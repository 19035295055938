import Header from "../Home/Header/Header.js";
import Banner from "../Home/Banner/Banner";
import Banner2 from "../Home/Banner/Banner2";
import Content1 from "../Home/Content/content1";
import Content3 from "../Home/Content/content3/content3";
import Banner3 from "../Home/Banner/Banner3/Banner3";
import Content4 from "../Home/Content/content4/content4";
// import Testimonial from "../Home/Testimonial/Testimonial";
import Blog from "../Home/Blog/Blog";
import Category from "./Category/Category";
import Card4 from "../Home/Card4/card4";
import WriteUp from "../Home/WriteUp/WriteUp";
import ImageGrid from "../Home/ImageGrid/ImageGrid";
import Testimonial from "./Testimonial2/Testimonial.js";

function Home() {
  return (
    <>
      <Banner />
      <Category />
      <Content1 />
      <Card4 />
      <Content3 />
      {/* <Banner3 /> */}
      {/* <Content4 /> */}
      <ImageGrid />
      <Testimonial />
      <WriteUp />
      <Banner2 />
    </>
  );
}

export default Home;
