/* eslint-disable no-loop-func */
import React from "react";
import PostApiCall from "../../Helper/Api";
import Notiflix from "notiflix";
import Payment_Success from "../../Images/Payment/paymentsucessful.svg";
import Order_Success from "../../Images/Payment/ordersucessful.svg";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";

class PaymentSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ShippingAddress: [],
      BillingAddress: [],
      CartData: [],
      SummaryData: [],
      OfferData: [],

      NumRegex: /^0|[0-9]\d*$/,
      MobileRegex: /^[0-9]*$/,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      SpecialRegex: /[-!$%^&*()_+|~=`"{}\[\]:\/;<>?,.@#]/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,

      TxnId: "",
      MerKey: "a6JOJL",
      MerSalt: "cri53U9i",
      CustomerLoginData: [],
      CustomerHash: "",
      MainOrder: [],
      Payment_Mode: "",
      Loader: true
    };
  }

  componentDidMount() {


    PostApiCall.postRequest(
      {
        whereClause: `where  fld_payment_trx_num='${this.props.match.params.txnid}/${this.props.match.params.mipid}'`,
      },
      "GetWebsiteOrderDetails"
    ).then((results1) =>
      // const objs = JSON.parse(result._bodyText)
      results1.json().then((obj) => {
        if (results1.status == 200 || results1.status == 201) {

          this.setState({
            MainOrder: obj.data,
            ProdData: obj.data,
            Payment_Mode: obj.data[0].Payment_Mode,
            Loader: false
          });
        }
      })
    );
  }

  render() {
    //console.log(this.props.match.params.txnid + '/' + this.props.match.params.mipid)
    return (
      <div>
        {this.state.Loader == true ? <div class="d-flex justify-content-center">
          <div class="spinner-border mt-5" role="status" style={{ color: "#c83363", width: "3rem", height: "3rem" }}>
            <span class="visually-hidden">Loading...</span>
          </div>
        </div> :

          <div class="container">
            <div class="row marginbtm-240">
              <div class="col-md-12">
                <div class="d-flex justify-content-center">
                  <img
                    src={
                      this.state.Payment_Mode == "COD"
                        ? Order_Success
                        : Payment_Success
                    }
                    class="paymentFail img-fluid"
                    alt="payment failed"
                    height="550px"
                    width="550px"
                  ></img>
                </div>
                <div class="privacy-box">
                  <div class="d-flex justify-content-center">
                    <button
                      className="butn"

                      onClick={() => {
                        window.location.href = "/";
                      }}
                    >
                      Continue Shopping
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default withRouter(PaymentSuccess);
