import React from "react";
import PostApiCall from "../../Helper/Api";
import Notiflix from "notiflix";
import { isMobile } from "react-device-detect";
// import custm from "./assets/img/banner/24-hours.png";
import ContactUsImage from "../../Images/StaticPagesBanner/ContactUs.png";
import ContactUsImageMobile from "../../Images/StaticPagesBanner/6.png";
import "./StaticPages.css";
import ManageCustomerAPI from "../ClinetDetailsApi/ManageCustomerAPI";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import pplogo1 from "../../Images/Logo.svg";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NumRegex: /^0|[0-9]\d*$/,
      MobileRegex: /^[0-9]*$/,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      SpecialRegex: /[-!$%^&*()_+|~=`'"{}\[\]:\/;<>?,.@#]/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,

      Name: "",
      CompanyName: "",
      Mobile: "",
      Email: "",
      Message: "",
      bannerfood: [],
      images: [],
      captchaNumbers: "",
      Captcha: "",
      clientName: "",
      clientPhoneNumber: null,
      clientEmail: null,
      clientAddress: null,
      clientCity: null,
      Loading: false,
    };
  }

  componentDidMount() {
    var captcha = Math.floor(Math.random() * 100000 + 1);
    this.setState({
      captchaNumbers: captcha,
    });

    ManageCustomerAPI.postRequest(
      {
        whereClause: `where fld_company_id = ${3}`,
      },
      "API/GetClientData"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          console.log(obj.data);
          this.setState({
            clientLogo: obj.data[0].fld_storelogo,
            clientName: obj.data[0].fld_companyname,
            clientPhoneNumber: obj.data[0].fld_mobilenumber,
            clientEmail: obj.data[0].fld_email,
            clientAddress: obj.data[0].fld_address,
            clientCity: obj.data[0].fld_city,
          });

          //console.log(obj)
        }
      })
    );
  }

  onPost() {
    PostApiCall.postRequest(
      {
        name: this.state.Name,
        email: this.state.Email.trim(),
        mobile: this.state.Mobile,
        company: this.state.CompanyName,
        message: this.state.Message,
      },
      "ContactUsMailer"
    ).then((results2) =>
      // const objs = JSON.parse(result._bodyText)
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          Notiflix.Notify.Success(
            "We" +
              "'" +
              "ve received your message. We" +
              "'" +
              "ll get back soon."
          );
          this.setState({
            Loading: false,
          });
          window.location.reload();
        }
      })
    );
  }

  Submit() {
    if (this.state.Name != "") {
      if (this.state.Email != "") {
        if (this.state.EmailRegex.test(this.state.Email)) {
          if (this.state.Mobile != "" && this.state.Mobile.length == 10) {
            if (this.state.CompanyName != "") {
              if (this.state.Captcha == this.state.captchaNumbers) {
                this.setState(
                  {
                    Loading: true,
                  },
                  () => {
                    this.onPost();
                  }
                );
              } else {
                Notiflix.Notify.Failure("Captcha is invalid.");
              }
            } else {
              Notiflix.Notify.Failure("Please enter your company name.");
            }
          } else {
            Notiflix.Notify.Failure("Please enter a valid mobile number.");
          }
        } else {
          Notiflix.Notify.Failure("Please enter a valid email address.");
        }
      } else {
        Notiflix.Notify.Failure("Please enter your email address.");
      }
    } else {
      Notiflix.Notify.Failure("Please enter your name");
    }
  }

  onPost() {
    PostApiCall.postRequest(
      {
        name: this.state.Name,
        email: this.state.Email.trim(),
        mobile: this.state.Mobile,
        company: this.state.CompanyName,
        message: this.state.Message,
      },
      "ContactUsMailer"
    ).then((results2) =>
      // const objs = JSON.parse(result._bodyText)
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          Notiflix.Notify.Success(
            "We" +
              "'" +
              "ve received your message. We" +
              "'" +
              "ll get back soon."
          );
          window.location.reload();
        }
      })
    );
  }

  render() {
    return (
      <div>
        {/* <Menu></Menu> */}
        <section class="inner-banner">
          <div class="main-banner mobileinner-banner">
            <img
              src={isMobile ? ContactUsImageMobile : ContactUsImage}
              alt=""
              className="img-responsive"
            />
          </div>
        </section>

        <div class="detail-page-container mt-3">
          <div class="contact-box">
            <div class="row">
              <div class="col-md-12">
                {/* <h2 style={{ marginBottom: "20px" }}>Write to Us</h2> */}
                {/* <p style={{fontSize: "12px"}}>Get in touch with us and our team would be glad to assist you.</p> */}
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="write-to-us contactform">
                  <h1 class="light-title">
                    Write to <strong>Us</strong>
                  </h1>

                  <p class="such mb-5">
                    If you have any questions on usage of the product or how to
                    return your product please contact us.
                  </p>

                  {/* <form action="#"> */}
                  <div class="row">
                    <div class="form-group required-field col-md-12">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Name"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="name@example.com"
                          className="input"
                          value={this.state.Name}
                          onChange={(text) => {
                            this.setState({
                              Name: text.target.value,
                            });
                          }}
                        />
                      </FloatingLabel>
                    </div>

                    <div class="form-group col-md-12">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Company Name"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          className="input"
                          placeholder="name@example.com"
                          value={this.state.CompanyName}
                          onChange={(text) => {
                            this.setState({
                              CompanyName: text.target.value,
                            });
                          }}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-12 required-field">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="name@example.com"
                          className="input"
                          value={this.state.Email}
                          onChange={(text) => {
                            this.setState({
                              Email: text.target.value,
                            });
                          }}
                        />
                      </FloatingLabel>
                    </div>
                    <div class="form-group col-md-12">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Mobile Number"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="name@example.com"
                          className="input"
                          value={this.state.Mobile}
                          onChange={(mobile) => {
                            if (
                              this.state.NumRegex.test(mobile.target.value) &&
                              mobile.target.value.length <= 10
                            ) {
                              this.setState({
                                Mobile: mobile.target.value,
                              });
                            }
                          }}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div class="form-group required-field">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Message"
                      className="mb-3"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        className="input"
                        value={this.state.Message}
                        onChange={(text) => {
                          this.setState({
                            Message: text.target.value,
                          });
                        }}
                      />
                    </FloatingLabel>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6 contact-us-captcha">
                      <p> {this.state.captchaNumbers}</p>
                    </div>
                    <div class="form-group col-md-6 required-field captchaPadding">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="captcha"
                        className=""
                      >
                        <Form.Control
                          type="captcha"
                          placeholder="Enter the captcha"
                          className="form-control form-controlCaptch"
                          value={this.state.Captcha}
                          onChange={(text) => {
                            this.setState({
                              Captcha: text.target.value,
                            });
                          }}
                        />
                      </FloatingLabel>

                      {/* <input
                        type="captcha"
                        class="form-control form-controlCaptch"
                        placeholder="Enter the captcha"
                        value={this.state.Captcha}
                        onChange={(text) => {
                          this.setState({
                            Captcha: text.target.value,
                          });
                        }}
                        required=""
                      /> */}
                    </div>
                  </div>
                  <div class="form-footer">
                    <button
                      type="submit"
                      class="butnSubmitContactfrm"
                      onClick={this.Submit.bind(this)}
                    >
                      {this.state.Loading == true ? (
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>

              <div class="col-md-6">
                <div className="row">
                  <div className="col-12">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30154.175350100548!2d72.830686!3d19.139571!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5f48237561736cb3!2sRoyal%20Classic%2C%20Andheri%20(west)%2C%20Mumbai.!5e0!3m2!1sen!2sus!4v1653218655523!5m2!1sen!2sus"
                      width="100%"
                      height="450"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                  <div className="col-12">
                    <div class="contact-info">
                      <img
                        src={pplogo1}
                        alt="logo"
                        style={{ width: "15%" }}
                      ></img>
                      <div>
                        <b style={{ fontSize: "25px" }}>
                          {this.state.clientName}
                        </b>
                        <p>{this.state.clientAddress} West,</p>
                        <p>{this.state.clientCity} - 400053, India</p>
                      </div>
                      <div className="footer-contact-us">
                        <table
                          className="contact-us-footer"
                          style={{ width: "100%" }}
                        >
                          <tr style={{ borderColor: "white" }}>
                            <td
                              style={{
                                width: "85%",
                                textAlign: "left",
                                borderColor: "white",
                              }}
                            >
                              <p>
                                <b>Customer Support ( 9:00am - 5:30pm )</b>
                              </p>
                              <p>
                                For any queries/suggestions, connect with our
                                customer care
                              </p>
                              <p>
                                Call Us at :{" "}
                                <a href={`tel:${this.state.clientPhoneNumber}`}>
                                  {this.state.clientPhoneNumber}
                                </a>
                              </p>
                              <p>
                                Email us at :{" "}
                                <a href={`mailto:${this.state.clientEmail}`}>
                                  {this.state.clientEmail}
                                </a>
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="address-box responsive-map-container"> */}
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30154.175350100548!2d72.830686!3d19.139571!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5f48237561736cb3!2sRoyal%20Classic%2C%20Andheri%20(west)%2C%20Mumbai.!5e0!3m2!1sen!2sus!4v1653218655523!5m2!1sen!2sus" width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">

                                    </iframe> */}

                  {/* change by laxmi */}

                  {/* <div class="contact-info">
                    <img
                      src={pplogo1}
                      alt="logo"
                      style={{ width: "15%" }}
                    ></img>
                    <div>
                      <b style={{ fontSize: "25px" }}>
                        {this.state.clientName}
                      </b>
                      <p>{this.state.clientAddress} West,</p>
                      <p>{this.state.clientCity} - 400053, India</p>
                    </div>
                    <div className="footer-contact-us">
                      <table
                        className="contact-us-footer"
                        style={{ width: "100%" }}
                      >
                        <tr style={{ borderColor: "white" }}>
                          <td
                            style={{
                              width: "85%",
                              textAlign: "left",
                              borderColor: "white",
                            }}
                          >
                            <p>
                              <b>Customer Support ( 9:00am - 5:30pm )</b>
                            </p>
                            <p>
                              For any queries/suggestions, connect with our
                              customer care
                            </p>
                            <p>
                              Call Us at :{" "}
                              <a href={`tel:${this.state.clientPhoneNumber}`}>
                                {this.state.clientPhoneNumber}
                              </a>
                            </p>
                            <p>
                              Email us at :{" "}
                              <a href={`mailto:${this.state.clientEmail}`}>
                                {this.state.clientEmail}
                              </a>
                            </p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
