import React from "react"
import InnerBanner from "../InnerBanner/Innerbanner";

const TermsandConditions = () => {

    return (
        <>
            <div className="row">
                <div className="12">
                    <InnerBanner />
                </div>
            </div>
            <div className="static-page-main">
                <div className="row">
                    <div className="col-12">
                        <div className="termsandconditions-heading">
                            <h2>Terms & Conditions</h2>
                        </div>
                    </div>
                    <div className="col-12">
                        <p>This Agreement is a legally binding document between You and Pink Parrot Kids LLP. Accessing, browsing or otherwise using the website indicates Your acceptance of all the terms and conditions herein. If You do not agree to this Agreement, please do not use the website. The terms of this Agreement will govern the relationship between You and Pink Parrot Kids LLP, including with respect to the listing, advertising, exhibiting, making available, marketing, sale and/or delivery of any Products (defined below) through the Website.
                        </p>
                        <p>
                            Pink Parrot Kids LLP  shall have the right to add to and amend the Agreement at any time. Pink Parrot Kids LLP  may notify the revised terms and conditions of this Agreement, to You as an update provided on the Website. It shall be Your responsibility to review the notifications on the Website or from Pink Parrot Kids LLP  from time to time. Your continued use of the Website after modifications/amendments/revisions of the terms and conditions of this Agreement shall be deemed as an acceptance of such modifications/amendments/revisions and such revised Agreement shall be binding on and enforceable against You.
                        </p>
                        <p>
                            Please read this Agreement carefully before using or registering on the Website or accessing any material, information or services through the Website. If You do not agree with the below terms and conditions, please do not use the Website for purchase the products offered for sale on the Website

                        </p>
                        <b>1. Services</b>
                        <p>1.1 The Website is an electronic marketplace and serves as an intermediary that:
                        </p>
                        <p>(a) enables the Sellers to advertise, exhibit, make available and offer to sell various Products to Buyers;
                        </p>
                        <p>(b) enables the Buyers to accept the offer of sale of the Products made by the Sellers on the Website and to make payments to the Sellers for purchase of the Products through the payment gateway available on the Website and/ or through vouchers and store credit issued by Pink Parrot Kids LLP ;
                        </p>
                        <p>(c) facilitates and provides incidental and ancillary services such as delivery, cash collection during cash-on-delivery services etc.</p>
                        <p>All the incidental and ancillary services offered by the Website are outsourced to third-party service providers. Pink Parrot Kids LLP  shall not be responsible and/ or be held liable in any manner for a defect and/ or deficiency in service due to an act and/ or an omission by the third-party service provider.
                        </p>
                        <b>2. Essential Conditions</b>
                        <p>
                            2.1 You accept that You are of legal age to form a binding contract and are not a person barred from receiving the services under the laws applicable in India. You shall not transact on this Website if You are a minor under the age of eighteen (18) without parental guidance. Notwithstanding the foregoing, if You are below the age of eighteen (18) years, please read through this Agreement with your parent or legal guardian. In the event You are an entity, You shall be incorporated and/ or set up as per the laws of India.
                        </p>
                        <p>2.2 Pink Parrot Kids LLP  reserves the right to refuse access to use the services offered at the Website to new Users or to terminate access granted to existing Users at any time without according any reason for doing so.
                        </p>
                        <p>2.3 You shall not have more than one active Account (as defined below) on the Website. Additionally, You are prohibited from selling, trading, or otherwise transferring Your Account to another person.
                        </p>
                        <b>3. Buyer Conditions</b>
                        <p>3.1 You may maintain an account (“Account”) on the Website or You may use the Website as a guest user. In either case, You will be required to furnish certain information and details, including Your name, e-mail id, and any other information deemed necessary by Us (“Buyer Information”). You shall ensure that the Buyer Information is true, complete, accurate and up-to-date at all times. You also agree to Our representatives contacting You based on the information provided by You with respect to the transactions entered by You through the Website.
                        </p>
                        <p>If You maintain an Account, You shall be responsible for maintaining the confidentiality and security of the password and for all activities that occur in and through Your Account. You agree that you will not disclose Your password to any third party and that You will take sole responsibility for any activities or transactions under Your Account, whether or not you have authorized such activities or transactions. You will immediately notify Us of any unauthorized use of Your Account. Please note that We will not be liable for any such unauthorized use.
                        </p>
                        <p>3.2 If You wish to terminate Your Account, You may send Us an e-mail at neha@pinkparrotkids.com. You may use the payment gateway facility available on the Website and You may be required to provide your credit or debit card details to the approved payment gateways while making the payment. In this regard, You agree to provide correct and accurate credit/ debit card details to the approved payment gateways for buying the Products. You shall not use the credit/ debit card, which is not lawfully owned by You in any transaction. You will be solely responsible for the security and confidentiality of Your credit/ debit card details. We expressly disclaim all liabilities that may arise as a consequence of any unauthorized use of Your credit/ debit card and/ or failure of transactions. You may also use the cash-on-delivery facility provided your order qualifies for it.
                        </p>
                        <p>3.3 The payment gateway service provider, Your bank, and/ or the credit card association may restrict, limit Your transactions and/ or block your debit/ credit card for a particular reason. In all such cases, any expenses and losses suffered by Pink Parrot Kids LLP  shall be borne by You.
                        </p>
                        <p>3.4 You hereby agree and acknowledge that You purchase the Product(s) from the Seller at your own risk and Pink Parrot Kids LLP  shall not be liable for any reason including, but not limited to, defective, deficient and/ or damaged Products.
                        </p>
                        <p>3.5 You shall not open the package in which the Product arrives unless You sign the acknowledgement receipt produced by Our delivery service provider. In the event You wish to return a Product purchased, You shall return the Product as per the return policy of the Seller. You shall be completely responsible for the packaging of the Product in case of a return. In the event, the Product is damaged due to Your act and/ or omission, or during transit because of inadequate packing, You shall bear the loss suffered by the Seller.
                        </p>
                        <p>3.6 You shall be available at the address provided by You for delivery to accept the Products. In the event You have paid for the Product through Our online payment gateway, You shall not be eligible to claim a refund if You do not accept the delivery from Our delivery service provider when he/ she visits Your address to deliver.
                        </p>
                        <p>3.7 In the event You opt to make cash payments by availing of the cash-on-delivery option provided by the Seller, You shall be solely responsible for the amount of cash handed over to the representative of Our logistic service provider. We shall not be responsible and/ or liable for any loss of cash collected from You by the representative of Our logistic service provider.
                        </p>
                        <p>3.8 You shall provide honest and fair reviews about the Sellers on the Website and You shall not use abusive language while posting reviews.
                        </p>
                        <p>3.9 You shall not misrepresent, or provide false details including but not limited to Your name, address, and phone number. In the event it is found by Pink Parrot Kids LLP  that You have provided Us with false details, We may terminate Your Account, and/ or block Your access to the Website.
                        </p>
                        <b>4. Limitation of Liability</b>
                        <p>4.1 Great care has been taken to ensure that the information available on the Website is correct and error-free. However, We cannot warrant that use of the Website will be error-free or fit for purpose, timely, that defects will be corrected, or that the site or the server that makes it available is free of viruses or bugs or represents the full functionality, accuracy, reliability of the Website and we do not make any warranty whatsoever, whether express or implied, relating to fitness for purpose, or accuracy.
                        </p>
                        <p>
                            4.2 By accepting this Agreement You agree to relieve us from any liability whatsoever arising from Your use of information from any third party, Your use of any third party website, or Your purchase of any Product from any Seller.

                        </p>
                        <p>4.3 We do not accept liability for any indirect loss, consequential loss, loss of data, loss of income or profit, loss of damage to property and/or loss from claims of third parties arising out of the use of the Website or for any Products on the Website.
                        </p>
                        <p>4.4 We have taken all reasonable steps to prevent internet fraud and ensure any data collected from You is stored as securely and safely as possible. However, we cannot be held liable in the extremely unlikely event of a breach in our secure servers or those of third parties.
                        </p>
                        <p>4.5 If Pink Parrot Kids LLP  has a reasonable belief that there exists an abuse of vouchers and/or discount codes or in suspected instances of fraud, Pink Parrot Kids LLP  reserves the right to block the Buyer immediately and to refuse all future service. Additionally, should there exist an abuse of vouchers or discount codes, Pink Parrot Kids LLP  reserves the right to seek compensation from any and all violators. Offers available on the Website are subject to Pink Parrot Kids LLP’s discretion and may be withdrawn at any time and without notice.
                        </p>
                        <p>4.6 In the event We are held liable for any reason, Our total liability shall not exceed the price of the Product.
                        </p>
                        <b>5. Indemnification
                        </b>
                        <p>5.1 You agree to indemnify, defend and hold Pink Parrot Kids LLP, its parents, subsidiaries, affiliates, successors, assigns and licensors, franchisees or sub-franchisees, any of their respective officers, directors, employees, agents, vendors, licensors, representatives, advertisers, service providers and suppliers harmless from and against any and all claims, actions, losses, expenses, damages and costs (including reasonable attorneys' fees), resulting from any breach or violation of this Agreement by You.
                        </p>
                        <b>6. Governing Law and Jurisdiction
                        </b>
                        <p>6.1 This Agreement shall be construed in accordance with the applicable laws of India and The Courts at Bangalore, India shall have exclusive jurisdiction in any proceedings arising out of this Agreement.
                        </p>
                        <p>Your (“User”, “You” or “Your”, which shall mean and include its legal heirs and representatives) use of the Website is subject to and governed by the following terms and conditions (“Terms of Use”) as applicable to the Website together with all policies, notices, guidelines, disclaimers that are published and shared with You from time to time which is incorporated herein by way of reference including but not limited to Our Privacy Policy, and terms & conditions,  and such other terms as may be applicable to Us in Your capacity as a User of the Website. These Terms of Use shall be enforceable against You in the same manner as any other written agreement. User shall mean any person/ persons, who visit, uses, deals with and/ or transacts through the Website and includes a buyer, seller, guest user, and browser.
                        </p>
                        <p>You must satisfy the following conditions to access, browse or otherwise use the Website:
                        </p>
                        <ul>
                            <li> You must be a natural person who is at least 18 years of age. If You are less than 18 years of age, please read through these terms of use with Your parent or legal guardian, and in such a case these terms of use shall be deemed to be a contract between the Company and Your legal guardian or parent and to the extent permissible under applicable laws, enforceable against You.
                            </li>
                            <li>You must not misrepresent Your identity. You must not use another person’s username, password or other account information, or another person’s name, likeness, voice, image or photograph or impersonate any person or entity.
                            </li>
                        </ul>
                        <p>The right to use the website is personal to the User and is not transferable to any other person or entity.
                        </p>
                        <p>Accessing, browsing or otherwise using the website indicates Your acceptance of all the terms and conditions herein. If You do not agree to these Terms of Use, please do not use the website. You acknowledge and agree that by mere use of the website, You shall be contracting with the company and these Terms of Use constitute Your binding obligations with the Company. Please read these Terms of Use carefully before proceeding.
                        </p>
                        <p>We shall have the right at any time to change or discontinue any aspect or feature of the Website, including but not limited to, content, hours of availability, equipment needed for access or use, fees or charges. It is Your responsibility to review these Terms of Use periodically for any updates/ changes. Your continued use of the website subsequent to such changes will indicate and imply Your acceptance to such revisions.
                        </p>
                        <p>If You wish to use the part of the Website that requires maintenance of an account on the Website (“Account”) such as, to buy products, add products to a wishlist, provide testimonials and reviews, access certain content or upload content on the Website, You will be required to furnish Account Information. You shall ensure that the Account Information is true, complete, accurate and up-to-date at all times. By providing Us with the Account Information, You consent to receive communications including, but not limited to SMS, e-mails or phone calls from Us with respect to Your transaction on the Website or for any other purpose.
                        </p>
                        <p>It is not compulsory for You to register on the Website. You shall be permitted to use, browse or search for products on the Website without an Account as a “Guest User”. However, without an Account, You shall have restricted access to use and avail of the services offered on the Website. Note that these Terms of Use are binding on Guest Users too.
                        </p>
                        <p>You shall be responsible for maintaining the confidentiality and security of Your password and for all activities that occur in and through Your Account. You agree that you will not disclose Your password to any third party and that You will take sole responsibility for any activities or transactions under your Account, whether or not you have authorized such activities or transactions. You will immediately notify us of any unauthorized use of Your Account. Please note that We will not be liable for any such unauthorized use.</p>
                        <p>If You wish to terminate Your Account, You may send an e-mail to Us at customercare@pinkparrotcustomercare.com LLP  requesting the same. Similarly, We may at Our discretion, suspend or terminate Your Account in accordance with Section 15 of this Terms of Use.
                        </p>
                        <b>User Agreement
                        </b>
                        <p>You agree to the following:
                        </p>
                        <ul>
                            <li>You will use the Website for lawful purposes only and comply with all applicable local, state, national and foreign laws, including but not limited to, data privacy, international communications laws and regulations while using and transacting on the Website.</li>
                            <li>You will provide authentic and true information in all instances where such information is requested from You. The Company reserves the right to confirm and validate the information and other details provided by You at any point in time. If upon confirmation Your details are found to be wholly or partially untrue, the Company has the right in its sole discretion to reject the registration and restrain You from using the Website without any prior intimation.
                            </li>
                            <li>You shall access the Website and transact at Your sole risk and shall use Your best and prudent judgment before entering into any transaction through this Website.
                            </li>
                            <li>The Company will be entitled to seek damages and/ or be entitled to any other remedy available to it under the applicable law in the event of unauthorized use by You.
                            </li>
                        </ul>
                        <b>Prohibited Use
                        </b>
                        <p>The Website may only be used for lawful purposes. The Company specifically prohibits use of the Website for, and You agree not to use the Website for, any of the following purposes:
                        </p>
                        <p>Copying, modifying, deleting, publishing, transmitting, distributing, displaying, performing, create derivative works on, or in any way exploit, any of the Materials on the Website, in whole or in part, without Our prior written consent;
                        </p>
                        <p>Subleasing, leasing, participating in the transfer or sale, selling, assigning, transferring, distributing, renting, permitting concurrent use of, or granting other rights to any Materials provided on the Website to any third party;
                        </p>
                        <p>Downloading any Materials or User Content unless You see a ‘download’ or similar link displayed on the Website by reference;
                        </p>
                        <p>Using any robot, spider or any other data mining technology or automatic or manual process to monitor, cache, extract data from, copy or distribute the Materials or User Content on the Website (except as may be a result of standard Internet browser or search engine);
                        </p>
                        <p>Accessing the Website through means other than through the interface that is provided by the Company;
                        </p>
                        <p>Violating applicable laws, or any term(s) specified under the Terms of Use or any other Website policies;
                        </p>
                        <p>Intentionally or unintentionally interfering with or violating any applicable laws related to the access to or use of the Website, violating any requirements, procedures, policies or regulations connected to the Website;
                        </p>
                        <p>Engaging in any form of antisocial, disrupting, or destructive acts, including “flaming,” “spamming,” “flooding,” “trolling,” and “griefing” as those terms are commonly understood and used on the internet;
                        </p>
                        <p>Disrupting or interfering with the security of, or otherwise cause harm to, the Website, Materials, systems resources, or gain unauthorized access to, user accounts, passwords, servers or networks connected to or accessible through the Website or any affiliated or linked sites, or any other data not intended for You. You further agree to the following to ensure the security of the Website:</p>
                        <p>You shall not misuse this Website by knowingly introducing Viruses. You must not attempt to gain unauthorized access to Our Website, the server on which the Website is stored or any server, computer or database connected to this Website. You must not attack this Website via a denial-of-service attack.
                        </p>
                        <p>We will not be liable for any loss or damage caused by a denial-of-service attack or Viruses that may infect Your computer equipment, computer programs, data or other proprietary material due to Your use of this Website or to Your downloading of any Material posted on it, or on any website linked to it.
                        </p>
                        <p>The Company shall have the right at any time to change or modify the Terms & Conditions applicable to User’s use of the Website, or any part thereof, or to impose new conditions, including, but not limited to, adding fees and charges for use. Such changes, modifications, additions or deletions shall be effective immediately upon notice thereof, which may be given by means including, but not limited to, posting on Website, or by electronic or conventional mail, or by any other means by which User obtains notice thereof. Any use of the Website by User after such notice shall be deemed to constitute acceptance by User of such changes, modifications or additions.
                        </p>
                        <p>We shall terminate Your Account if You breach any provisions of this Terms of Use. By breaching the provisions of this Terms of Use, You may be liable to be prosecuted under the Information Technology Act, 2000 and any other applicable law. We will report any such breach to the relevant law enforcement authorities and We will co-operate with such authorities by disclosing Your identity to them. In the event of such a breach, Your rights to use this Website will cease immediately.</p>
                        <p>If it is determined that You have violated any of these Terms of Use and/or if You have violated any applicable law, in addition to termination of Your Account, We reserve the right to suspend Your use of and prohibit access to any or all features or parts of the Website. Any such suspension or termination of access to the Website may be effected by Us without providing You with prior written notice in this regard.</p>
                        <p>Upon termination of Your Account or prohibition of Your use of the Website, all rights granted by Us to You to use the Website will automatically terminate and We shall have no further obligation or liability towards You.
                        </p>
                        <p>You acknowledge and agree that, upon termination, You shall immediately destroy any copies made of any portion of the content contained on the Website. You acknowledge and agree that the Company shall not be liable to You or any third party claiming through You, for any suspension or termination of access to the Website.
                        </p>
                        <p>Upon termination of Your Account, You shall not create a new account and use the Website with Your details and/ or with the details of any other user.
                        </p>
                    </div>
                </div>
            </div>

        </>
    )

}

export default TermsandConditions