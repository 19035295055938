import React from 'react'

import img1 from '../../Images/ParallaxBanner/4.webp';
import img2 from '../../Images/ParallaxBanner/5.webp';
import img3 from '../../Images/ParallaxBanner/6.webp';
import img4 from '../../Images/ParallaxBanner/7.webp';
import img5 from '../../Images/ParallaxBanner/8.webp';
import img6 from '../../Images/ParallaxBanner/9.webp';
import img7 from '../../Images/ParallaxBanner/10.webp';
import img8 from '../../Images/ParallaxBanner/11.webp';
import img9 from '../../Images/ParallaxBanner/12.webp';
import img10 from '../../Images/ParallaxBanner/13.webp';

class innerBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            images: [
                { banner: img1 }, { banner: img2 }, { banner: img3 }, { banner: img4 },
                { banner: img5 }, { banner: img6 }, { banner: img7 }, { banner: img8 },
                { banner: img9 }, { banner: img10 },
            ],
            BannerImages: []

        }
    }
    componentDidMount() {

        var index = 0
        index = Math.floor(Math.random() * this.state.images.length);
        var imagesdata = []
        imagesdata.push(this.state.images[index])
        this.setState({
            BannerImages: imagesdata
        })
    }
    render() {
        return (
            <>
                 <section class="inner-banner banner-carousel-bootstrap">
                    <div class="main-banner mobileinner-banner">
                        {this.state.BannerImages.map((data, i) =>
                            <img src={data.banner} alt="" className="img-responsive" />)}
                    </div>
                </section>

            </>
        )
    }
}

export default innerBanner;