import { Component } from "react";
import VerifiedIcon from "../../Images/ReviewBanner/VerifiedIcon.svg";
import "./ProductReviews.css";
import Notiflix from "notiflix";
import moment from "moment";
import PostApiCall from "../../Helper/Api";

class ProductReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReviewData: [],
      Loading: true,
      AverageRating: null,
    };
  }

  componentDidMount() {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_variant_id=${this.props.variantid} order by fld_review_id desc`,
        recordCount: "Top 5 *",
      },
      "GetReviews"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.data.length != 0) {
            console.log(obj1.data);
            this.setState({
              ReviewData: obj1.data,
              AverageRating: obj1.data[0].avg_rating,
              Loading: false,
            });
          } else {
            this.setState({
              ReviewData: obj1.data,
              Loading: false,
            });
          }
        }
      })
    );
  }

  render() {
    // console.log(this.state.ReviewData.length)
    return (
      <>
        <section
          class="section-padding flosil-product-reviews mt-3"
          style={{ background: "#fff", overflowX: "hidden" }}
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title mt-2">
                  <h4>Ratings and Reviews</h4>
                  <div class="title-divider"></div>
                </div>
              </div>
            </div>

            <div
              class="row"
              style={{
                background: "#fff!important",
                display: this.state.ReviewData.length == 0 ? "none" : "flex",
              }}
            >
              {/* col size change by laxmi for phn view------------------------------ */}
              {/* <div class="col-md-4 product-reviews"> */}
              <div class="col-md-8 col-lg-5 col-sm-6 col-6 product-reviews">
                {/* ----------------------------end col size */}

                <div className="overall-ratings">
                  <div className="ratings">
                    <strong className="ratings-exact">
                      {this.state.AverageRating}
                    </strong>
                    <span className="ratings-upto">/5</span>
                  </div>
                  <div className="ratings-verified">
                    <strong className="ratings-static">Overall Rating</strong>
                    <span className="verified-upto">
                      {this.state.ReviewData.length} verified ratings
                    </span>
                  </div>
                </div>
              </div>
              {/* col size change by laxmi for phn view------------------------------ */}
              {/* <div
                class="col-md-8 product-reviews"
                style={{ background: "#fff!important" }}
              > */}
              <div
                class="col-md-4 col-lg-7 col-sm-6 col-6 product-reviews"
                style={{ background: "#fff!important" }}
              >
                {/* ----------------------------end col size */}
                <div className="">
                  {/* <div className="writereview">Write a review !</div> */}
                  <button
                    className="butn mt-3 writereviewphnview"
                    onClick={() => {
                      localStorage.setItem("ReviewPage", "ReviewPage");
                      var login = localStorage.getItem("CustomerData");
                      var logindetails = JSON.parse(login);
                      if (logindetails) {
                        window.location.href = `/writereview/${this.props.variantid}`;
                      } else {
                        window.location.href = "/login";
                      }
                    }}
                  >
                    Write Review
                  </button>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                background: "#fff!important",
                display: this.state.ReviewData.length == 0 ? "block" : "none",
              }}
            >
              <div className="col-12 product-reviews">
                <div className="">
                  {/* <div className="writereview">Write a review !</div> */}
                  <button
                    className="butn mt-3"
                    onClick={() => {
                      localStorage.setItem("ReviewPage", "ReviewPage");
                      var login = localStorage.getItem("CustomerData");
                      var logindetails = JSON.parse(login);
                      if (logindetails) {
                        window.location.href = `/writereview/${this.props.variantid}`;
                      } else {
                        window.location.href = "/login";
                      }
                    }}
                  >
                    Write Review
                  </button>
                </div>
              </div>
            </div>

            {this.state.Loading === true ? (
              <div className="rating-spinner">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  {this.state.ReviewData.length != 0 ? (
                    <div>
                      {this.state.ReviewData.map((data, i) => {
                        return (
                          <div class="row customer-reviews-Description">
                            {/* change by laxmi-----------------------------add phn view col size */}
                            {/* <div
                              class="col-md-4"
                              style={{ background: "#fff!important" }}
                            > */}
                            <div
                              class="col-md-4 col-sm-5 col-xs-5"
                              style={{ background: "#fff!important" }}
                            >
                              <div className="userInfoSection">
                                <img
                                  src="https://images-static.nykaa.com/prod-review/default_profile_image.png"
                                  alt="avatar"
                                  class="userImageIcon"
                                />
                                <div className="UserInformationProductReviews">
                                  <span className="userNameReviews">
                                    {data.fld_name}
                                  </span>
                                  <span className="verified-buyers-icon">
                                    <img
                                      src={VerifiedIcon}
                                      height="23%"
                                      width="23%"
                                    ></img>
                                    Verified Buyers
                                  </span>
                                </div>
                              </div>
                            </div>

                            {/* change by laxmi-----------------------------add phn view col size */}
                            {/* <div
                              class="col-md-8"
                              style={{ background: "#fff!important" }}
                            > */}
                            <div
                              class="col-md-8 col-sm-7 col-xs-7"
                              style={{ background: "#fff!important" }}
                            >
                              <div className="star-ratings">
                                <section class="css-rwwnk2">
                                  {data.fld_rating <= 2 ? (
                                    <span class="css-1at2way">
                                      {data.fld_rating}
                                      <i class="css-w0v4um">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="13"
                                          height="14"
                                        >
                                          <title>star</title>
                                          <path
                                            fill="#FFF"
                                            d="M2.555 11.878c-.05 0-.1-.025-.15-.05a.277.277 0 01-.1-.275l1.098-4.092L.109 4.79c-.1-.05-.125-.175-.1-.275a.24.24 0 01.225-.174l4.242-.225L5.998.15c.05-.075.15-.15.25-.15s.2.075.225.15l1.522 3.967 4.242.225c.1 0 .2.075.225.174.025.1 0 .2-.075.275L9.093 7.46l1.098 4.092c.025.1 0 .2-.1.275-.075.05-.2.075-.274 0L6.248 9.532l-3.569 2.296c-.05.05-.075.05-.124.05z"
                                          ></path>
                                        </svg>
                                      </i>
                                    </span>
                                  ) : (
                                    <span class="css-1at2wjx">
                                      {data.fld_rating}
                                      <i class="css-w0v4um">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="13"
                                          height="14"
                                        >
                                          <title>star</title>
                                          <path
                                            fill="#FFF"
                                            d="M2.555 11.878c-.05 0-.1-.025-.15-.05a.277.277 0 01-.1-.275l1.098-4.092L.109 4.79c-.1-.05-.125-.175-.1-.275a.24.24 0 01.225-.174l4.242-.225L5.998.15c.05-.075.15-.15.25-.15s.2.075.225.15l1.522 3.967 4.242.225c.1 0 .2.075.225.174.025.1 0 .2-.075.275L9.093 7.46l1.098 4.092c.025.1 0 .2-.1.275-.075.05-.2.075-.274 0L6.248 9.532l-3.569 2.296c-.05.05-.075.05-.124.05z"
                                          ></path>
                                        </svg>
                                      </i>
                                    </span>
                                  )}
                                </section>
                                <div class="css-177e1lt">
                                  {moment(data.fld_review_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </div>
                              </div>
                              <section class="css-1v6g5ho">
                                <h4 class="css-tm4hnq">{data.fld_headline}</h4>
                                <p class="css-1n0nrdk">
                                  {data.fld_review_description}
                                </p>
                              </section>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="pt-5">
                      <p style={{ textAlign: "center" }}>No reviews yet</p>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              class="css-1rjb1jp"
              style={{
                display:
                  this.state.Loading == true ||
                    this.state.ReviewData.length == 0
                    ? "none"
                    : "block",
              }}
            >
              <a
                onClick={() => {
                  window.location.href = `/all-reviews/${this.props.variantid}`;
                }}
                class="css-1xv8iu0"
              >
                <span class="css-1a3bom8">Read all reviews</span>
                <i>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                  >
                    <title>right arrow</title>
                    <path
                      d="M.874 0L0 .937 4.262 5.5 0 10.073.864 11l4.694-5.037L6 5.5l-.442-.463z"
                      class="css-1sx9h1w"
                    ></path>
                  </svg>
                </i>
              </a>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ProductReviews;
