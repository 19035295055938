import Container from "react-bootstrap/Container";
import { AiOutlineUser } from "react-icons/ai";
import { MdDateRange } from "react-icons/md";
import Blogimg from "../../../Images/bottomimage.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Content/content1.css";
import Card from "react-bootstrap/Card";
import "../../Home/WriteUp/WriteUp.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Rightup() {
  return (
    <>
      <div className="blog-main">
        <div>
          {/* <div>
            <h2>latest blog</h2>
            <p>
              Interesting features is rich and colorful, each button to bring a
              surprise to the baby Introduction to give your child learn in
              advance.
            </p>
          </div> */}
          <div>
            <Row className="pad-row">
              <Col xs={12} md={6}>
                {/* change by laxmi comment extra blog-img div */}
                {/* <div className="blog-img"> */}
                <img alt="blog" className="blog-img" src={Blogimg} />
                {/* </div> */}
              </Col>

              <Col
                xs={12}
                md={6}
                className="align-items-center writeupPehraPhn"
              >
                <div className="blog-h2p texttransform-about texttransform-about">
                  <h2 className="blog-h2">Welcome to Pink Parrot Kids !</h2>
                  <p className="rightup-p">
                  Pink Parrot Kids, a Mumbai-based company, was born in April 2019. The founder Neha Kannan was inspired by her 4-year-old daughter Adhira Kannan to offer quality products to children across all ages. She believes in fun learning and activities keeping young and energetic minds busy.
                  </p>

                  <p className="mt-2">
                  I, Neha Kannan, started this venture to break all the notions of traditional learning with the help of our doodle designing skills. Our prolific designs boost your kid's brain development by triggering their creative and analytical tendencies. We popularised the phrase "Learning can take place outside the classroom."

                  </p>

                  <div className="but-margin-blogbelowTestimonial">
                    <div className="butn butnWriteup">
                      <a href="/about-us">Read More</a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default Rightup;
