import { Component } from "react";
import "./ListingPage.css";
import "react-rangeslider/lib/index.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import ListingPageCard from "./ListingPageCard";
import InnerBanner from "../InnerBanner/Innerbanner";
import PostApiCall from "../../Helper/Api";
import Loader from "../../LoaderComponent/Loader";

class ListingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: "",
      value: "",
      productView: "row rless grid product-grid",
      addClass: false,
      Items: [],
      CategoryId: null,
      Loading: true,
      FilterType: [
        // { label: 'All Products', value: 'All Products' },
        { label: "Sort By", value: "Sort By" },
        { label: "Price, High to Low", value: "Price, High to Low" },
        { label: "Price, Low to High", value: "Price, Low to High" },
        { label: "Alphabetically, A to Z", value: "Alphabetically, A to Z" },
        { label: "Alphabetically, Z to A", value: "Alphabetically, Z to A" },
      ],
      Filter: "Sort By",
      ProductCount: null,
      InStockCheck: false,
      OutOfStockCheck: false,
      OutOfStockItemsCount: null,
      InStockItemCount: null,
      RangeSliderValue: null,
    };
  }

  componentDidMount() {
    // this.setState({
    //   CategoryId:this.props.match.params.categoryid,
    // }, ()=>{
    //   this.getItems();
    // })

    if (this.props.match.params.categoryid == "allproducts") {
      this.getAllItems();
    } else {
      this.getItems();
    }
  }

  getAllItems = () => {
    PostApiCall.postRequest(
      {
        id: parseInt(null),
        stateid: null,
      },
      "GetProductsCatgory"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          // console.log("All itsm"+obj1.data);
          this.setState({
            Items: obj1.data.filter(value => value.fld_status == "Active"),
            ProductCount: obj1.data.length,
            Loading: false,
          });
        }
      })
    );
  };

  getItems = () => {
    PostApiCall.postRequest(
      {
        id: parseInt(this.props.match.params.categoryid),
        stateid: null,
      },
      "GetProductsCatgory"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          console.log(obj1.data);
          this.setState({
            Items: obj1.data.filter(value => value.fld_status == "Active"),
            ProductCount: obj1.data.length,
            Loading: false,
          });
        }
      })
    );
  };

  toggle() {
    this.setState({ addClass: !this.state.addClass });
  }

  // sortByPriceHighToLow(){
  //   let highToLow = this.state.Items.sort((a, b) => {
  //     return b.fld_categoryid - a.fld_categoryid;
  //   });

  //   this.setState({
  //     Items: highToLow
  //   });
  // }

  SortByPriceHighToLow = () => {
    let sortedProductsDsc;
    sortedProductsDsc = this.state.Items.sort((a, b) => {
      return (
        parseInt(b.fld_website_discount_price) -
        parseInt(a.fld_website_discount_price)
      );
    });

    this.setState({
      Items: sortedProductsDsc,
    });
  };

  SortByPriceLowToHigh = () => {
    let sortedProductsDsc;
    sortedProductsDsc = this.state.Items.sort((a, b) => {
      return (
        parseInt(a.fld_website_discount_price) -
        parseInt(b.fld_website_discount_price)
      );
    });

    this.setState({
      Items: sortedProductsDsc,
    });
  };

  SortByAlphabeticallyAtoZ = () => {
    this.setState({
      Items: this.state.Items.sort((a, b) =>
        a.fld_variantname > b.fld_variantname ? 1 : -1
      ),
    });
  };

  SortByAlphabeticallyZtoA = () => {
    this.setState({
      Items: this.state.Items.sort((a, b) =>
        b.fld_variantname > a.fld_variantname ? 1 : -1
      ),
    });
  };

  // SortByInStock=()=>{

  //   let sortedProductsInStock;
  //   sortedProductsInStock= this.state.Items.filter((value)=> value.available_stock != "Out Of Stock")

  //   this.setState({
  //       Items:sortedProductsInStock,
  //       InStockItemCount: sortedProductsInStock.length

  //   })
  // }

  // SortByOutOfStockStock=()=>{
  //   let sortedProductsOutOfStock;
  //   sortedProductsOutOfStock= this.state.Items.filter((value)=> value.available_stock == "Out Of Stock")

  //   this.setState({
  //       Items:sortedProductsOutOfStock,
  //       OutOfStockItemsCount: sortedProductsOutOfStock.length
  //   })
  // }

  // SortByPriceRangeSlider = () =>{
  //   let sortedProductsByPriceRange;
  //   sortedProductsByPriceRange= this.state.Items.filter((value)=> {
  //     return parseInt(value.fld_website_discount_price) <= parseInt(this.state.RangeSliderValue, 10)})

  //   this.setState({
  //     Items: sortedProductsByPriceRange
  //   })
  // }

  render() {
    const Items = this.state.Items;
    const horizontalLabels = {
      0: "Low",

      100: "High",
    };
    return (
      <>
        {/* <Header /> */}
        <div className="listingpage-banner">
          <InnerBanner />
        </div>
        <div class="listing-page-container">
          <div class="row">
            {/* <div class="col-lg-3 col-md-4 col-12" id="main-collection-filters"> */}
            {/* <div class="facets-container">
                <details
                  // id="Details-1-template--15673555648751__product-grid"
                  class="disclosure-has-popup facets__disclosure js-filter"
                  
                >
                  <summary class="facets__summary caption-large focus-offset">
                    <div className="justify-content-between listing-page">
                      <span>Availability</span>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        class="icon icon-caret"
                        viewBox="0 0 10 6"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </summary>
                  <div
                    id="Facet-1-template--15673555648751__product-grid"
                    class="facets__display"
                  >
                    <div class="facets__header">
                      <span class="facets__selected no-js-hidden">
                        0 selected
                      </span>
                      <facet-remove>
                        <a
                          href="/collections/all"
                          class="facets__reset link underlined-link"
                        >
                          Reset
                        </a>
                      </facet-remove>
                    </div>

                    <ul class="facets__list list-unstyled" role="list">
                      <li class="list-menu__item facets__item">
                        <label for="Filter-Availability-1">
                          <input
                            type="checkbox"
                            name="filter.v.availability"
                            checked={this.state.InStockCheck == true ? true : false}
                            onChange={()=>{
                              // this.setState({
                              //   InStockCheck: this.state.InStockCheck == true ? false : true
                              // }, ()=>{
                              //   this.SortByOutOfStock()
                              // })
                             if(this.state.InStockCheck == false ){
                               this.setState({
                                InStockCheck: true
                               }, ()=>{
                                this.SortByInStock()
                               })
                             }else if(this.state.InStockCheck == true){
                              this.setState({
                                InStockCheck: false,
                                InStockItemCount: null
                              }, ()=>{
                                this.getItems()
                              })
                             }
                            }}
                          />
                          In stock ({this.state.InStockItemCount == null ? 0 : this.state.InStockItemCount})
                        </label>
                      </li>
                      <li class="list-menu__item facets__item">
                        <label for="Filter-Availability-2">
                          <input
                            type="checkbox"
                            name="filter.v.availability"
                            checked={this.state.OutOfStockCheck == true ? true : false}
                            onChange={()=>{
                              // this.setState({
                              //   InStockCheck: this.state.InStockCheck == true ? false : true
                              // }, ()=>{
                              //   this.SortByOutOfStock()
                              // })
                             if(this.state.OutOfStockCheck == false ){
                               this.setState({
                                OutOfStockCheck: true
                               }, ()=>{
                                this.SortByOutOfStockStock()
                               })
                             }else if(this.state.OutOfStockCheck == true){
                              this.setState({
                                OutOfStockCheck: false,
                                OutOfStockItemsCount: null
                              }, ()=>{
                                this.getItems()
                              })
                             }
                            }}
                          />
                          Out of stock ({this.state.OutOfStockItemsCount == null ? 0 : this.state.OutOfStockItemsCount})
                        </label>
                      </li>
                    </ul>
                  </div>
                </details> */}
            {/* <details
                  id="Details-1-template--15673555648751__product-grid"
                  class="disclosure-has-popup facets__disclosure js-filter"
                  data-index="1"
                >
                  <summary class="facets__summary caption-large focus-offset">
                    <div className="justify-content-between listing-page">
                      <span>Price</span>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        class="icon icon-caret"
                        viewBox="0 0 10 6"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </summary>
                  <div
                    id="Facet-1-template--15673555648751__product-grid"
                    class="facets__display"
                  >
                   <input
                   type="range"
                   min="0" max="5000"
                   value={this.state.RangeSliderValue}
                   onChange={(price)=>{
                     this.setState({
                      RangeSliderValue: price.target.value
                     },()=>{
                      this.SortByPriceRangeSlider()
                    })
                   }
                   }
                    />
                  </div>
                </details> */}
            {/* </div>
            </div> */}
            <div class="col-lg-12 col-md-12 col-12" id="for-desktop-view">
              <div class="row wbsortbar">
                {/* <div className="col-3 col-md-4 listing-page-breadcumb"> */}

                {/* change by laxmi---------------------- */}
                <div className="col-6 col-sm-6 col-md-4  listing-page-breadcumb">
                  <Breadcrumb className="breadcrumb-content">
                    <Breadcrumb.Item>
                      <Link to="/">Home </Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                      <Link to="#">{this.props.match.params.categoryname}</Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>

                {/* change by laxmi--------------------------- */}
                {/* <div class="wbcatsort col-md-8 col-9 d-flex justify-content-end"> */}

                <div class="wbcatsort col-6 col-sm-6 col-md-8  d-flex justify-content-end">
                  {/* end change--------------- */}
                  <div
                    class="product-count d-flex align-items-center"
                    role="status"
                  >
                    <h2 class="product-count__text">
                      <span id="ProductCountDesktop">
                        {this.state.Items.length} Products
                      </span>
                    </h2>
                  </div>
                  <div class="wbglbtn d-flex align-items-center">
                    <button class="gridv wblistgridbtn " aria-label="grid">
                      <svg
                        viewBox="0 0 52 52"
                        onClick={() => {
                          this.setState({
                            productView: "row rless grid product-grid",
                          });
                        }}
                      >
                        <path d="M14,16H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H14a2,2,0,0,1,2,2V14A2,2,0,0,1,14,16ZM4,12h8V4H4Z"></path>
                        <path d="M14,34H2a2,2,0,0,1-2-2V20a2,2,0,0,1,2-2H14a2,2,0,0,1,2,2V32A2,2,0,0,1,14,34ZM4,30h8V22H4Z"></path>
                        <path d="M14,52H2a2,2,0,0,1-2-2V38a2,2,0,0,1,2-2H14a2,2,0,0,1,2,2V50A2,2,0,0,1,14,52ZM4,48h8V40H4Z"></path>
                        <path d="M32,16H20a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2H32a2,2,0,0,1,2,2V14A2,2,0,0,1,32,16ZM22,12h8V4H22Z"></path>
                        <path d="M32,34H20a2,2,0,0,1-2-2V20a2,2,0,0,1,2-2H32a2,2,0,0,1,2,2V32A2,2,0,0,1,32,34ZM22,30h8V22H22Z"></path>
                        <path d="M32,52H20a2,2,0,0,1-2-2V38a2,2,0,0,1,2-2H32a2,2,0,0,1,2,2V50A2,2,0,0,1,32,52ZM22,48h8V40H22Z"></path>
                        <path d="M50,16H38a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2H50a2,2,0,0,1,2,2V14A2,2,0,0,1,50,16ZM40,12h8V4H40Z"></path>
                        <path d="M50,34H38a2,2,0,0,1-2-2V20a2,2,0,0,1,2-2H50a2,2,0,0,1,2,2V32A2,2,0,0,1,50,34ZM40,30h8V22H40Z"></path>
                        <path d="M50,52H38a2,2,0,0,1-2-2V38a2,2,0,0,1,2-2H50a2,2,0,0,1,2,2V50A2,2,0,0,1,50,52ZM40,48h8V40H40Z"></path>
                      </svg>
                    </button>
                    <button
                      class="listv wblistgridbtn "
                      aria-label="list"
                      onClick={() => {
                        this.setState({
                          productView: "row rless grid product-list",
                        });
                      }}
                    >
                      <svg viewBox="0 0 16 16">
                        <path d="M3 1H1V3H3V1Z"></path>
                        <path d="M3 5H1V7H3V5Z"></path>
                        <path d="M1 9H3V11H1V9Z"></path>
                        <path d="M3 13H1V15H3V13Z"></path>
                        <path d="M15 1H5V3H15V1Z"></path>
                        <path d="M15 5H5V7H15V5Z"></path>
                        <path d="M5 9H15V11H5V9Z"></path>
                        <path d="M15 13H5V15H15V13Z"></path>
                      </svg>
                    </button>
                  </div>
                  {/* <facet-filters-form class="small-hide"> */}

                  {/* change by laxmi remove small-hide class------------------------- */}
                  <facet-filters-form>
                    <form id="FacetFiltersForm">
                      <div class="facet-filters sorting caption">
                        <div class="facet-filters__field">
                          <span class="facet-filters__label">
                            {/* <label for="SortBy">Sort by:</label> */}
                          </span>
                          <div class="select">
                            <select
                              name="sort_by"
                              class="facet-filters__sort select__select caption-large"
                              id="SortBy"
                              aria-describedby="a11y-refresh-page-message"
                              value={this.state.Filter}
                              onChange={(text) => {
                                this.setState(
                                  {
                                    Filter: text.target.value,
                                  },
                                  () => {
                                    if (
                                      this.state.Filter == "Price, High to Low"
                                    ) {
                                      this.SortByPriceHighToLow();
                                    } else if (
                                      this.state.Filter == "Price, Low to High"
                                    ) {
                                      this.SortByPriceLowToHigh();
                                    } else if (
                                      this.state.Filter ==
                                      "Alphabetically, A to Z"
                                    ) {
                                      this.SortByAlphabeticallyAtoZ();
                                    } else if (
                                      this.state.Filter ==
                                      "Alphabetically, Z to A"
                                    ) {
                                      this.SortByAlphabeticallyZtoA();
                                    } else if (this.state.Filter == "Sort By") {
                                      PostApiCall.postRequest(
                                        {
                                          id: parseInt(
                                            this.props.match.params.categoryid
                                          ),
                                          stateid: null,
                                        },
                                        "GetProductsCatgory"
                                      ).then((results) =>
                                        // const objs = JSON.parse(result._bodyText)
                                        results.json().then((obj1) => {
                                          if (
                                            results.status == 200 ||
                                            results.status == 201
                                          ) {
                                            // console.log(obj1.data)
                                            this.setState({
                                              Items: obj1.data.filter(value => value.fld_status == "Active"),
                                            });
                                          }
                                        })
                                      );
                                    }
                                  }
                                );
                              }}
                            >
                              {this.state.FilterType.map((data) => (
                                <option key={data.value} value={data.value}>
                                  {data.label}
                                </option>
                              ))}
                            </select>
                            {/* <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-caret" viewBox="0 0 10 6">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z" fill="currentColor">
                                                        </path></svg> */}
                          </div>
                        </div>
                        <noscript>
                          <button
                            type="submit"
                            class="facets__button-no-js button"
                          >
                            Sort
                          </button>
                        </noscript>
                      </div>
                    </form>
                  </facet-filters-form>
                </div>
              </div>
              {this.state.Loading == true ? (
                <div class="d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    role="status"
                    style={{ color: "#c83363", width: "3rem", height: "3rem" }}
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <ul
                  class={this.state.productView}
                  id="product-grid"
                  data-id="template--15673555648751__product-grid"
                >
                  {this.state.Items.map((data, index) => {
                    return (
                      <li
                        class="grid__item col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 cless"
                        key={index}
                      >
                        <ListingPageCard
                          productViewClass={this.state.productView}
                          itemdata={data}
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>

            {/*--------------- commented start by laxmi bcz this is looking like extra code , not used any whrere -------------------*/}

            {/* <div class="col-lg-9 col-md-8 col-12" id="for-mobile-view">
              <div class="row wbsortbar">
                <div class="wbglbtn col-md-3 col-5">
                  <button class="gridv wblistgridbtn active" aria-label="grid">
                    <svg viewBox="0 0 52 52">
                      <path d="M14,16H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H14a2,2,0,0,1,2,2V14A2,2,0,0,1,14,16ZM4,12h8V4H4Z"></path>
                      <path d="M14,34H2a2,2,0,0,1-2-2V20a2,2,0,0,1,2-2H14a2,2,0,0,1,2,2V32A2,2,0,0,1,14,34ZM4,30h8V22H4Z"></path>
                      <path d="M14,52H2a2,2,0,0,1-2-2V38a2,2,0,0,1,2-2H14a2,2,0,0,1,2,2V50A2,2,0,0,1,14,52ZM4,48h8V40H4Z"></path>
                      <path d="M32,16H20a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2H32a2,2,0,0,1,2,2V14A2,2,0,0,1,32,16ZM22,12h8V4H22Z"></path>
                      <path d="M32,34H20a2,2,0,0,1-2-2V20a2,2,0,0,1,2-2H32a2,2,0,0,1,2,2V32A2,2,0,0,1,32,34ZM22,30h8V22H22Z"></path>
                      <path d="M32,52H20a2,2,0,0,1-2-2V38a2,2,0,0,1,2-2H32a2,2,0,0,1,2,2V50A2,2,0,0,1,32,52ZM22,48h8V40H22Z"></path>
                      <path d="M50,16H38a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2H50a2,2,0,0,1,2,2V14A2,2,0,0,1,50,16ZM40,12h8V4H40Z"></path>
                      <path d="M50,34H38a2,2,0,0,1-2-2V20a2,2,0,0,1,2-2H50a2,2,0,0,1,2,2V32A2,2,0,0,1,50,34ZM40,30h8V22H40Z"></path>
                      <path d="M50,52H38a2,2,0,0,1-2-2V38a2,2,0,0,1,2-2H50a2,2,0,0,1,2,2V50A2,2,0,0,1,50,52ZM40,48h8V40H40Z"></path>
                    </svg>
                  </button>
                  <button class="listv wblistgridbtn" aria-label="list">
                    <svg viewBox="0 0 16 16">
                      <path d="M3 1H1V3H3V1Z"></path>
                      <path d="M3 5H1V7H3V5Z"></path>
                      <path d="M1 9H3V11H1V9Z"></path>
                      <path d="M3 13H1V15H3V13Z"></path>
                      <path d="M15 1H5V3H15V1Z"></path>
                      <path d="M15 5H5V7H15V5Z"></path>
                      <path d="M5 9H15V11H5V9Z"></path>
                      <path d="M15 13H5V15H15V13Z"></path>
                    </svg>
                  </button>
                </div>

                <div class="wbcatsort col-md-9 col-7"> */}
            {/* <facet-filters-form class="small-hide"> */}

            {/* change by laxmi remove small-hide class------------------------- */}
            {/* <facet-filters-form>
                    <form id="FacetFiltersForm">
                      <div class="facet-filters sorting caption">
                        <div class="facet-filters__field">
                          <span class="mobile-facets__open">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              focusable="false"
                              role="presentation"
                              class="icon icon-filter"
                              fill="none"
                              viewBox="0 11 20 20"
                            >
                              <line
                                x1="16.5"
                                y1="17.5"
                                x2="3.5"
                                y2="17.5"
                                stroke="#3F7972"
                                stroke-linecap="round"
                              ></line>
                              <line
                                x1="16.5"
                                y1="24.5"
                                x2="3.5"
                                y2="24.5"
                                stroke="#3F7972"
                                stroke-linecap="round"
                              ></line>
                              <circle
                                cx="13"
                                cy="24.5"
                                r="2"
                                fill="white"
                                stroke="#3F7972"
                              ></circle>
                              <circle
                                cx="7"
                                cy="17.5"
                                r="2"
                                fill="white"
                                stroke="#3F7972"
                              ></circle>
                            </svg>

                            <span class="mobile-facets__open-label button-label">
                              Filter and sort
                            </span>
                          </span>
                        </div>
                      </div>
                    </form>
                  </facet-filters-form>
                </div>
              </div> */}

            {/* <ul
                class="row rless grid product-grid"
                id="product-grid"
                data-id="template--15673555648751__product-grid"
              >
                {this.state.Items.map((data, index) => {
                  return (
                    <li
                      class="grid__item col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 cless"
                      key={index}
                    >
                      <ListingPageCard itemdata={data} />
                    </li>
                  );
                })}
              </ul>
            </div> */}

            {/*----- commented end by laxmi bcz extra code , not used any whrere ------*/}
          </div>
        </div>
        {/* <Footer /> */}
      </>
    );
  }
}

export default ListingPage;
