import Offcanvas from "react-bootstrap/Offcanvas";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { FaAlignLeft } from "react-icons/fa";
import "../Leftdrawer/Leftdrawer.css";
import { HiPlusSm } from "react-icons/hi";
import Collapsible from "react-collapsible";
import PostApiCall from "../../../Helper/Api";
import { RiTwitterFill, RiFacebookFill } from "react-icons/ri";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import Logo from "../../../Images/Logo1.png";
import { Link } from "react-router-dom";

function Leftdrawer() {
  const [show, setShow] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  const [LoginData, setLogindata] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    var loginDetails = JSON.parse(localStorage.getItem("CustomerData"));

    if (loginDetails != null) {
      setLogindata(loginDetails);
    }

    PostApiCall.postRequest(
      {
        catCount: "*",
      },
      "GetProductCategories"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          //  console.log(obj1.data);
          setCategoryData(obj1.data);
        }
      })
    );
  }, []);

  return (
    <>
      <FaAlignLeft style={{ color: "#22b7c0" }} onClick={handleShow} />

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title
            style={{ color: "#c83363", fontSize: "22px" }}
          ></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          <ul className="leftd-ul">
            <li>
              <a className="li-link drawfontanchor" href="/">
                Home
              </a>
            </li>
            {LoginData.length == 0 ? (
              <li>
                <a className="li-link drawfontanchor" href="/login">
                  Login
                </a>
              </li>
            ) : (
              <li>
                <a className="li-link drawfontanchor" href="/account">
                  My Account
                </a>
              </li>
            )}
            <li
              style={{
                display: LoginData.length == 0 ? "block" : "none",
              }}
            >
              <a className="li-link drawfontanchor" href="/register">
                Register
              </a>
            </li>

            <li>
              {/* <a className="li-link drawfontanchor" href="/products/allproducts">
              Shop
              </a>
              <span className="hiplus">
                <HiPlusSm className="hiplus-icon" />
              </span> */}
              <div style={{ color: "#22b7c0" }}>
                <Collapsible
                  trigger={["Shop by Category", <MdOutlineKeyboardArrowDown />]}
                >
                  <ul class="mobile-menu-categories">
                    {categoryData.map((product, i) => (
                      <li>
                        <a
                          className="li-link drawfontanchor"
                          href={`${
                            process.env.PUBLIC_URL
                          }/${product.fld_categoryname.trim()}/${
                            product.fld_categoryid
                          }`}
                        >
                          {product.fld_categoryname}{" "}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Collapsible>
              </div>
            </li>
            <li>
              <a
                className="li-link drawfontanchor"
                href="/products/allproducts"
              >
                All Products{" "}
              </a>
            </li>
            <li>
              <a className="li-link drawfontanchor" href="/about-us">
                About Us
              </a>
            </li>
            <li>
              <a className="li-link drawfontanchor" href="/contact-us">
                Contact Us
              </a>
            </li>
            <li
              style={{
                display: LoginData.length == 0 ? "none" : "block",
              }}
            >
              <a
                className="li-link drawfontanchor"
                onClick={() => {
                  localStorage.removeItem("CustomerData");
                  window.location.href = "/";
                }}
              >
                Logout
              </a>
            </li>
          </ul>
          <div className="contact-wrapper">
            <p className="got-question">Got a question?</p>
            <p className="drawer-contact">
              <a href="tel:+919967855001">+91-99678 55001</a>
            </p>
          </div>

          <div className="contact-wrapper border-0">
            <p className="got-question">Follow us on:</p>
          </div>

          {/* somy draw icon fixed */}
          {/* <div className="fixed-bottom">
            <div className="row">
              <div className="col-5 fixediconsize">
                <div className="d-flex fixed-footer-drawer">
                  <div className="left-drawer-logo w-80">
                    <Link to="/">
                      {" "}
                      <img
                        alt="pink"
                        className="imgsizedraw"
                        src={Logo}
                        width="100%"
                      />{" "}
                    </Link>
                  </div>
                  <ul
                    className="footer-col1-ul listIconDraw"
                    style={{ marginTop: "auto" }}
                  >
                    <a
                      href="https://www.facebook.com/pinkparrotkids/"
                      target="_blank"
                    >
                      <li>
                        <RiFacebookFill />
                      </li>
                    </a>

                    <a href="https://www.instagram.com/pinkparrotkids/?igshid=f9jg2z9gtu6d">
                      <li>
                        <AiFillInstagram />
                      </li>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-12 fixediconsize">
              <div className="fixed-footer-drawer">
                {/* <div className="left-drawer-logo left-drawer-logoposition w-80">
                  <Link to="/">
                    {" "}
                    <img
                      alt="pink"
                      className="imgsizedraw"
                      src={Logo}
                      width="100%"
                    />{" "}
                  </Link>
                </div> */}
                <ul className="footer-col1-ul listIconDraw">
                  <a
                    href="https://www.facebook.com/pinkparrotkids/"
                    target="_blank"
                  >
                    <li className="facebook">
                      <RiFacebookFill className="drawsocialicon" />
                    </li>
                  </a>

                  <a
                    href="https://www.instagram.com/pinkparrotkids/?igshid=f9jg2z9gtu6d"
                    target="_blank"
                  >
                    <li className="instagram">
                      <AiFillInstagram className="drawsocialicon" />
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Leftdrawer;
