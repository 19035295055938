import { Component } from "react";
import "./Checkout.css";
import { store } from "../../context/store";
import CouponIcon from "../../Images/couponicon.webp";
import GetApiCall from "../../Helper/GetApi";
import parse from "html-react-parser";
import PostApiCall from "../../Helper/Api";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Notiflix from "notiflix";
import { ThemeConsumer } from "styled-components";
import Loader from "../../LoaderComponent/Loader";
import { findAllByDisplayValue } from "@testing-library/react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";

class Information extends Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      cartData: [],
      AddressData: [],
      selectedCategories: "",
      open: true,
      SearchText: "",
      Check: false,
      Uncheck: false,
      OnlinePayment: "",
      shippingAddress: null,
      billingAddress: null,
      COD: "No",
      CartdID: null,
      selectedCategories: "",
      CouponCard: false,
      open: true,
      SearchText: "",
      OfferData: [],
      loginData: [],
      CouponCode: null,
      cartID: null,
      CouponId: null,
      OfferErrorCode: "",
      OfferErrorMessage: "",
      AppliedCoupon: null,
      ShowCouponMoreInformation: false,
      EnteredCouponCode: null,
      CouponErrorCodeTextBox: null,
      OfferErrorMessageTextBox: "",
      Subtotal: 0,
      OfferDiscountAmount: 0,
      CouponDiscountAmount: 0,
      ShippingCharges: 0,
      GST: 0,
      GrandTotal: 0,
      CouponDataId: null,
      Loading: false,
      AddressCard: false,
      Title: "Home",
      Name: null,
      Mobile: null,
      Area: null,
      FlatNo: null,
      Landmark: null,
      CountryData: [],
      CityData: [],
      StateData: [],
      CountryId: 0,
      StateId: 0,
      CityId: 0,
      Country: null,
      State: null,
      City: null,
      Pincode: null,
      AddressId: null,
      NumRegex: /^[0-9]*$/,
      GSTNumber: null,
      Loading: false,
      SelectedAddress: [],
      SelectedBillingAddress: [],
      LoadingAplyCoupon: false,
      GSTonShipping: null,
    };
  }

  componentDidMount() {
    localStorage.removeItem("currentPage");
    localStorage.removeItem("SelectAddress");

    const store = this.context;

    this.getCartdetails();

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    GetApiCall.getRequest("GetOfferList").then((resultdes) =>
      resultdes.json().then((obj) => {
        // console.log(obj.data)
        this.setState({
          OfferData: obj.data.filter(
            (value) => value.fld_showonwebsite == "Active"
          ),
        });
      })
    );

    PostApiCall.postRequest(
      {
        whereClause: `where fld_customerid=${logindetails[0].fld_customerid}`,
      },
      "Get_Customer_Address"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          console.log(obj.data);
          this.setState({
            AddressData: obj.data,
          });
        }
      })
    );

    GetApiCall.getRequest("GetCountry").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          CountryData: obj.data,
        });

        if (obj.data.length != 0) {
          this.setState({
            CountryId: 101,
            Country: obj.data[102].label,
          });
        }
        PostApiCall.postRequest(
          {
            countryid: 101,
          },
          "GetState"
        ).then((results) =>
          results.json().then((objstate) => {
            if (results.status == 200 || results.status == 201) {
              if (objstate.data.length != 0) {
                this.setState({
                  StateId: objstate.data[0].value,

                  StateData: objstate.data,
                  State: objstate.data[0].label,
                });
              }

              PostApiCall.postRequest(
                {
                  stateid: objstate.data[0].value,
                },
                "GetCity"
              ).then((resultscity) =>
                resultscity.json().then((objcity) => {
                  if (resultscity.status == 200 || resultscity.status == 201) {
                    if (objcity.data.length != 0) {
                      this.setState({
                        CityId: objcity.data[0].value,
                        CityData: objcity.data,
                        City: objcity.data[0].label,
                      });
                    }
                  }
                })
              );
            }
          })
        );
      })
    );

    this.getCartdetails();
  }

  getCartdetails() {
    this.getCartwebsite();
  }

  getCartwebsite = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    ).then((result) => {
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          console.log(obj.data);
          this.setState(
            {
              cartData: obj.data,
              CartdID: obj.data[0].fld_cart_id,
              cartID: obj.data[0].fld_cart_id,
              CouponId: obj.data[0].fld_offerid,
              CouponDataId: obj.data[0].fld_coupon_id,
              CouponCode: obj.data[0].fld_code,
              Subtotal: obj.data[0].total_mrp,
              OfferDiscountAmount: obj.data[0].fld_cart_discount_amount,
              CouponDiscountAmount:
                obj.data[0].fld_coupon_discount == null ||
                  obj.data[0].fld_coupon_discount == 0
                  ? 0.0
                  : obj.data[0].fld_coupon_discount,
              ShippingCharges:
                obj.data[0].fld_shipping_charge == null
                  ? 0
                  : obj.data[0].fld_shipping_charge,
              GST: obj.data[0].fld_total_gst,
              GrandTotal: obj.data[0].fld_cart_amount,
              GSTonShipping: obj.data[0].fld_shipping_charge_gst == null
                ? 0.00
                : obj.data[0].fld_shipping_charge_gst,
            }
            // ,
            // () => {
            //   if (
            //     (this.state.cartData[0].fld_cart_id != null &&
            //       this.state.shippingAddress == null) ||
            //     this.state.shippingAddress == null
            //   ) {
            //     //console.log(this.state.cartData[0].fld_code)
            //     PostApiCall.postRequest(
            //       {
            //         orderid: this.state.cartID,
            //         couponid: this.state.cartData[0].fld_coupon_id,
            //         stateid: localStorage.getItem("ChangedPincode"),
            //         customerid:
            //           localStorage.getItem("CustomerData") == null
            //             ? null
            //             : logindetails[0].fld_customerid,
            //         status: "remove",
            //       },
            //       "ApplyOffer"
            //     ).then((result) =>
            //       result.json().then((obj3) => {
            //         if (result.status == 200 || result.status == 201) {
            //           this.setState({
            //             OfferErrorCode: "",
            //             OfferErrorMessage: "",
            //             shippingAddress: null,
            //           });
            //         }
            //       })
            //     );
            //   } else {
            //   }
            // }
          );
        }
      });
    });
  };

  removeCoupon() {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    if (
      (this.state.cartData[0].fld_code != null &&
        this.state.shippingAddress == null) ||
      this.state.shippingAddress == null
    ) {
      PostApiCall.postRequest(
        {
          orderid: this.state.cartID,
          couponid: this.state.cartData[0].fld_coupon_id,
          stateid: localStorage.getItem("ChangedPincode"),
          customerid:
            localStorage.getItem("CustomerData") == null
              ? null
              : logindetails[0].fld_customerid,
          status: "remove",
        },
        "ApplyOffer"
      ).then((result) =>
        result.json().then((obj3) => {
          if (result.status == 200 || result.status == 201) {
            Notiflix.Notify.Success("Coupon removed successfully");
          }
        })
      );
    } else {
    }
  }

  onChangeCountry(country) {
    // console.log(typeof(country.target.value));

    //  console.log(this.state.CountryData[parseInt(country.target.value) +1])
    this.setState({
      CountryId: country.target.value,
      Country: this.state.CountryData[parseInt(country.target.value) + 1].label,
    });
    //  this.props.setcountry(this.state.CountryData[country.target.value - 1].label);

    PostApiCall.postRequest(
      {
        countryid: country.target.value,
        Country:
          this.state.CountryData[parseInt(country.target.value) + 1].label,
      },
      "GetState"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //  this.props.setstate(obj.data[0].label)
          // console.log(obj);
          this.setState({
            StateId: obj.data[0].value,
            State: obj.data[0].label,
          });

          PostApiCall.postRequest(
            {
              stateid: obj.data[0].value,
            },
            "GetCity"
          ).then((resultscity) =>
            resultscity.json().then((objcity) => {
              if (resultscity.status == 200 || resultscity.status == 201) {
                this.setState({
                  CityData: objcity.data,
                  StateData: obj.data,
                });
                if (objcity.data.length > 0) {
                  // this.props.setcity(objcity.data[0].label)

                  this.setState({
                    CityId: objcity.data[0].value,
                    City: objcity.data[0].label,
                  });
                }
              }
            })
          );
        }
      })
    );
  }

  onChangeState(state) {
    //  this.props.setstate(state.target.value)
    this.setState({
      StateId: state.target.value,
    });

    for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {
      if (this.state.StateData[i].value == state.target.value) {
        // this.props.setstate();
        this.setState({
          State: this.state.StateData[i].label,
        });
      }
    }

    PostApiCall.postRequest(
      {
        stateid: state.target.value,
      },
      "GetCity"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length > 0) {
            // this.props.setcity(obj.data[0].label)
            this.setState({
              CityData: obj.data,
              CityId: obj.data[0].value,
              City: obj.data[0].label,
            });
          }
          // this.props.cityData(obj.data)
        }
      })
    );
  }

  onChangeCity(city) {
    // this.props.setcity(city.target.value)
    this.setState({
      CityId: city.target.value,
    });

    for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
      if (this.state.CityData[i].value == city.target.value) {
        this.setState({
          City: this.state.CityData[i].label,
        });
        // this.props.setcity(this.state.CityData[i].label);
      }
    }
  }

  render() {
    return (
      <>
        {/* {this.state.Loading == true ?
          <div class="d-flex justify-content-center m-5">
            <div class="spinner-border" role="status" style={{ color: "#c83363", width: "3rem", height: "3rem" }}>
              <span class="visually-hidden">Loading...</span>
            </div>
          </div> : */}
        <div className="content">
          <div className="wrap">
            <div className="main">
              <header class="main__header" role="banner">
                <h1 class="visually-hidden">Information</h1>
                <nav aria-label="Breadcrumb">
                  <ol class="breadcrumb " role="list">
                    <li class="breadcrumb__item breadcrumb__item--completed">
                      <a class="breadcrumb__link" href="/cart">
                        Cart
                      </a>
                      <svg
                        class="icon-svg icon-svg--color-adaptive-light icon-svg--size-10 breadcrumb__chevron-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        zoomAndPan="magnify"
                        viewBox="0 0 37.5 37.499999"
                        height="50"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                      >
                        <defs>
                          <clipPath id="id1">
                            <path
                              d="M 11.707031 4.90625 L 25.957031 4.90625 L 25.957031 32.65625 L 11.707031 32.65625 Z M 11.707031 4.90625 "
                              clip-rule="nonzero"
                            />
                          </clipPath>
                        </defs>
                        <g clip-path="url(#id1)">
                          <path
                            fill="rgb(45.098877%, 45.098877%, 45.098877%)"
                            d="M 25.882812 19.09375 C 25.890625 19.078125 25.902344 19.0625 25.910156 19.042969 C 25.917969 19.023438 25.921875 19.003906 25.929688 18.984375 C 25.933594 18.964844 25.941406 18.949219 25.945312 18.929688 C 25.949219 18.910156 25.949219 18.890625 25.949219 18.871094 C 25.953125 18.851562 25.957031 18.832031 25.957031 18.8125 C 25.957031 18.792969 25.953125 18.773438 25.949219 18.753906 C 25.949219 18.734375 25.949219 18.714844 25.945312 18.695312 C 25.941406 18.675781 25.933594 18.660156 25.929688 18.640625 C 25.921875 18.621094 25.917969 18.601562 25.910156 18.582031 C 25.902344 18.5625 25.890625 18.546875 25.882812 18.53125 C 25.875 18.511719 25.863281 18.492188 25.851562 18.476562 C 25.84375 18.457031 25.828125 18.441406 25.816406 18.425781 C 25.804688 18.414062 25.792969 18.398438 25.78125 18.386719 L 12.835938 5.179688 C 12.597656 4.9375 12.203125 4.933594 11.960938 5.167969 C 11.714844 5.40625 11.707031 5.792969 11.945312 6.035156 L 24.472656 18.8125 L 11.945312 31.589844 C 11.707031 31.832031 11.714844 32.21875 11.960938 32.457031 C 12.078125 32.574219 12.234375 32.628906 12.390625 32.628906 C 12.554688 32.628906 12.714844 32.566406 12.835938 32.445312 L 25.78125 19.242188 C 25.792969 19.226562 25.804688 19.210938 25.816406 19.199219 C 25.828125 19.183594 25.84375 19.167969 25.851562 19.148438 C 25.863281 19.132812 25.875 19.113281 25.882812 19.09375 Z M 25.882812 19.09375 "
                            fill-opacity="1"
                            fill-rule="nonzero"
                          />
                        </g>
                      </svg>
                    </li>

                    <li
                      class="breadcrumb__item breadcrumb__item"
                      aria-current="step"
                    >
                      <span class="breadcrumb__text">Information</span>
                      <svg
                        class="icon-svg icon-svg--color-adaptive-light icon-svg--size-10 breadcrumb__chevron-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        zoomAndPan="magnify"
                        viewBox="0 0 37.5 37.499999"
                        height="50"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                      >
                        <defs>
                          <clipPath id="id1">
                            <path
                              d="M 11.707031 4.90625 L 25.957031 4.90625 L 25.957031 32.65625 L 11.707031 32.65625 Z M 11.707031 4.90625 "
                              clip-rule="nonzero"
                            />
                          </clipPath>
                        </defs>
                        <g clip-path="url(#id1)">
                          <path
                            fill="rgb(45.098877%, 45.098877%, 45.098877%)"
                            d="M 25.882812 19.09375 C 25.890625 19.078125 25.902344 19.0625 25.910156 19.042969 C 25.917969 19.023438 25.921875 19.003906 25.929688 18.984375 C 25.933594 18.964844 25.941406 18.949219 25.945312 18.929688 C 25.949219 18.910156 25.949219 18.890625 25.949219 18.871094 C 25.953125 18.851562 25.957031 18.832031 25.957031 18.8125 C 25.957031 18.792969 25.953125 18.773438 25.949219 18.753906 C 25.949219 18.734375 25.949219 18.714844 25.945312 18.695312 C 25.941406 18.675781 25.933594 18.660156 25.929688 18.640625 C 25.921875 18.621094 25.917969 18.601562 25.910156 18.582031 C 25.902344 18.5625 25.890625 18.546875 25.882812 18.53125 C 25.875 18.511719 25.863281 18.492188 25.851562 18.476562 C 25.84375 18.457031 25.828125 18.441406 25.816406 18.425781 C 25.804688 18.414062 25.792969 18.398438 25.78125 18.386719 L 12.835938 5.179688 C 12.597656 4.9375 12.203125 4.933594 11.960938 5.167969 C 11.714844 5.40625 11.707031 5.792969 11.945312 6.035156 L 24.472656 18.8125 L 11.945312 31.589844 C 11.707031 31.832031 11.714844 32.21875 11.960938 32.457031 C 12.078125 32.574219 12.234375 32.628906 12.390625 32.628906 C 12.554688 32.628906 12.714844 32.566406 12.835938 32.445312 L 25.78125 19.242188 C 25.792969 19.226562 25.804688 19.210938 25.816406 19.199219 C 25.828125 19.183594 25.84375 19.167969 25.851562 19.148438 C 25.863281 19.132812 25.875 19.113281 25.882812 19.09375 Z M 25.882812 19.09375 "
                            fill-opacity="1"
                            fill-rule="nonzero"
                          />
                        </g>
                      </svg>
                    </li>
                    <li class="breadcrumb__item breadcrumb__item">
                      {/* <a class="breadcrumb__link" href="/shipping"> */}
                      Shipping & Payment
                      {/* </a> */}
                      {/* <svg
                        class="icon-svg icon-svg--color-adaptive-light icon-svg--size-10 breadcrumb__chevron-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        zoomAndPan="magnify"
                        viewBox="0 0 37.5 37.499999"
                        height="50"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                      >
                        <defs>
                          <clipPath id="id1">
                            <path
                              d="M 11.707031 4.90625 L 25.957031 4.90625 L 25.957031 32.65625 L 11.707031 32.65625 Z M 11.707031 4.90625 "
                              clip-rule="nonzero"
                            />
                          </clipPath>
                        </defs>
                        <g clip-path="url(#id1)">
                          <path
                            fill="rgb(45.098877%, 45.098877%, 45.098877%)"
                            d="M 25.882812 19.09375 C 25.890625 19.078125 25.902344 19.0625 25.910156 19.042969 C 25.917969 19.023438 25.921875 19.003906 25.929688 18.984375 C 25.933594 18.964844 25.941406 18.949219 25.945312 18.929688 C 25.949219 18.910156 25.949219 18.890625 25.949219 18.871094 C 25.953125 18.851562 25.957031 18.832031 25.957031 18.8125 C 25.957031 18.792969 25.953125 18.773438 25.949219 18.753906 C 25.949219 18.734375 25.949219 18.714844 25.945312 18.695312 C 25.941406 18.675781 25.933594 18.660156 25.929688 18.640625 C 25.921875 18.621094 25.917969 18.601562 25.910156 18.582031 C 25.902344 18.5625 25.890625 18.546875 25.882812 18.53125 C 25.875 18.511719 25.863281 18.492188 25.851562 18.476562 C 25.84375 18.457031 25.828125 18.441406 25.816406 18.425781 C 25.804688 18.414062 25.792969 18.398438 25.78125 18.386719 L 12.835938 5.179688 C 12.597656 4.9375 12.203125 4.933594 11.960938 5.167969 C 11.714844 5.40625 11.707031 5.792969 11.945312 6.035156 L 24.472656 18.8125 L 11.945312 31.589844 C 11.707031 31.832031 11.714844 32.21875 11.960938 32.457031 C 12.078125 32.574219 12.234375 32.628906 12.390625 32.628906 C 12.554688 32.628906 12.714844 32.566406 12.835938 32.445312 L 25.78125 19.242188 C 25.792969 19.226562 25.804688 19.210938 25.816406 19.199219 C 25.828125 19.183594 25.84375 19.167969 25.851562 19.148438 C 25.863281 19.132812 25.875 19.113281 25.882812 19.09375 Z M 25.882812 19.09375 "
                            fill-opacity="1"
                            fill-rule="nonzero"
                          />
                        </g>
                      </svg> */}
                    </li>
                  </ol>
                </nav>
                <div class="shown-if-js" data-alternative-payments=""></div>
              </header>
              <main className="main__content" role="main">
                <div
                  className="step"
                  data-step="contact_information"
                  data-last-step="false"
                >
                  <form
                    data-customer-information-form="true"
                    class="edit_checkout animate-floating-labels"
                  >
                    <div className="step__sections">
                      <div class="section__header section__headerphnview">
                        <div class="shippingphnmargintop layout-flex layout-flex--tight-vertical layout-flex--loose-horizontal layout-flex--wrap">
                          <h2
                            class="section__title layout-flex__item layout-flex__item--stretch shippingaddresstexFont"
                            id="main-header"
                            tabindex="-1"
                          >
                            Shipping Address
                          </h2>

                          <button
                            className="butn addnewaddressbtn"
                            type="button"
                            onClick={() => {
                              this.setState({
                                AddressCard: true,
                              });
                            }}
                          >
                            Add new Address
                          </button>
                        </div>
                      </div>
                      <Modal
                        backdrop={false}
                        centered
                        fade={false}
                        size="lg"
                        scrollable
                        isOpen={this.state.AddressCard == true ? true : false}
                      >
                        <ModalHeader
                          close={
                            <button
                              className="close crossChngepassword"
                              onClick={() => {
                                this.setState({
                                  AddressCard: false,
                                });
                              }}
                            >
                              ×
                            </button>
                          }
                          className="title"
                          style={{ alignItems: "center" }}
                        >
                          Add New Address
                        </ModalHeader>

                        <ModalBody>
                          <div className="row">
                            <div class="col-md-12 center">
                              <div class="form-group mb-3">
                                <label for="validationCustom01">
                                  Address Title<span class="mandatory">*</span>
                                </label>
                                <br />
                                <label class="radio-inline">
                                  <input
                                    type="radio"
                                    name="optradio"
                                    checked={
                                      this.state.Title === "Office"
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      this.setState({
                                        Title: "Office",
                                      });
                                    }}
                                  />
                                  Office
                                </label>
                                <label
                                  class="radio-inline"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    name="optradio"
                                    checked={
                                      this.state.Title === "Home" ? true : false
                                    }
                                    onChange={() => {
                                      // setTitle("Home")
                                      this.setState({
                                        Title: "Home",
                                      });
                                    }}
                                  />
                                  Home
                                </label>
                              </div>
                            </div>
                            <Row>
                              {/* col size add by laxmi------------------------------------- */}
                              {/* <Col> */}
                              <Col xs={12} s={12} md={12} lg={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Name"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="name@example.com"
                                    className="input"
                                    value={this.state.Name}
                                    onChange={(text) => {
                                      this.setState({
                                        Name: text.target.value,
                                      });
                                    }}
                                  />
                                </FloatingLabel>
                              </Col>
                              {/* col size add by laxmi------------------------------------- */}
                              {/* <Col> */}
                              <Col xs={12} s={12} md={12} lg={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Mobile Number"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="name@example.com"
                                    className="input"
                                    value={this.state.Mobile}
                                    onChange={(mobile) => {
                                      if (mobile.target.value.length <= 10) {
                                        this.setState({
                                          Mobile: mobile.target.value.replace(
                                            /\D/g,
                                            ""
                                          ),
                                        });
                                      }
                                    }}
                                  />
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              {/* col size add by laxmi------------------------------------- */}
                              {/* <Col> */}
                              <Col xs={12} s={12} md={12} lg={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Flat/House/Building No."
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="name@example.com"
                                    className="input"
                                    value={this.state.FlatNo}
                                    onChange={(text) => {
                                      this.setState({
                                        FlatNo: text.target.value,
                                      });
                                    }}
                                  />
                                </FloatingLabel>
                              </Col>
                              {/* col size add by laxmi------------------------------------- */}
                              {/* <Col> */}
                              <Col xs={12} s={12} md={12} lg={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Area/Street/Sector/Village"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="name@example.com"
                                    className="input"
                                    value={this.state.Area}
                                    onChange={(text) => {
                                      this.setState({
                                        Area: text.target.value,
                                      });
                                    }}
                                  />
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              {/* col size add by laxmi------------------------------------- */}
                              {/* <Col> */}
                              <Col xs={12} s={12} md={12} lg={6}>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Lankmark (optional)"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="name@example.com"
                                    className="input"
                                    value={this.state.Landmark}
                                    onChange={(text) => {
                                      this.setState({
                                        Landmark: text.target.value,
                                      });
                                    }}
                                  />
                                </FloatingLabel>
                              </Col>
                              <Col>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="GST Number (optional)"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="name@example.com"
                                    className="input"
                                    value={this.state.GSTNumber}
                                    onChange={(text) => {
                                      this.setState({
                                        GSTNumber: text.target.value,
                                      });
                                    }}
                                  />
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Pincode"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Pincode"
                                    className="input"
                                    value={this.state.Pincode}
                                    onChange={(pin) => {
                                      if (pin.target.value.length <= 6) {
                                        this.setState({
                                          Pincode: pin.target.value.replace(
                                            /\D/g,
                                            ""
                                          ),
                                        });
                                      }
                                    }}
                                  />
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              {/* col size add by laxmi------------------------------------- */}
                              {/* <Col> */}
                              <Col xs={12} s={12} md={12} lg={4}>
                                <Form.Label htmlFor="basic-url">
                                  Country
                                </Form.Label>

                                <Form.Select
                                  aria-label="Default select example"
                                  className="mb-3 pd-country"
                                  value={this.state.CountryId}
                                  onChange={this.onChangeCountry.bind(this)}
                                >
                                  {this.state.CountryData.map((schedule) => (
                                    <option
                                      key={schedule.label}
                                      value={schedule.value}
                                    >
                                      {schedule.label}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>
                              {/* col size add by laxmi------------------------------------- */}
                              {/* <Col> */}
                              <Col xs={12} s={12} md={12} lg={4}>
                                <Form.Label htmlFor="basic-url">
                                  State
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="mb-3 pd-country"
                                  value={this.state.StateId}
                                  onChange={this.onChangeState.bind(this)}
                                >
                                  {this.state.StateData.map((schedule) => (
                                    <option
                                      key={schedule.label}
                                      value={schedule.value}
                                    >
                                      {schedule.label}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>
                              {/* col size add by laxmi------------------------------------- */}
                              {/* <Col> */}
                              <Col xs={12} s={12} md={12} lg={4}>
                                <Form.Label htmlFor="basic-url">
                                  City
                                </Form.Label>

                                <Form.Select
                                  aria-label="Default select example"
                                  className="mb-3 pd-country"
                                  value={this.state.CityId}
                                  onChange={this.onChangeCity.bind(this)}
                                >
                                  {this.state.CityData.map((schedule) => (
                                    <option
                                      key={schedule.label}
                                      value={schedule.value}
                                    >
                                      {schedule.label}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>
                            </Row>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <button
                            className="butn"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState(
                                {
                                  // Loading: true
                                },
                                () => {
                                  if (this.state.Title != null) {
                                    if (this.state.Name != null) {
                                      if (this.state.Mobile != "") {
                                        if (
                                          this.state.NumRegex.test(
                                            this.state.Mobile
                                          ) &&
                                          this.state.Mobile.length == 10
                                        ) {
                                          if (this.state.FlatNo != null) {
                                            if (this.state.Area != null) {
                                              if (this.state.Country != null) {
                                                if (this.state.State != null) {
                                                  if (this.state.City != null) {
                                                    if (
                                                      this.state.Pincode != null
                                                    ) {
                                                      if (
                                                        this.state.NumRegex.test(
                                                          this.state.Pincode
                                                        ) &&
                                                        this.state.Pincode
                                                          .length == 6
                                                      ) {
                                                        if (
                                                          this.state.Country !=
                                                          null
                                                        ) {
                                                          if (
                                                            this.state.State !=
                                                            null
                                                          ) {
                                                            if (
                                                              this.state.City !=
                                                              null
                                                            ) {
                                                              this.setState(
                                                                {
                                                                  Loading: true,
                                                                },
                                                                () => {
                                                                  const login =
                                                                    localStorage.getItem(
                                                                      "CustomerData"
                                                                    );
                                                                  const loginDetails =
                                                                    JSON.parse(
                                                                      login
                                                                    );

                                                                  PostApiCall.postRequest(
                                                                    {
                                                                      CUSTOMERID:
                                                                        loginDetails[0]
                                                                          .fld_customerid,
                                                                      addressid:
                                                                        this
                                                                          .state
                                                                          .AddressId,
                                                                      title:
                                                                        this
                                                                          .state
                                                                          .Title,
                                                                      name: this
                                                                        .state
                                                                        .Name,
                                                                      mobileNo:
                                                                        this
                                                                          .state
                                                                          .Mobile,
                                                                      area: this
                                                                        .state
                                                                        .Area,
                                                                      flatno:
                                                                        this
                                                                          .state
                                                                          .FlatNo,
                                                                      landmark:
                                                                        this
                                                                          .state
                                                                          .Landmark,
                                                                      gstNumber:
                                                                        this
                                                                          .state
                                                                          .GSTNumber,
                                                                      country:
                                                                        this
                                                                          .state
                                                                          .CountryId,
                                                                      state:
                                                                        this
                                                                          .state
                                                                          .StateId,
                                                                      city: this
                                                                        .state
                                                                        .CityId,
                                                                      pincode:
                                                                        this
                                                                          .state
                                                                          .Pincode,
                                                                      action:
                                                                        "INSERT",
                                                                      longitude:
                                                                        this
                                                                          .state
                                                                          .Longitude,
                                                                      latitude:
                                                                        this
                                                                          .state
                                                                          .Latitude,
                                                                    },
                                                                    "Add_CustomerAddress"
                                                                  ).then(
                                                                    (result) =>
                                                                      result
                                                                        .json()
                                                                        .then(
                                                                          (
                                                                            obj
                                                                          ) => {
                                                                            if (
                                                                              result.status ==
                                                                              200 ||
                                                                              result.status ==
                                                                              201
                                                                            ) {
                                                                              this.setState(
                                                                                {
                                                                                  Loading: false,
                                                                                  AddressCard: false,
                                                                                }
                                                                              );

                                                                              Notiflix.Notify.Success(
                                                                                "Address Successfully Added"
                                                                              );
                                                                              window.location.reload();
                                                                            } else {
                                                                              this.setState(
                                                                                {
                                                                                  Loading: false,
                                                                                }
                                                                              );
                                                                              Notiflix.Notify.Failure(
                                                                                obj.data
                                                                              );
                                                                            }
                                                                          }
                                                                        )
                                                                  );
                                                                }
                                                              );
                                                            } else {
                                                              Notiflix.Notify.Failure(
                                                                "Please enter city"
                                                              );
                                                            }
                                                          } else {
                                                            Notiflix.Notify.Failure(
                                                              "Please enter state"
                                                            );
                                                          }
                                                        } else {
                                                          Notiflix.Notify.Failure(
                                                            "Please select country"
                                                          );
                                                        }
                                                      } else {
                                                        Notiflix.Notify.Failure(
                                                          "Please enter valid pincode"
                                                        );
                                                      }
                                                    } else {
                                                      Notiflix.Notify.Failure(
                                                        "Please enter pincode"
                                                      );
                                                    }
                                                  } else {
                                                    Notiflix.Notify.Failure(
                                                      "Please enter city"
                                                    );
                                                  }
                                                } else {
                                                  Notiflix.Notify.Failure(
                                                    "Please enter state"
                                                  );
                                                }
                                              } else {
                                                Notiflix.Notify.Failure(
                                                  "Please select country"
                                                );
                                              }
                                            } else {
                                              Notiflix.Notify.Failure(
                                                "Please enter Area/Colony/Street/Sector/Village"
                                              );
                                            }
                                          } else {
                                            Notiflix.Notify.Failure(
                                              "Please enter Flat/House/Company/Building/Apartment No."
                                            );
                                          }
                                        } else {
                                          Notiflix.Notify.Failure(
                                            "Please enter valid mobile number"
                                          );
                                        }
                                      } else {
                                        Notiflix.Notify.Failure(
                                          "Please enter mobile number"
                                        );
                                      }
                                    } else {
                                      Notiflix.Notify.Failure(
                                        "Please enter your Full Name"
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.Failure(
                                      "Please select Address Title"
                                    );
                                  }
                                }
                              );
                            }}
                          >
                            {this.state.Loading == true ? (
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            ) : (
                              "Save Address"
                            )}
                          </button>
                        </ModalFooter>
                      </Modal>

                      <div class="section section--shipping-address">
                        <div class="section__content">
                          <div className="section">
                            <div class="section__content">
                              <div class="row">
                                {this.state.AddressData.map((data, index) => (
                                  <div
                                    // class change by laxmi--------------
                                    // class="col-md-5"
                                    className="col-md-12 col-sm-12 col-lg-12 col-xl-6"
                                    style={{
                                      display:
                                        this.state.AddressData == null
                                          ? "none"
                                          : "block",
                                    }}
                                  >
                                    <div class="checkout-box">
                                      <div class="boxes">
                                        <div class="address-header">
                                          <input
                                            type="checkbox"
                                            checked={
                                              this.state.SelectedAddress
                                                .fld_address_id ===
                                                data.fld_address_id
                                                ? true
                                                : false
                                            }
                                            onChange={() => {
                                              this.setState(
                                                {
                                                  SelectedAddress: data,
                                                  shippingAddress:
                                                    data.fld_address_id,
                                                },
                                                () => {
                                                  // console.log(this.state.shippingAddress)
                                                  const det =
                                                    localStorage.getItem(
                                                      "CustomerData"
                                                    );
                                                  const details =
                                                    JSON.parse(det);
                                                  PostApiCall.postRequest(
                                                    {
                                                      customerid:
                                                        details[0]
                                                          .fld_customerid,
                                                      orderid:
                                                        this.state.CartdID,
                                                      addressid:
                                                        this.state
                                                          .shippingAddress,
                                                    },
                                                    "Add_shipping_chrg"
                                                  ).then((results) =>
                                                    results
                                                      .json()
                                                      .then((obj) => {
                                                        if (
                                                          results.status ==
                                                          200 ||
                                                          results.status == 201
                                                        ) {
                                                          this.getCartwebsite();
                                                        }
                                                      })
                                                  );
                                                }
                                              );
                                            }}
                                          ></input>
                                          <h5>{data.fld_address_type}</h5>
                                        </div>
                                        <div class="address-box">
                                          <div></div>
                                          <div>
                                            <p>
                                              <b>{data.fld_contact_name}</b>
                                            </p>
                                            <p>
                                              {data.fld_address_1},
                                              {data.fld_address_2},
                                              {data.CityName}-{data.fld_pincode}
                                              ,
                                            </p>
                                            <p>
                                              {data.StateName},
                                              {data.CountryName}
                                            </p>
                                            <p
                                              style={{
                                                display:
                                                  data.fld_landmark == null
                                                    ? "none"
                                                    : "block",
                                              }}
                                            >
                                              Landmark: {data.fld_landmark}
                                            </p>
                                            <p>
                                              Mobile: {data.fld_contact_number}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="section__content section__contentphnview">
                        <fieldset class="content-box contentphnview">
                          <legend class="visually-hidden">
                            Choose a billing address
                          </legend>
                          <div
                            class="radio-wrapper content-box__row"
                            data-same-billing-address=""
                          >
                            <div class="radio__input">
                              <input
                                class="input-radio"
                                data-backup="different_billing_address_true"
                                aria-controls="section--billing-address__different"
                                type="radio"
                                value="true"
                                name="checkout[different_billing_address]"
                                id="checkout_different_billing_address_true"
                                checked={this.state.Check}
                                onClick={() => {
                                  if (this.state.shippingAddress != null) {
                                    if (this.state.Check == true) {
                                      this.setState({
                                        Check: false,
                                        billingAddress:
                                          this.state.shippingAddress,
                                      });
                                    } else {
                                      this.setState({
                                        Check: true,
                                        billingAddress:
                                          this.state.shippingAddress,
                                      });
                                    }
                                  } else {
                                    Notiflix.Notify.Failure(
                                      "Please select your shipping address"
                                    );
                                  }
                                }}
                              />
                            </div>

                            <label
                              class="radio__label content-box__emphasis"
                              for="checkout_different_billing_address_false"
                            >
                              Billing address is same as shipping address
                            </label>
                          </div>
                        </fieldset>
                      </div>
                      <div
                        class="section sectionphnview section--shipping-address"
                        style={{
                          display: this.state.Check == true ? "none" : "block",
                        }}
                      >
                        <div class="section__header">
                          <div class="layout-flex layout-flex--tight-vertical layout-flex--loose-horizontal layout-flex--wrap">
                            <h2
                              class="section__title layout-flex__item layout-flex__item--stretch"
                              id="main-header"
                              tabindex="-1"
                            >
                              Billing Address
                            </h2>
                          </div>
                        </div>
                        <div class="section__content">
                          <div className="section">
                            <div class="section__content">
                              <div class="row">
                                {this.state.AddressData.map((data, index) => (
                                  <div
                                    // class change by laxmi---------------------------------------
                                    // class="col-md-5"
                                    className="col-md-12 col-sm-12 col-lg-12 col-xl-6"
                                    style={{
                                      display:
                                        this.state.AddressData == null
                                          ? "none"
                                          : "block",
                                    }}
                                  >
                                    <div class="checkout-box">
                                      <div class="boxes">
                                        <div class="address-header">
                                          <input
                                            type="checkbox"
                                            checked={
                                              this.state.SelectedBillingAddress
                                                .fld_address_id ===
                                                data.fld_address_id
                                                ? true
                                                : false
                                            }
                                            onChange={() => {
                                              this.setState({
                                                SelectedBillingAddress: data,
                                                billingAddress:
                                                  data.fld_address_id,
                                              });
                                            }}
                                          ></input>
                                          <h5>{data.fld_address_type}</h5>
                                        </div>
                                        <div class="address-box">
                                          <div></div>
                                          <div>
                                            <p>
                                              <b>{data.fld_contact_name}</b>
                                            </p>
                                            <p>
                                              {data.fld_address_1},
                                              {data.fld_address_2},
                                              {data.CityName}-{data.fld_pincode}
                                              ,
                                            </p>
                                            <p>
                                              {data.StateName},
                                              {data.CountryName}
                                            </p>
                                            <p
                                              style={{
                                                display:
                                                  data.fld_landmark == null
                                                    ? "none"
                                                    : "block",
                                              }}
                                            >
                                              Landmark:{data.fld_landmark}
                                            </p>
                                            <p>
                                              Mobile: {data.fld_contact_number}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </main>
            </div>
            <aside class="sidebar">
              <div class="sidebar__header">
                <a class="logo logo--left" href="#">
                  <img
                    alt=""
                    class="logo__image logo__image--medium"
                    src="//cdn.shopify.com/s/files/1/0623/7578/8783/files/logo.png?1321"
                  />
                </a>

                <h1 class="visually-hidden">Information</h1>
              </div>
              <div class="sidebar__content">
                <div
                  id="order-summary"
                  class="order-summary order-summary--is-collapsed"
                >
                  <h2 class="visually-hidden-if-js orderphntopbottom">
                    Order summary
                  </h2>
                  <div class="order-summary__sections ordersummerbottomphn">
                    <div class="order-summary__section order-summary__section--product-list">
                      <div class="order-summary__section__content">
                        <table class="product-table">
                          <caption class="visually-hidden">
                            Shopping cart
                          </caption>
                          <thead class="product-table__header">
                            <tr>
                              <th scope="col">
                                <span class="visually-hidden">
                                  Product image
                                </span>
                              </th>
                              <th scope="col">
                                <span class="visually-hidden">Description</span>
                              </th>
                              <th scope="col">
                                <span class="visually-hidden">Quantity</span>
                              </th>
                              <th scope="col">
                                <span class="visually-hidden">Price</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody data-order-summary-section="line-items">
                            {this.state.cartData.map((data, index) => {
                              return (
                                <tr class="product">
                                  <td class="product__image checkoutimgitem">
                                    <div class="product-thumbnail ">
                                      <div class="product-thumbnail__wrapper">
                                        <img
                                          alt="Skylofts Rabbit LED Night Lamp Plug"
                                          class="product-thumbnail__image"
                                          src={data.VariantImage}
                                        />
                                      </div>
                                      <span
                                        class="product-thumbnail__quantity"
                                        aria-hidden="true"
                                      >
                                        {data.fld_quantity}
                                      </span>
                                    </div>
                                  </td>
                                  <th class="product__description" scope="row">
                                    {/* checkoutshowitemfontphn add by laxmi-----------------for phn viewsize */}
                                    <span class="product__description__name order-summary__emphasis checkoutshowitemfontphn">
                                      {data.fld_variantname}
                                    </span>
                                    <span class="product__description__variant order-summary__small-text"></span>
                                  </th>
                                  {
                                    data.fld_mrp == data.fld_website_discount_price
                                      ?
                                      <td class="product__price" >
                                        <span class="order-summary__emphasis skeleton-while-loading checkoutshowitemfontphn">
                                          ₹
                                          {parseFloat(
                                            data.fld_website_discount_price
                                          ).toFixed(2)}
                                        </span>
                                      </td>
                                      :
                                      <td class="product__price" >
                                        {/* checkoutshowitemfontphn add by laxmi-----------------for phn viewsize */}
                                        <del class="order-summary__small-text skeleton-while-loading--sm checkoutshowitemfontphn" >
                                          ₹ {parseFloat(data.fld_mrp).toFixed(2)}
                                        </del>
                                        <br />

                                        {/* checkoutshowitemfontphn add by laxmi-----------------for phn viewsize */}
                                        <span class="order-summary__emphasis skeleton-while-loading checkoutshowitemfontphn">
                                          ₹
                                          {parseFloat(
                                            data.fld_website_discount_price
                                          ).toFixed(2)}
                                        </span>
                                      </td>
                                  }

                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {/* <div class="order-summary__scroll-indicator" aria-hidden="true" tabindex="-1" id="order-summary__scroll-indicator">
                                                    Scroll for more items
                                                    <svg aria-hidden="true" focusable="false" class="icon-svg icon-svg--size-12"> </svg>
                                                </div> */}
                      </div>
                    </div>
                    <div
                      class="order-summary__section order-summary__section--total-lines"
                      data-order-summary-section="payment-lines"
                    >
                      <div className="row coupon apply-offer-select-address mb-3">
                        <div className="col-12">
                          <div className="row">
                            <div
                              className="col-8"
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => {
                                if (this.state.shippingAddress != null) {
                                  this.setState({
                                    CouponCard: true,
                                  });
                                } else {
                                  Notiflix.Notify.Failure(
                                    "Please select the shipping Address"
                                  );
                                }
                              }}
                            >
                              <img
                                src={CouponIcon}
                                height="25px"
                                width="25px"
                                style={{ marginTop: "0px", marginRight: "5px" }}
                              ></img>
                              <p className="ml-5 mb-0 checkoutshowitemfontphn">
                                {this.state.CouponCode == null
                                  ? "Apply Coupon"
                                  : this.state.CouponCode}
                              </p>
                            </div>
                            <div
                              className="col-4"
                              style={{
                                display:
                                  this.state.CouponCode == null
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <p
                                style={{ cursor: "pointer" }}
                                className="removeCoupon"
                                onClick={() => {
                                  var login =
                                    localStorage.getItem("CustomerData");
                                  var logindetails = JSON.parse(login);

                                  var Order = localStorage.getItem("OrderData");
                                  var Orderdetails = JSON.parse(Order);

                                  PostApiCall.postRequest(
                                    {
                                      orderid: this.state.cartID,
                                      couponid: this.state.CouponDataId,
                                      stateid:
                                        localStorage.getItem("ChangedPincode"),
                                      customerid:
                                        localStorage.getItem("CustomerData") ==
                                          null
                                          ? null
                                          : logindetails[0].fld_customerid,
                                      status: "remove",
                                    },
                                    "ApplyOffer"
                                  ).then((result) =>
                                    result.json().then((obj3) => {
                                      // console.log(obj3.data, "apply offer");

                                      if (
                                        result.status == 200 ||
                                        result.status == 201
                                      ) {
                                        Notiflix.Notify.Success(
                                          "Coupon removed successfully"
                                        );

                                        this.setState({
                                          OfferErrorCode: "",
                                          OfferErrorMessage: "",
                                          shippingAddress: null,
                                        });

                                        this.getCartdetails();
                                        window.location.reload();
                                      } else {
                                        Notiflix.Notify.Failure(obj3.data);
                                      }
                                    })
                                  );
                                }}
                              >
                                Remove
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <table class="total-line-table">
                        <caption class="visually-hidden">Cost summary</caption>
                        <thead>
                          <tr>
                            <th scope="col">
                              <span class="visually-hidden">Description</span>
                            </th>
                            <th scope="col">
                              <span class="visually-hidden">Price</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="total-line-table__tbody">
                          <tr class="total-line total-line--subtotal">
                            <th class="total-line__name" scope="row">
                              Total MRP (Inclusive GST)
                            </th>
                            <td class="total-line__price">
                              <span
                                class="order-summary__emphasis skeleton-while-loading"
                                data-checkout-subtotal-price-target="68000"
                              >
                                ₹ {parseFloat(this.state.Subtotal).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr class="total-line total-line--shipping">
                            <th class="total-line__name" scope="row">
                              <span>Discount on MRP</span>
                            </th>
                            <td class="total-line__price">
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-total-shipping-target="1961"
                              >
                                ₹ {parseFloat(this.state.OfferDiscountAmount).toFixed(2)}
                              </span>
                            </td>
                          </tr>

                          <tr class="total-line total-line--shipping">
                            <th class="total-line__name" scope="row">
                              <span>Coupon Discount</span>
                            </th>
                            <td class="total-line__price">
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-total-shipping-target="1961"
                              >
                                ₹ {parseFloat(this.state.CouponDiscountAmount).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr class="total-line total-line--shipping">
                            <th class="total-line__name" scope="row">
                              <span>GST</span>
                            </th>
                            <td class="total-line__price">
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-total-shipping-target="1961"
                              >
                                ₹ {parseFloat(this.state.GST).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr class="total-line total-line--shipping">
                            <th class="total-line__name" scope="row">
                              <span>Convenience Fee</span>
                            </th>
                            <td class="total-line__price">
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-total-shipping-target="1961"
                              >
                                ₹ {parseFloat(this.state.ShippingCharges).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr class="total-line total-line--shipping">
                            <th class="total-line__name" scope="row">
                              <span>GST on Shipping</span>
                            </th>
                            <td class="total-line__price">
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-total-shipping-target="1961"
                              >
                                ₹ {parseFloat(this.state.GSTonShipping).toFixed(2)}
                              </span>
                            </td>
                          </tr>

                          <tr
                            class="total-line total-line--shipping"
                            style={{
                              borderBottom: "1px solid rgba(175,175,175,0.34)",
                            }}
                          >
                            <th class="total-line__name_plain" scope="row">
                              <span></span>
                            </th>
                            <td class="total-line__price_plain">
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-total-shipping-target="1961"
                              ></span>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot class="total-line-table__footer">
                          <tr class="total-line">
                            <th
                              class="total-line__name payment-due-label"
                              scope="row"
                              style={{ paddingTop: "1.5rem !important" }}
                            >
                              <span class="payment-due-label__total">
                                Total (Inclusive of all Taxes)
                              </span>
                              {/* <span class="payment-due-label__taxes order-summary__small-text hidden" data-checkout-taxes="">
                                                                Including <span data-checkout-total-taxes-target="0">$0.00</span> in taxes
                                                            </span> */}
                            </th>
                            <td
                              class="total-line__price payment-due"
                              data-presentment-currency="USD"
                            >
                              <span class="payment-due__price skeleton-while-loading--lgskeleton-while-loading order-summary__emphasis">
                                ₹ {parseFloat(this.state.GrandTotal).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      <div class="section section--shipping-method ">
                        <div class="section__header">
                          <h2
                            class="section__title"
                            id="main-header"
                            tabindex="-1"
                          >
                            Payment method
                          </h2>
                        </div>
                        <div class="section__content ">
                          <div class="content-box" data-shipping-methods="">
                            <div class="content-box__row">
                              <div
                                class="radio-wrapper"
                                data-shipping-method="shopify-Standard-19.58"
                              >
                                <div class="radio__input">
                                  <input
                                    class="input-radio"
                                    type="radio"
                                    checked={
                                      this.state.COD == "No" ? true : false
                                    }
                                    onChange={() => {
                                      this.setState({
                                        COD: "No",
                                      });
                                    }}
                                  />
                                </div>
                                <label
                                  class="radio__label"
                                  for="checkout_shipping_rate_id_shopify-standard-19_58"
                                >
                                  <span
                                    class="radio__label__primary"
                                    data-shipping-method-label-title="Standard"
                                  >
                                    Credit Card/ Debit card / Net Banking
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div
                            class="content-box mt-4"
                            data-shipping-methods=""
                          >
                            <div class="content-box__row">
                              <div
                                class="radio-wrapper"
                                data-shipping-method="shopify-Standard-19.58"
                              >
                                <div class="radio__input">
                                  <input
                                    class="input-radio"
                                    type="radio"
                                    checked={
                                      this.state.COD == "Yes" ? true : false
                                    }
                                    onChange={() => {
                                      this.setState({
                                        COD: "Yes",
                                      });
                                    }}
                                  />
                                </div>
                                <label
                                  class="radio__label"
                                  for="checkout_shipping_rate_id_shopify-standard-19_58"
                                >
                                  <span
                                    class="radio__label__primary"
                                    data-shipping-method-label-title="Standard"
                                  >
                                    Cash on Delivery
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="step__footer">
                        <div className="returbackbtnphn">
                          <button
                            type="button"
                            className="butn shippingbtn mt-lg-3 mt-0"
                            style={{ width: "30%" }}
                            onClick={() => {
                              if (this.state.shippingAddress != null) {
                                if (this.state.billingAddress != null) {
                                  const det =
                                    localStorage.getItem("CustomerData");
                                  const details = JSON.parse(det);
                                  PostApiCall.postRequest(
                                    {
                                      customerid: details[0].fld_customerid,
                                      cartid: this.state.CartdID,
                                      orderstatus: "INPROCESS",
                                      paymentstaus: "Payment Initiated",
                                      paymentmode:
                                        this.state.COD == "Yes"
                                          ? "COD"
                                          : "OnlinePayment",
                                      paymenttrxno: "",
                                      paymentgateway: "",
                                      billingaddress: this.state.billingAddress,
                                      shippingaddress:
                                        this.state.shippingAddress,
                                    },
                                    "PlaceOrder"
                                  ).then((results) =>
                                    results.json().then((obj) => {
                                      if (
                                        results.status == 200 ||
                                        results.status == 201
                                      ) {
                                        localStorage.setItem(
                                          "OrderDetails",
                                          JSON.stringify(obj.data)
                                        );

                                        window.location.href = "/shipping";
                                      }
                                    })
                                  );
                                } else {
                                  Notiflix.Notify.Failure(
                                    "Please select your billing address"
                                  );
                                }
                              } else {
                                Notiflix.Notify.Failure(
                                  "Please select your shipping address"
                                );
                              }
                            }}
                          >
                            Continue to Shipping
                          </button>

                          <a
                            class="returntocartbtnphn step__footer__previous-link return_to_previous_page"
                            href="/cart"
                          >
                            <svg
                              focusable="false"
                              aria-hidden="true"
                              class="icon-svg icon-svg--color-accent icon-svg--size-10 previous-link__icon"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 10 10"
                            >
                              <path d="M8 1L7 0 3 4 2 5l1 1 4 4 1-1-4-4"></path>
                            </svg>
                            <span class="step__footer__previous-link-content">
                              Return to cart
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Modal
                  isOpen={this.state.CouponCard == true ? true : false}
                  centered
                  fullscreen="xl"
                  size="lg"
                  scrollable="true"
                >
                  <ModalHeader
                    close={
                      <button
                        className="close"
                        onClick={() => {
                          this.setState({
                            CouponCard: false,
                            CouponErrorCodeTextBox: null,
                            OfferErrorMessage: "",
                            EnteredCouponCode: null,
                          });
                        }}
                      >
                        ×
                      </button>
                    }
                    className="title"
                    style={{ alignItems: "center" }}
                  >
                    Coupons and Offers
                  </ModalHeader>
                  <ModalBody>
                    <div className="row">
                      <div className="col-6 apply-coupon-input">
                        <input
                          type="text"
                          placeholder="Enter Coupon Code"
                          class="form-control mb-0"
                          value={this.state.EnteredCouponCode}
                          onChange={(enteredcouponcode) => {
                            this.setState({
                              EnteredCouponCode: enteredcouponcode.target.value,
                            });
                          }}
                        ></input>
                        <p className="invalid-coupon-code-textbox">
                          {this.state.CouponErrorCodeTextBox}
                        </p>
                        <p className="invalid-coupon-code-textbox-color">
                          {this.state.OfferErrorMessageTextBox}
                        </p>
                      </div>
                      <div className="col-6 apply-coupon-button">
                        <button
                          class="butn"
                          onClick={() => {
                            var login = localStorage.getItem("CustomerData");
                            var logindetails = JSON.parse(login);

                            if (this.state.EnteredCouponCode != null) {
                              this.setState(
                                {
                                  CouponErrorCodeTextBox: null,
                                  OfferErrorMessageTextBox: " ",
                                  Loading: true,
                                },
                                () => {
                                  PostApiCall.postRequest(
                                    {
                                      whereClause: `where UPPER(fld_code) = UPPER('${this.state.EnteredCouponCode}') AND cast(getdate() as date) between cast(fld_startdate as date) and cast(fld_enddate as date)`,
                                    },
                                    "SearchForOfferCode"
                                  ).then((results) =>
                                    // const objs = JSON.parse(result._bodyText)
                                    results.json().then((obj) => {
                                      if (
                                        results.status == 200 ||
                                        results.status == 201
                                      ) {
                                        if (obj.data.length != 0) {
                                          PostApiCall.postRequest(
                                            {
                                              orderid: this.state.cartID,
                                              couponid: obj.data[0].fld_offerid,
                                              stateid:
                                                localStorage.getItem(
                                                  "ChangedPincode"
                                                ) == null
                                                  ? null
                                                  : localStorage.getItem(
                                                    "ChangedPincode"
                                                  ),
                                              customerid:
                                                localStorage.getItem(
                                                  "CustomerData"
                                                ) == null
                                                  ? null
                                                  : logindetails[0]
                                                    .fld_customerid,
                                              status: "apply",
                                            },
                                            "ApplyOffer"
                                          ).then((result) =>
                                            result.json().then((obj3) => {
                                              // //console.log(obj3.data);

                                              if (obj3.data) {
                                                if (obj3.data.length != 0) {
                                                  this.setState({
                                                    OfferErrorCode:
                                                      obj3.data[0].errorcode,
                                                    OfferErrorMessageTextBox:
                                                      obj3.data[0].error,
                                                    AppliedCoupon:
                                                      this.state
                                                        .EnteredCouponCode,
                                                    Loading: false,
                                                  });
                                                  this.getCartdetails();
                                                } else {
                                                }
                                              } else {
                                                this.setState({
                                                  Loading:
                                                    findAllByDisplayValue,
                                                });
                                                Notiflix.Notify.Failure(
                                                  "Soemthing went wrong. Please try again."
                                                );
                                              }
                                            })
                                          );
                                        } else {
                                          this.setState({
                                            CouponErrorCodeTextBox:
                                              "Coupon does not exist",
                                            Loading: false,
                                          });
                                        }
                                      }
                                    })
                                  );
                                }
                              );
                            } else {
                              Notiflix.Notify.Failure(
                                "Please enter Coupon Code"
                              );
                            }
                          }}
                        >
                          {this.state.Loading == true ? (
                            <Loader />
                          ) : (
                            "Apply Coupon"
                          )}
                        </button>
                      </div>
                    </div>
                    <div
                      class="rgbox-bottom text-center mt-5 mb-5"
                      style={{
                        display:
                          this.state.OfferData.length != 0 ? "block" : "none",
                      }}
                    >
                      <p>
                        <label>OR</label>
                      </p>
                    </div>
                    {this.state.OfferData?.map((data, i) => {
                      return (
                        <div className="coupon-card mb-3" key={i}>
                          <h5>{data.fld_code}</h5>
                          <p>{data.fld_name}</p>
                          <p className="coupon-terms-condition">
                            {data.fld_caption}
                          </p>
                          <p className="coupon-terms-condition">
                            {data.fld_description}
                          </p>
                          <b
                            className="coupon-terms-condition"
                            style={{
                              color:
                                this.state.OfferErrorCode == "1"
                                  ? "#fa4a5b"
                                  : "#60b246",
                            }}
                          >
                            {this.state.AppliedCoupon == data.fld_code
                              ? this.state.OfferErrorMessage
                              : ""}
                          </b>

                          <p
                            className="coupon-more"
                            style={{
                              display:
                                this.state.ShowCouponMoreInformation == false
                                  ? "block"
                                  : "none",
                            }}
                            onClick={() => {
                              this.setState({
                                ShowCouponMoreInformation: true,
                              });
                            }}
                          >
                            + More
                          </p>
                          <div
                            className="coupon-terms-condition ml-2"
                            style={{
                              display:
                                this.state.ShowCouponMoreInformation == true
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <p>
                              Terms and Conditions
                              <p>{parse(data.fld_termscondition)}</p>
                            </p>
                          </div>
                          <button
                            class="butn"
                            onClick={() => {
                              this.setState(
                                {
                                  LoadingAplyCoupon: true,
                                },
                                () => {
                                  var login =
                                    localStorage.getItem("CustomerData");
                                  var logindetails = JSON.parse(login);

                                  var Order = localStorage.getItem("OrderData");
                                  var Orderdetails = JSON.parse(Order);

                                  PostApiCall.postRequest(
                                    {
                                      orderid: this.state.cartID,
                                      couponid: data.fld_offerid,
                                      stateid:
                                        localStorage.getItem("ChangedPincode"),
                                      customerid:
                                        localStorage.getItem("CustomerData") ==
                                          null
                                          ? null
                                          : logindetails[0].fld_customerid,
                                      status: "apply",
                                    },
                                    "ApplyOffer"
                                  ).then((result) =>
                                    result.json().then((obj3) => {
                                      // //console.log(obj3.data);

                                      if (obj3.data) {
                                        if (obj3.data.length != 0) {
                                          this.setState({
                                            OfferErrorCode:
                                              obj3.data[0].errorcode,
                                            OfferErrorMessage:
                                              obj3.data[0].error,
                                            AppliedCoupon: data.fld_code,
                                            LoadingAplyCoupon: false,
                                          });
                                          this.getCartdetails();
                                        } else {
                                        }
                                      } else {
                                        Notiflix.Notify.Failure(
                                          "Soemthing went wrong. Please try again."
                                        );
                                      }
                                    })
                                  );
                                }
                              );
                            }}
                          >
                            {this.state.LoadingAplyCoupon == true ? (
                              <Loader />
                            ) : (
                              "Apply Coupon"
                            )}
                          </button>
                        </div>
                      );
                    })}
                  </ModalBody>
                  <ModalFooter></ModalFooter>
                </Modal>
              </div>
            </aside>
          </div>
        </div>

        {/* } */}
      </>
    );
  }
}

export default Information;
