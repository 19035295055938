import "../Adcart/Adcart.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import { AiOutlineClose } from "react-icons/ai";
import { FiMinus } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import ban from "../../../Images/pplogo1.webp";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsHandbag } from "react-icons/bs";
import PostApiCall from "../../../Helper/Api";
import { store } from "../../../context/store";
import moment from "moment";
import Notiflix from "notiflix";
import { Modal } from "react-bootstrap";


const Adcart = (props) => {
  const [show, setShow] = useState(false);
  const [cartData, setcartData] = useState([]);
  const [loginDetails, setloginDetails] = useState([]);
  const [cartId, setCartId] = useState(null);
  const [updateCart, setupdateCart] = useState(true);
  const [totalAmount, settotalAmount] = useState(null);
  const [discountAmount, setdiscountAmount] = useState(null);
  const [confrimToDelete, setconfrimToDelete] = useState(false);


  const cartCount = useContext(store);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    if (logindetails != null) {
      setloginDetails(logindetails);
    }

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        console.log(res1.data);
        if (res1.data) {
          cartCount.setCart(res1.data.length);

          if (res1.data.length != 0) {
            setCartId(res1.data[0].fld_cart_id);
            settotalAmount(res1.data[0].fld_cart_amount_preview);
            setcartData(res1.data);
            setdiscountAmount(res1.data[0].fld_cart_discount_amount);
          } else {
            setcartData(res1.data);
            localStorage.removeItem(
              "OrderData"
            );
          }
        }
      });
  }, [updateCart, props.openCartFetchData]);

  return (
    <>
      {/* <p>sadsasd</p> */}
      <BsHandbag onClick={handleShow} />
      <Modal
        show={confrimToDelete == true ? true : false}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        className="modalposition2 no-header"
      >

        <Modal.Body className="m-0 p-0" >
          <div style={{ padding: "15px" }}>
            Are you sure you want to clear the cart?
          </div>
        </Modal.Body>
        <Modal.Footer style={{ background: "white" }}>
          <a className="confirmtoDelete" onClick={() => {
            var login =
              localStorage.getItem("CustomerData");
            var logindetails = JSON.parse(login);

            PostApiCall.postRequest({
              orderid: cartId == null ? null : cartId,
              customerid: logindetails[0].fld_customerid
            }, "ClearCartData").then((result) =>
              result.json().then((obj) => {
                if (result.status == 200 || result.status == 201) {
                  setconfrimToDelete(false)
                  setupdateCart(!updateCart);
                }
              })
            );
          }}>
            Continue
          </a>
          <a className="confirmtoDelete" onClick={() => {
            setconfrimToDelete(false)
          }}>Cancel</a>
        </Modal.Footer>
      </Modal>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <div className="d-flex justify-content-between p-3">
          <p onClick={handleClose} style={{ cursor: "pointer" }}>{"< Back"}</p>
          {/* <p className="clearCart" style={{ display: cartData.length == 0 ? "none" : "block" }} onClick={() => {
            setconfrimToDelete(true)
          }}>Clear Cart</p> */}
        </div>
        <div className="cart-sum text-center">
          <h1>Order Summary</h1>
        </div>

        {/* <Offcanvas.Header>
          <Offcanvas.Title>Order Summary</Offcanvas.Title>
        </Offcanvas.Header> */}
        <Offcanvas.Body>
          {cartData.length != 0 ? (
            <div className="li-cart">
              {/* <div className="free-delivery-text">
              Congratulations, Your delivery charge is waived off!!!
            </div> */}
              <div className="li-cart-container">
                <div
                  className="li-cart-message"
                  style={{
                    color: "#c83363",
                    display: discountAmount == 0 ? "none" : "block",
                  }}
                >
                  Congratulations! You've saved ₹{discountAmount}
                </div>
                {cartData.map((data, index) => {
                  return (
                    <div className="item-available" key={index}>
                      <ul className="remove-border">
                        <li>
                          <div className="d-flex">
                            <div className="cart-drawer-image">
                              <img src={data.VariantImage}></img>
                            </div>
                            <div className="cart-drawer-other-details">
                              <div className="item-available-list">
                                <div className="item-available-text">
                                  {data.fld_variantname}
                                </div>
                                <div>
                                  <AiOutlineClose
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      var login =
                                        localStorage.getItem("CustomerData");
                                      var logindetails = JSON.parse(login);

                                      PostApiCall.postRequest(
                                        {
                                          cartID: data.fld_cart_id,
                                          customerid:
                                            localStorage.getItem(
                                              "CustomerData"
                                            ) == null
                                              ? null
                                              : logindetails[0].fld_customerid,
                                          cartlineid: data.fld_cart_dtl_id,
                                          stateid: null,
                                        },
                                        "DeleteCart"
                                      ).then((result) =>
                                        result.json().then((obj3) => {
                                          if (
                                            result.status == 200 ||
                                            result.status == 201
                                          ) {
                                            setupdateCart(!updateCart);
                                            Notiflix.Notify.Success(
                                              "Product is successfully deleted."
                                            );

                                            if (cartData.length == 0) {
                                              localStorage.removeItem(
                                                "OrderData"
                                              );
                                            }
                                          } else {
                                            Notiflix.Notify.Failure(obj3.data);
                                          }
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="item-available-list2">
                                <div>
                                  {/* <span className="item-qty">450gms</span> */}
                                  <span className="text-red-rupee">
                                    &#x20b9;{data.fld_website_discount_price}
                                  </span>
                                  <span class="discount-rupee" style={{ display: data.fld_website_discount_price == data.fld_mrp ? "none" : "inline" }}>
                                    {" "}
                                    &#x20b9;{data.fld_mrp}
                                  </span>
                                </div>
                                <div>
                                  <div className="qty">
                                    <span
                                      className="qty-minus"
                                      onClick={() => {
                                        var login =
                                          localStorage.getItem("CustomerData");
                                        var logindetails = JSON.parse(login);

                                        var Order =
                                          localStorage.getItem("OrderData");
                                        var Orderdetails = JSON.parse(Order);

                                        if (data.fld_quantity == 1) {
                                          PostApiCall.postRequest(
                                            {
                                              cartID: data.fld_cart_id,
                                              customerid:
                                                localStorage.getItem(
                                                  "CustomerData"
                                                ) == null
                                                  ? null
                                                  : logindetails[0].fld_customerid,
                                              cartlineid: data.fld_cart_dtl_id,
                                              stateid: null,
                                            },
                                            "DeleteCart"
                                          ).then((result) =>
                                            result.json().then((obj3) => {
                                              if (
                                                result.status == 200 ||
                                                result.status == 201
                                              ) {
                                                setupdateCart(!updateCart);
                                                Notiflix.Notify.Success(
                                                  "Product is successfully deleted."
                                                );

                                                if (cartData.length == 0) {
                                                  localStorage.removeItem(
                                                    "OrderData"
                                                  );
                                                }
                                              } else {
                                                Notiflix.Notify.Failure(obj3.data);
                                              }
                                            })
                                          );
                                        } else if (data.fld_quantity > 1) {
                                          PostApiCall.postRequest(
                                            {
                                              orderdate: moment().format("lll"),
                                              itemdetails: `
                           [
                             { 
                               "Item":${data.fld_variantid}, 
                               "Quantity":${-1}
                             }
                           ]
                           `,
                                              status: "INCART",
                                              customerid:
                                                localStorage.getItem(
                                                  "CustomerData"
                                                ) == null
                                                  ? null
                                                  : logindetails[0]
                                                    .fld_customerid,
                                              createdon: moment().format("lll"),
                                              updatedon: moment().format("lll"),
                                              orderid:
                                                localStorage.getItem(
                                                  "OrderData"
                                                ) == null
                                                  ? 0
                                                  : Orderdetails[0].order_id,

                                              updatedby:
                                                localStorage.getItem(
                                                  "CustomerData"
                                                ) == null
                                                  ? null
                                                  : logindetails[0].fld_userid,
                                            },
                                            "AddToCartWebsite"
                                          ).then((result) =>
                                            result.json().then((obj3) => {
                                              // console.log(obj3.data);

                                              if (
                                                result.status == 200 ||
                                                result.status == 201
                                              ) {
                                                setupdateCart(!updateCart);

                                                Notiflix.Notify.Success(
                                                  "Product quantity updated."
                                                );

                                                if (
                                                  localStorage.getItem(
                                                    "OrderData"
                                                  ) == null
                                                ) {
                                                  // console.log('datastore')
                                                  localStorage.setItem(
                                                    "OrderData",
                                                    JSON.stringify(obj3.data)
                                                  );
                                                }
                                              } else {
                                                Notiflix.Notify.Failure(
                                                  obj3.data
                                                );
                                              }
                                            })
                                          );
                                        }
                                      }}
                                    >
                                      <FiMinus />
                                    </span>
                                    <span className="qty-add">
                                      {data.fld_quantity}
                                    </span>
                                    <span className="qty-plus">
                                      <AiOutlinePlus
                                        onClick={() => {
                                          var login =
                                            localStorage.getItem(
                                              "CustomerData"
                                            );
                                          var logindetails = JSON.parse(login);

                                          var Order =
                                            localStorage.getItem("OrderData");
                                          var Orderdetails = JSON.parse(Order);
                                          PostApiCall.postRequest(
                                            {
                                              orderdate: moment().format("lll"),
                                              itemdetails: `
                                                  [
                                                    { 
                                                      "Item":${data.fld_variantid
                                                }, 
                                                      "Quantity":${+1}
                                                    }
                                                  ]
                                                  `,
                                              status: "INCART",
                                              customerid:
                                                localStorage.getItem(
                                                  "CustomerData"
                                                ) == null
                                                  ? null
                                                  : logindetails[0]
                                                    .fld_customerid,
                                              createdon: moment().format("lll"),
                                              updatedon: moment().format("lll"),
                                              orderid:
                                                localStorage.getItem(
                                                  "OrderData"
                                                ) == null
                                                  ? 0
                                                  : Orderdetails[0].order_id,

                                              updatedby:
                                                localStorage.getItem(
                                                  "CustomerData"
                                                ) == null
                                                  ? null
                                                  : logindetails[0].fld_userid,
                                            },
                                            "AddToCartWebsite"
                                          ).then((result) =>
                                            result.json().then((obj3) => {
                                              if (
                                                result.status == 200 ||
                                                result.status == 201
                                              ) {
                                                setupdateCart(!updateCart);
                                                Notiflix.Notify.Success(
                                                  "Product quantity updated."
                                                );

                                                if (
                                                  localStorage.getItem(
                                                    "OrderData"
                                                  ) == null
                                                ) {
                                                  // console.log('datastore')
                                                  localStorage.setItem(
                                                    "OrderData",
                                                    JSON.stringify(obj3.data)
                                                  );
                                                }
                                              } else {
                                                Notiflix.Notify.Failure(
                                                  obj3.data
                                                );
                                              }
                                            })
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
              {/* banner */}
              {/* <div className="banner">
              <div className="banner-title">
                Stop paying delivery charges. Join Meatopia today!
              </div>
              <div className="banner-nm">
                <div className="banner-nm-img">
                  <img src={ban} alt="ban" />
                </div>
                <div className="banner-nm-join">
                  <span>JOIN NOW</span>
                  <MdKeyboardArrowRight />
                </div>
              </div>
            </div> */}
              {/* banner */}
              <div className="bill">
                <div className="bill-heading">Bill Details</div>
                {cartData.map((data, index) => {
                  if (index == 0) {
                    return (
                      <div>
                        <div className="bill-details">
                          <div className="bill-details-total">
                            Total MRP
                          </div>
                          <div className="bill-details-amount">
                            {" "}
                            ₹ {parseFloat(data.total_mrp).toFixed(2)}
                          </div>
                        </div>
                        <div className="bill-details">
                          <div className="bill-details-total">Discount on MRP</div>
                          <div className="bill-details-amount">
                            {" "}
                            ₹ {data.fld_cart_discount_amount}
                          </div>
                        </div>
                        <div className="bill-total">
                          <div className="bill-details-totalam">
                            Total (Inclusive of all Taxes){" "}
                          </div>
                          <div className="bill-details-amru">
                            {" "}
                            &#x20b9;
                            {parseFloat(data.fld_cart_amount_preview).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            <div className="li-cart">
              <div className="li-empty-cart-container">
                <div className="empty-cart">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 1"
                    width="100%"
                    height="500"
                    viewBox="0 0 896 747.97143"
                  >
                    <title>empty_cart</title>
                    <path
                      d="M193.634,788.75225c12.42842,23.049,38.806,32.9435,38.806,32.9435s6.22712-27.47543-6.2013-50.52448-38.806-32.9435-38.806-32.9435S181.20559,765.7032,193.634,788.75225Z"
                      transform="translate(-152 -76.01429)"
                      fill="#2f2e41"
                    />
                    <path
                      d="M202.17653,781.16927c22.43841,13.49969,31.08016,40.3138,31.08016,40.3138s-27.73812,4.92679-50.17653-8.57291S152,772.59636,152,772.59636,179.73811,767.66958,202.17653,781.16927Z"
                      transform="translate(-152 -76.01429)"
                      fill="#c83363"
                    />
                    <rect
                      x="413.2485"
                      y="35.90779"
                      width="140"
                      height="2"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="513.2485"
                      y="37.40779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="452.2485"
                      y="37.40779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="484.2485"
                      y="131.90779"
                      width="140"
                      height="2"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="522.2485"
                      y="113.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="583.2485"
                      y="113.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="670.2485"
                      y="176.90779"
                      width="140"
                      height="2"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="708.2485"
                      y="158.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="769.2485"
                      y="158.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="656.2485"
                      y="640.90779"
                      width="140"
                      height="2"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="694.2485"
                      y="622.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="755.2485"
                      y="622.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="417.2485"
                      y="319.90779"
                      width="140"
                      height="2"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="455.2485"
                      y="301.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="516.2485"
                      y="301.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="461.2485"
                      y="560.90779"
                      width="140"
                      height="2"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="499.2485"
                      y="542.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="560.2485"
                      y="542.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="685.2485"
                      y="487.90779"
                      width="140"
                      height="2"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="723.2485"
                      y="469.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="784.2485"
                      y="469.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <polygon
                      points="362.06 702.184 125.274 702.184 125.274 700.481 360.356 700.481 360.356 617.861 145.18 617.861 134.727 596.084 136.263 595.347 146.252 616.157 362.06 616.157 362.06 702.184"
                      fill="#2f2e41"
                    />
                    <circle
                      cx="156.78851"
                      cy="726.03301"
                      r="17.88673"
                      fill="#3f3d56"
                    />
                    <circle
                      cx="333.10053"
                      cy="726.03301"
                      r="17.88673"
                      fill="#3f3d56"
                    />
                    <circle
                      cx="540.92726"
                      cy="346.153"
                      r="11.07274"
                      fill="#3f3d56"
                    />
                    <path
                      d="M539.38538,665.76747H273.23673L215.64844,477.531H598.69256l-.34852,1.10753Zm-264.8885-1.7035H538.136l58.23417-184.82951H217.95082Z"
                      transform="translate(-152 -76.01429)"
                      fill="#2f2e41"
                    />
                    <polygon
                      points="366.61 579.958 132.842 579.958 82.26 413.015 418.701 413.015 418.395 413.998 366.61 579.958"
                      fill="#f2f2f2"
                    />
                    <polygon
                      points="451.465 384.7 449.818 384.263 461.059 341.894 526.448 341.894 526.448 343.598 462.37 343.598 451.465 384.7"
                      fill="#2f2e41"
                    />
                    <rect
                      x="82.2584"
                      y="458.58385"
                      width="345.2931"
                      height="1.7035"
                      fill="#2f2e41"
                    />
                    <rect
                      x="101.45894"
                      y="521.34377"
                      width="306.31852"
                      height="1.7035"
                      fill="#2f2e41"
                    />
                    <rect
                      x="254.31376"
                      y="402.36843"
                      width="1.7035"
                      height="186.53301"
                      fill="#2f2e41"
                    />
                    <rect
                      x="385.55745"
                      y="570.79732"
                      width="186.92877"
                      height="1.70379"
                      transform="translate(-274.73922 936.23495) rotate(-86.24919)"
                      fill="#2f2e41"
                    />
                    <rect
                      x="334.45728"
                      y="478.18483"
                      width="1.70379"
                      height="186.92877"
                      transform="translate(-188.46866 -52.99638) rotate(-3.729)"
                      fill="#2f2e41"
                    />
                    <rect y="745" width="896" height="2" fill="#2f2e41" />
                    <path
                      d="M747.41068,137.89028s14.61842,41.60627,5.62246,48.00724S783.39448,244.573,783.39448,244.573l47.22874-12.80193-25.86336-43.73993s-3.37348-43.73992-3.37348-50.14089S747.41068,137.89028,747.41068,137.89028Z"
                      transform="translate(-152 -76.01429)"
                      fill="#a0616a"
                    />
                    <path
                      d="M747.41068,137.89028s14.61842,41.60627,5.62246,48.00724S783.39448,244.573,783.39448,244.573l47.22874-12.80193-25.86336-43.73993s-3.37348-43.73992-3.37348-50.14089S747.41068,137.89028,747.41068,137.89028Z"
                      transform="translate(-152 -76.01429)"
                      opacity="0.1"
                    />
                    <path
                      d="M722.87364,434.46832s-4.26731,53.34138,0,81.07889,10.66828,104.5491,10.66828,104.5491,0,145.08854,23.4702,147.22219,40.53945,4.26731,42.6731-4.26731-10.66827-12.80193-4.26731-17.06924,8.53462-19.20289,0-36.27213,0-189.8953,0-189.8953l40.53945,108.81641s4.26731,89.61351,8.53462,102.41544-4.26731,36.27213,10.66827,38.40579,32.00483-10.66828,40.53945-14.93559-12.80193-4.26731-8.53462-6.401,17.06924-8.53462,12.80193-10.66828-8.53462-104.54909-8.53462-104.54909S879.69728,414.1986,864.7617,405.664s-24.537,6.16576-24.537,6.16576Z"
                      transform="translate(-152 -76.01429)"
                      fill="#2f2e41"
                    />
                    <path
                      d="M761.27943,758.78388v17.06924s-19.20289,46.39942,0,46.39942,34.13848,4.8083,34.13848-1.59266V763.05119Z"
                      transform="translate(-152 -76.01429)"
                      fill="#2f2e41"
                    />
                    <path
                      d="M887.16508,758.75358v17.06924s19.20289,46.39941,0,46.39941-34.13848,4.80831-34.13848-1.59266V763.02089Z"
                      transform="translate(-152 -76.01429)"
                      fill="#2f2e41"
                    />
                    <circle
                      cx="625.28185"
                      cy="54.4082"
                      r="38.40579"
                      fill="#a0616a"
                    />
                    <path
                      d="M765.54674,201.89993s10.66828,32.00482,27.73752,25.60386l17.06924-6.401L840.22467,425.9337s-23.47021,34.13848-57.60869,12.80193S765.54674,201.89993,765.54674,201.89993Z"
                      transform="translate(-152 -76.01429)"
                      fill="#c83363"
                    />
                    <path
                      d="M795.41791,195.499l9.60145-20.26972s56.54186,26.67069,65.07648,35.20531,8.53462,21.33655,8.53462,21.33655l-14.93559,53.34137s4.26731,117.351,4.26731,121.61834,14.93559,27.73751,4.26731,19.20289-12.80193-17.06924-21.33655-4.26731-27.73751,27.73752-27.73751,27.73752Z"
                      transform="translate(-152 -76.01429)"
                      fill="#3f3d56"
                    />
                    <path
                      d="M870.09584,349.12212l-6.401,59.74234s-38.40579,34.13848-29.87117,36.27214,12.80193-6.401,12.80193-6.401,14.93559,14.93559,23.47021,6.401S899.967,355.52309,899.967,355.52309Z"
                      transform="translate(-152 -76.01429)"
                      fill="#a0616a"
                    />
                    <path
                      d="M778.1,76.14416c-8.51412-.30437-17.62549-.45493-24.80406,4.13321a36.31263,36.31263,0,0,0-8.5723,8.39153c-6.99153,8.83846-13.03253,19.95926-10.43553,30.92537l3.01633-1.1764a19.75086,19.75086,0,0,1-1.90515,8.46261c.42475-1.2351,1.84722.76151,1.4664,2.01085L733.543,139.792c5.46207-2.00239,12.25661,2.05189,13.08819,7.80969.37974-12.66123,1.6932-27.17965,11.964-34.59331,5.17951-3.73868,11.73465-4.88,18.04162-5.8935,5.81832-.935,11.91781-1.82659,17.49077.08886s10.31871,7.615,9.0553,13.37093c2.56964-.88518,5.44356.90566,6.71347,3.30856s1.33662,5.2375,1.37484,7.95506c2.73911,1.93583,5.85632-1.9082,6.97263-5.07112,2.62033-7.42434,4.94941-15.32739,3.53783-23.073s-7.72325-15.14773-15.59638-15.174a5.46676,5.46676,0,0,0,1.42176-3.84874l-6.48928-.5483a7.1723,7.1723,0,0,0,4.28575-2.25954C802.7981,84.73052,782.31323,76.29477,778.1,76.14416Z"
                      transform="translate(-152 -76.01429)"
                      fill="#2f2e41"
                    />
                    <path
                      d="M776.215,189.098s-17.36929-17.02085-23.62023-15.97822S737.80923,189.098,737.80923,189.098s-51.20772,17.06924-49.07407,34.13848S714.339,323.51826,714.339,323.51826s19.2029,100.28179,2.13366,110.95006,81.07889,38.40579,83.21254,25.60386,6.401-140.82123,0-160.02412S776.215,189.098,776.215,189.098Z"
                      transform="translate(-152 -76.01429)"
                      fill="#3f3d56"
                    />
                    <path
                      d="M850.89294,223.23648h26.38265S895.6997,304.31537,897.83335,312.85s6.401,49.07406,4.26731,49.07406-44.80675-8.53462-44.80675-2.13365Z"
                      transform="translate(-152 -76.01429)"
                      fill="#3f3d56"
                    />
                    <path
                      d="M850,424.01429H749c-9.85608-45.34-10.67957-89.14649,0-131H850C833.70081,334.115,832.68225,377.62137,850,424.01429Z"
                      transform="translate(-152 -76.01429)"
                      fill="#f2f2f2"
                    />
                    <path
                      d="M707.93806,368.325,737.80923,381.127s57.60868,8.53462,57.60868-14.93559-57.60868-10.66827-57.60868-10.66827L718.60505,349.383Z"
                      transform="translate(-152 -76.01429)"
                      fill="#a0616a"
                    />
                    <path
                      d="M714.339,210.43455l-25.60386,6.401L669.53227,329.91923s-6.401,29.87117,4.26731,32.00482S714.339,381.127,714.339,381.127s4.26731-32.00483,12.80193-32.00483L705.8044,332.05288,718.60633,257.375Z"
                      transform="translate(-152 -76.01429)"
                      fill="#3f3d56"
                    />
                    <rect
                      x="60.2485"
                      y="352.90779"
                      width="140"
                      height="2"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="98.2485"
                      y="334.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="159.2485"
                      y="334.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="109.2485"
                      y="56.90779"
                      width="140"
                      height="2"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="209.2485"
                      y="58.40779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="148.2485"
                      y="58.40779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="250.2485"
                      y="253.90779"
                      width="140"
                      height="2"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="350.2485"
                      y="255.40779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="289.2485"
                      y="255.40779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="12.2485"
                      y="252.90779"
                      width="140"
                      height="2"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="112.2485"
                      y="254.40779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="51.2485"
                      y="254.40779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="180.2485"
                      y="152.90779"
                      width="140"
                      height="2"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="218.2485"
                      y="134.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                    <rect
                      x="279.2485"
                      y="134.90779"
                      width="2"
                      height="18.5"
                      fill="#f2f2f2"
                    />
                  </svg>
                  <p className="empty-cart-msg">Your cart is empty!</p>
                </div>
              </div>
            </div>
          )}
        </Offcanvas.Body>
        {cartData.length != 0 ? (
          <div className="wrapper wrapperSidedrawFixBottmidth">
            <div className="wrapper2-flex">
              <div className="but-com">
                <Button
                  variant="light"
                  className="but-1 butn2checkout sidedrawtotalFixBottombutn2 text-start ps-2"
                  style={{ borderRadius: "0px", cursor: "auto" }}
                >
                  Total : &#x20b9; {parseFloat(totalAmount).toFixed(2)}
                  <span></span>
                </Button>
              </div>
              <div className="but-com">
                <button
                  className="butn2 butn2checkout sidedrawFixBottombutn2"
                  style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                  onClick={() => {
                    var login = localStorage.getItem("CustomerData");
                    var logindetails = JSON.parse(login);
                    if (logindetails) {
                      window.location.href = "/checkout";
                    } else {
                      localStorage.setItem("currentPage", "Cart");

                      window.location.href = "/login";
                    }
                  }}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="wrapper">
            <div className="">
              <div className="">
                <button
                  className="butn"
                  style={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    window.location.href = "/";
                  }}
                >
                  Continue Shopping
                </button>
              </div>
            </div>
          </div>
        )}
      </Offcanvas>
    </>
  );
};

export default Adcart;
