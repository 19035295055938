import { Component } from "react";
import "./Cart.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { store } from "../../context/store";
import Notiflix from "notiflix";
import PostApiCall from "../../Helper/Api";
import moment from "moment";
import { isMobile } from "react-device-detect";
import InnerBanner from "../InnerBanner/Innerbanner";

class Cart extends Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: "",
      ProductCart: [],
      CouponCard: false,
      open: true,
      SearchText: "",
      OfferData: [],
      loginData: [],
      CouponCode: null,
      cartID: null,
      CouponId: null,
      OfferErrorCode: "",
      OfferErrorMessage: "",
      AppliedCoupon: null,
      ShowCouponMoreInformation: false,
      EnteredCouponCode: null,
      CouponErrorCodeTextBox: null,
      Loading: true,
    };
  }

  componentDidMount() {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });

    const store = this.context;

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    if (logindetails != null) {
      this.setState({
        loginData: logindetails,
      });
    }

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          this.setState({
            Loading: false,
          });
          this.context.setCart(res1.data.length);

          if (res1.data.length != 0) {
            this.setState({
              ProductCart: res1.data,
              cartID: res1.data[0].fld_cart_id,
              CouponId: res1.data[0].fld_offerid,
              CouponCode: res1.data[0].fld_code,
            });
          } else {
            this.setState({
              ProductCart: res1.data,
            });
          }
        }
      });
  }
  getCartdetails = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          this.setState({ ProductCart: res1.data });
          this.context.setCart(res1.data.length);
        }
      });
  };
  render() {
    return (
      <>
        <InnerBanner />
        {/* <Header /> */}
        {this.state.Loading == true ? (
          <div class="d-flex justify-content-center m-5">
            <div
              class="spinner-border"
              role="status"
              style={{ color: "#c83363", width: "3rem", height: "3rem" }}
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            {this.state.ProductCart.length != 0 ? (
              <div class="cart-page-container">
                {/* <Container> */}
                <Breadcrumb className="breadcrumb-content">
                  <Breadcrumb.Item>
                    <Link to="/">Home </Link>
                  </Breadcrumb.Item>

                  <Breadcrumb.Item>
                    <Link to="/cart">Cart </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
                {/* </Container> */}
                {/* <div class="carthead">
                  <h1 class="heading headingCartPage text-center">Your cart</h1>
                  <a href="/products/allproducts" class="underlined-link">
                    Continue Shopping
                  </a>
                </div> */}
                <div id="main-cart-items">
                  <div class="js-contents">
                    <table class="cart-items">
                      <thead>
                        <tr>
                          <th
                            class="caption-with-letter-spacing"
                            colspan="2"
                            scope="col"
                          >
                            Product
                          </th>
                          <th
                            class="medium-hide large-up-hide right caption-with-letter-spacing"
                            colspan="1"
                            scope="col"
                          >
                            Total
                          </th>
                          <th
                            class="cart-items__heading--wide small-hide caption-with-letter-spacing"
                            colspan="1"
                            scope="col"
                          >
                            Quantity
                          </th>
                          <th
                            class="small-hide right caption-with-letter-spacing"
                            colspan="1"
                            scope="col"
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ProductCart.map((data, index) => {
                          return (
                            <tr class="cart-item" id="CartItem-1">
                              <td class="cart-item__media">
                                <a
                                  href={`${
                                    process.env.PUBLIC_URL
                                  }/productdetail/${
                                    data.fld_variantname == null
                                      ? ""
                                      : data.fld_variantname.replace(/\s/g, "-")
                                  }/${data.fld_variantid}`}
                                >
                                  <img
                                    class="cart-item__image img-fluid mx-auto"
                                    src={data.VariantImage}
                                    alt=""
                                    loading="lazy"
                                    width="150"
                                    height="150"
                                  />
                                </a>
                              </td>

                              <td class="cart-item__details">
                                <a
                                  href="/products/skylofts-rabbit-led-night-lamp-plug?variant=42449025007855"
                                  class="cart-item__name h4 break"
                                >
                                  {data.fld_variantname}
                                </a>
                                <div class="cart-item__discounted-prices">
                                  <span class="visually-hidden">
                                    Sale price
                                  </span>
                                  <strong class="cart-item__final-price product-option me-2">
                                    ₹{" "}
                                    {parseFloat(
                                      data.fld_website_discount_price
                                    ).toFixed(2)}
                                  </strong>
                                  <span class="visually-hidden">
                                    Regular price
                                  </span>
                                  <s class="cart-item__old-price product-option">
                                    ₹ {parseFloat(data.fld_mrp).toFixed(2)}
                                  </s>
                                </div>
                                <dl></dl>
                              </td>

                              <td class="cart-item__totals right medium-hide large-up-hide">
                                <div class="cart-item__price-wrapper">
                                  <dl class="cart-item__discounted-prices">
                                    <dt class="visually-hidden">Sale price</dt>
                                    <dd class="price price--end">
                                      ₹{" "}
                                      {parseFloat(
                                        data.fld_website_discount_price
                                      ).toFixed(2)}
                                    </dd>
                                    <dt class="visually-hidden">
                                      Regular price
                                    </dt>
                                    <dd>
                                      <s class="cart-item__old-price price price--end">
                                        ₹ {parseFloat(data.fld_mrp).toFixed(2)}
                                      </s>
                                    </dd>
                                  </dl>
                                </div>
                              </td>

                              <td class="cart-item__quantity">
                                <div class="cart-item__quantity-wrapper">
                                  <label
                                    class="visually-hidden"
                                    for="Quantity-1"
                                  >
                                    Quantity
                                  </label>
                                  <quantity-input class="quantity">
                                    <button
                                      class="quantity__button no-js-hidden listing-page"
                                      name="minus"
                                      type="button"
                                      onClick={() => {
                                        var login =
                                          localStorage.getItem("CustomerData");
                                        var logindetails = JSON.parse(login);

                                        var Order =
                                          localStorage.getItem("OrderData");
                                        var Orderdetails = JSON.parse(Order);

                                        if (data.fld_quantity > 1) {
                                          PostApiCall.postRequest(
                                            {
                                              orderdate: moment().format("lll"),
                                              itemdetails: `
                       [
                         { 
                           "Item":${data.fld_variantid}, 
                           "Quantity":${-1}
                         }
                       ]
                       `,
                                              status: "INCART",
                                              customerid:
                                                localStorage.getItem(
                                                  "CustomerData"
                                                ) == null
                                                  ? null
                                                  : logindetails[0]
                                                      .fld_customerid,
                                              createdon: moment().format("lll"),
                                              updatedon: moment().format("lll"),
                                              orderid:
                                                localStorage.getItem(
                                                  "OrderData"
                                                ) == null
                                                  ? 0
                                                  : Orderdetails[0].order_id,

                                              updatedby:
                                                localStorage.getItem(
                                                  "CustomerData"
                                                ) == null
                                                  ? null
                                                  : logindetails[0].fld_userid,
                                            },
                                            "AddToCartWebsite"
                                          ).then((result) =>
                                            result.json().then((obj3) => {
                                              // console.log(obj3.data);

                                              if (
                                                result.status == 200 ||
                                                result.status == 201
                                              ) {
                                                Notiflix.Notify.Success(
                                                  "Product quantity updated."
                                                );
                                                this.getCartdetails();
                                                if (
                                                  localStorage.getItem(
                                                    "OrderData"
                                                  ) == null
                                                ) {
                                                  // console.log('datastore')
                                                  localStorage.setItem(
                                                    "OrderData",
                                                    JSON.stringify(obj3.data)
                                                  );
                                                }
                                                this.getCartdetails();
                                              } else {
                                                Notiflix.Notify.Failure(
                                                  obj3.data
                                                );
                                              }
                                            })
                                          );
                                        }
                                      }}
                                    >
                                      <span class="visually-hidden">
                                        Decrease quantity for Skylofts Rabbit
                                        LED Night Lamp Plug
                                      </span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        focusable="false"
                                        role="presentation"
                                        class="icon icon-minus"
                                        fill="none"
                                        viewBox="0 0 10 2"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M.5 1C.5.7.7.5 1 .5h8a.5.5 0 110 1H1A.5.5 0 01.5 1z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </button>
                                    <input
                                      class="quantity__input"
                                      type="number"
                                      value={data.fld_quantity}
                                      min="0"
                                      id="Quantity-1"
                                      data-index="1"
                                    />
                                    <button
                                      class="quantity__button no-js-hidden listing-page"
                                      name="plus"
                                      type="button"
                                      onClick={() => {
                                        var login =
                                          localStorage.getItem("CustomerData");
                                        var logindetails = JSON.parse(login);

                                        var Order =
                                          localStorage.getItem("OrderData");
                                        var Orderdetails = JSON.parse(Order);
                                        PostApiCall.postRequest(
                                          {
                                            orderdate: moment().format("lll"),
                                            itemdetails: `
                                         [
                                           { 
                                             "Item":${data.fld_variantid}, 
                                             "Quantity":${+1}
                                           }
                                         ]
                                         `,
                                            status: "INCART",
                                            customerid:
                                              localStorage.getItem(
                                                "CustomerData"
                                              ) == null
                                                ? null
                                                : logindetails[0]
                                                    .fld_customerid,
                                            createdon: moment().format("lll"),
                                            updatedon: moment().format("lll"),
                                            orderid:
                                              localStorage.getItem(
                                                "OrderData"
                                              ) == null
                                                ? 0
                                                : Orderdetails[0].order_id,

                                            updatedby:
                                              localStorage.getItem(
                                                "CustomerData"
                                              ) == null
                                                ? null
                                                : logindetails[0].fld_userid,
                                          },
                                          "AddToCartWebsite"
                                        ).then((result) =>
                                          result.json().then((obj3) => {
                                            if (
                                              result.status == 200 ||
                                              result.status == 201
                                            ) {
                                              Notiflix.Notify.Success(
                                                "Product quantity updated."
                                              );
                                              this.getCartdetails();

                                              if (
                                                localStorage.getItem(
                                                  "OrderData"
                                                ) == null
                                              ) {
                                                // console.log('datastore')
                                                localStorage.setItem(
                                                  "OrderData",
                                                  JSON.stringify(obj3.data)
                                                );
                                              }
                                              this.getCartdetails();
                                            } else {
                                              Notiflix.Notify.Failure(
                                                obj3.data
                                              );
                                            }
                                          })
                                        );
                                      }}
                                    >
                                      <span class="visually-hidden">
                                        Increase quantity for Skylofts Rabbit
                                        LED Night Lamp Plug
                                      </span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        focusable="false"
                                        role="presentation"
                                        class="icon icon-plus"
                                        fill="none"
                                        viewBox="0 0 10 10"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </button>
                                  </quantity-input>

                                  <cart-remove-button
                                    id="Remove-1"
                                    data-index="1"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        var login =
                                          localStorage.getItem("CustomerData");
                                        var logindetails = JSON.parse(login);

                                        PostApiCall.postRequest(
                                          {
                                            cartID: data.fld_cart_id,
                                            customerid:
                                              localStorage.getItem(
                                                "CustomerData"
                                              ) == null
                                                ? null
                                                : logindetails[0]
                                                    .fld_customerid,
                                            cartlineid: data.fld_cart_dtl_id,
                                            stateid: null,
                                          },
                                          "DeleteCart"
                                        ).then((result) =>
                                          result.json().then((obj3) => {
                                            if (
                                              result.status == 200 ||
                                              result.status == 201
                                            ) {
                                              Notiflix.Notify.Success(
                                                "Product is successfully deleted."
                                              );
                                              this.getCartdetails();
                                              if (
                                                this.state.ProductCart.length ==
                                                0
                                              ) {
                                                localStorage.removeItem(
                                                  "OrderData"
                                                );
                                              }
                                              this.getCartdetails();
                                            } else {
                                              Notiflix.Notify.Failure(
                                                obj3.data
                                              );
                                            }
                                          })
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16"
                                        aria-hidden="true"
                                        focusable="false"
                                        role="presentation"
                                        class="icon icon-remove"
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                        }}
                                      >
                                        <path
                                          d="M14 3h-3.53a3.07 3.07 0 00-.6-1.65C9.44.82 8.8.5 8 .5s-1.44.32-1.87.85A3.06 3.06 0 005.53 3H2a.5.5 0 000 1h1.25v10c0 .28.22.5.5.5h8.5a.5.5 0 00.5-.5V4H14a.5.5 0 000-1zM6.91 1.98c.23-.29.58-.48 1.09-.48s.85.19 1.09.48c.2.24.3.6.36 1.02h-2.9c.05-.42.17-.78.36-1.02zm4.84 11.52h-7.5V4h7.5v9.5z"
                                          fill="currentColor"
                                        ></path>
                                        <path
                                          d="M6.55 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5zM9.45 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </a>
                                  </cart-remove-button>
                                </div>
                              </td>

                              <td class="cart-item__totals right small-hide">
                                <div class="cart-item__price-wrapper">
                                  <dl class="cart-item__discounted-prices">
                                    <dt class="visually-hidden">Sale price</dt>
                                    <dd class="price price--end">
                                      ₹{" "}
                                      {parseFloat(
                                        data.fld_website_discount_price *
                                          data.fld_quantity
                                      ).toFixed(2)}
                                    </dd>
                                    <dt class="visually-hidden">
                                      Regular price
                                    </dt>
                                    <dd>
                                      <s class="cart-item__old-price price price--end">
                                        ₹{" "}
                                        {parseFloat(
                                          data.fld_mrp * data.fld_quantity
                                        ).toFixed(2)}
                                      </s>
                                    </dd>
                                  </dl>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  class="cart-page-container cart-footer"
                  id="main-cart-footer"
                >
                  <div class="cart__footer">
                    <div class="cart__blocks">
                      {this.state.ProductCart.map((data, index) => {
                        if (index == 0) {
                          return (
                            <div class="js-contents">
                              <div class="totals">
                                <h3 class="totals__subtotal">Subtotal:</h3>
                                <p class="totals__subtotal-value">
                                  ₹{" "}
                                  {parseFloat(
                                    data.fld_cart_amount_no_shipping
                                  ).toFixed(2)}
                                </p>
                              </div>

                              <div></div>

                              <small class="tax-note caption-large rte">
                                Tax included and shipping calculated at checkout
                              </small>
                            </div>
                          );
                        }
                      })}

                      <div class="cart__ctas">
                        <div className="butn2" style={{ width: "50%" }}>
                          <a
                            onClick={() => {
                              window.location.href = "/products/allproducts";
                            }}
                            class="underlined-link"
                          >
                            Continue Shopping
                          </a>
                        </div>
                        <div className="butn2" style={{ width: "50%" }}>
                          <button
                            onClick={() => {
                              var login = localStorage.getItem("CustomerData");
                              var logindetails = JSON.parse(login);
                              if (logindetails) {
                                window.location.href = "/checkout";
                              } else {
                                localStorage.setItem("currentPage", "Cart");

                                window.location.href = "/login";
                              }
                            }}
                          >
                            Checkout
                          </button>
                        </div>
                        {/* <button
                    type="submit"
                    id="checkout"
                    class="cart__checkout-button button"
                    name="checkout"
                    form="cart"
                  ></button> */}
                      </div>

                      <div id="cart-errors"></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row cart-page-container">
                <div className="col-12 ml-5 mt-5">
                  <h5
                    style={{
                      fontSize: isMobile && "20px",
                    }}
                  >
                    Your Pink Parrot Kids cart is empty!
                  </h5>
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2">
                      <button
                        className="butn mt-5"
                        style={{ marginBottom: "100px" }}
                        onClick={() => {
                          if (this.state.ProductCart.length == 0) {
                            localStorage.removeItem("OrderData");
                            window.location.href = "/";
                          } else {
                            window.location.href = "/";
                          }
                        }}
                      >
                        {" "}
                        Continue Shopping
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default Cart;
