import React, { Component } from "react";
import "./Testimonial.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonalImage1 from "../../../Images/TestimonialsImages/1.webp";
import TestimonalImage2 from "../../../Images/TestimonialsImages/2.webp";
import TestimonalImage3 from "../../../Images/TestimonialsImages/3.webp";
import TestimonalImage4 from "../../../Images/TestimonialsImages/4.webp";
import TestimonalImage5 from "../../../Images/TestimonialsImages/5.webp";
import TestimonalImage6 from "../../../Images/TestimonialsImages/6.webp";
import TestimonalImage7 from "../../../Images/TestimonialsImages/7.webp";
import TestimonalImage8 from "../../../Images/TestimonialsImages/8.webp";
import TestimonalImage9 from "../../../Images/TestimonialsImages/9.webp";

class Testimonial extends Component {
  render() {
    var settings = {
      infinite: true,
      dots: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,

      // add by laxmi----------------------------------------------------------
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 995,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 630,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // end----------------------------------------------------------
      ],
    };
    return (
      <div class="container-fluid">
        <div className="content-head textimonialpaddingallview">
          <h2 style={{ color: "#fff" }} class="text-center">
            Testimonials
          </h2>

          <div class="slider">
            <div class="owl-carousel testimonialslickdotparent">
              <Slider {...settings} className='testimonialslickdotchild'>
                <div class="slider-card py-4 px-4 mb-4 testimonial-para">
                  <div class="d-flex justify-content-center align-items-center py-4">
                    <img
                      src={TestimonalImage1}
                      style={{ borderRadius: "50%", width: "50%" }}
                    />
                  </div>
                  <p>
                    <h5>Hi Neha,</h5>
                    Congratulations on your great initiative called Pink Parrot
                    Kids. And God Bless You. Kids are lovely and you know that.
                    Keep doing what you are doing. All the best for your future
                    !!
                  </p>
                  <p class="testi">- Ayushman Khurana, Actor</p>
                </div>
                <div class="slider-card py-4 px-4 testimonial-para">
                  <div class="d-flex justify-content-center align-items-center py-4">
                    <img
                      src={TestimonalImage9}
                      style={{ borderRadius: "50%", width: "50%" }}
                    />
                  </div>
                  <p>
                    <h5>Hello Neha,</h5>
                    Congratulations on Pink Parrot Kids, your hardwork and
                    dedication are commendable. Please keep doing it the same
                    way for these kids. God bless you. Best of luck!!
                  </p>
                  <p class="testi">- Pavitra Punia, Actress</p>
                </div>
                <div class="slider-card py-4 px-4 testimonial-para">
                  <div class="d-flex justify-content-center align-items-center py-4">
                    <img
                      src={TestimonalImage3}
                      style={{ borderRadius: "50%", width: "50%" }}
                    />
                  </div>
                  <p>
                    <h5>Hey Neha,</h5>I want to congratulate you on Pink Parrot
                    Kids. This is a fantastic initiative for children. I’m so
                    glad that you are doing this for them. Wish you all the best
                    for this great initiative.
                  </p>
                  <p class="testi">- Tiger Shroff, Actor</p>
                </div>
                <div class="slider-card py-4 px-4 testimonial-para">
                  <div class="d-flex justify-content-center align-items-center py-4">
                    <img
                      src={TestimonalImage2}
                      style={{ borderRadius: "50%", width: "50%" }}
                    />
                  </div>
                  <p>
                    <h5>Hi Neha,</h5>
                    What’s up? How’s it going? Just want to congratulate you and
                    your team for Pink Parrot Kids, it’s a lovely thing you are
                    doing for the kids. Best of luck !!{" "}
                  </p>
                  <p class="testi">- Varun Dhawan, Actor</p>
                </div>
                <div class="slider-card py-4 px-4 testimonial-para">
                  <div class="d-flex justify-content-center align-items-center py-4">
                    <img
                      src={TestimonalImage4}
                      style={{ borderRadius: "50%", width: "50%" }}
                    />
                  </div>
                  <p>
                    <h5>Hello Neha,</h5>
                    I’m Daler Mehendi. I’m glad that you are doing wonders for
                    Pink Parrot Kids. best wishes from my side, and I’ll be more
                    than glad to be of any help for your initiative. God Bless
                    you.{" "}
                  </p>
                  <p class="testi">- Daler Mehndi, Singer</p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonial;
