import Pinkparrot from "./Component/Pinkparrot/Pinkparrot";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css"

function App() {
  return (
    <>
      <Pinkparrot />
    </>
  );
}

export default App;
