import topcategory from "../../../Images/category.webp";
import "../Category/Category.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Component, useEffect, useState } from "react";
import PostApiCall from "../../../Helper/Api";
import GetApiCall from "../../../Helper/GetApi";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../Content/Skeleton.css";
import styled from "styled-components";

const GalleryStyles = styled.div`
  .gallery__grid {
    display: grid;
    gap: 3rem;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
    justify-content: center;
    padding: 0 3% 0 3%;
  }
  .gallery__title {
    font-size: 2rem;
    padding: 3%;
    text-align: center;
  }
  .item {
    margin: auto;
    border: 3px solid var(--gray-1);
  }
  .item__btns {
    display: flex;
    justify-content: space-between;
    button {
      font-size: 1.125rem;
      background-color: var(--gray-1);
      padding: 0.2rem 0.5rem;
      height: 3rem;
      border-radius: 8px;
      font-weight: bolder;
    }
  }
  .item-img {
    width: 304px;
    height: 140px;
    margin: auto;
    margin-bottom: 1rem;
    img {
      object-fit: contain;
    }
  }
  .item-title {
    font-size: 1rem;
    height: 82px;
    text-align: center;
    margin-bottom: 1rem;
  }
  .item-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .item-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    width: 60px;
  }
  .item__btnadd {
    border: 2px solid var(--red-1);
    color: var(--red-1);
  }
  .item-price {
    font-size: 2.5rem;
    color: var(--blue-1);
    text-align: center;
    margin-bottom: 1rem;
  }
  .item__btnbuy {
    border: 2px solid var(--red-1);
    background-color: var(--red-1) !important;
    color: var(--gray-1);
  }
  .item-start {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid yellow;
    svg {
      font-size: 1rem;
    }
  }
  .skeleton {
    margin-bottom: 1rem;
  }
`;

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: Array.from({ length: 0 }),
      loading: true,
    };
  }

  componentDidMount() {
    GetApiCall.getRequest("Get_Category").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            category: obj1.data,
            loading: false,
          });
        }
      })
    );
  }
  render() {
    var settings = {
      infinite: true,
      dots: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      arrows: false,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 995,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const { featuredProducts } = this.state;
    const rowSkeletons = 4;
    if (this.state.loading) {
      let rows = [];
      for (let index = 0; index < rowSkeletons; index++) {
        rows.push(
          <section>
            <article className="item">
              <div className="item-img">
                <Skeleton height={140} />
              </div>
              <h3 className="item-title">
                <Skeleton count={1} />
              </h3>

              <Skeleton height={48} count={1} className="skeleton" />
            </article>
          </section>
        );
      }

      return (
        <>
          <SkeletonTheme color="#F5F5F5" highlightColor="#ffffff">
            <GalleryStyles className="gallery__grid">
              <h2 className="gallery__title">
                <Skeleton />
              </h2>
              <div className="gallery__grid">{rows}</div>
            </GalleryStyles>
          </SkeletonTheme>
        </>
      );
    }

    return (
      <>
        <div className="che" style={{ padding: "40px 3%" }}>
          <h2 className="category-h2">Product Categories</h2>

          <Slider {...settings}>
            {this.state.category.map((data, index) => {
              return (
                <a
                  class="link-cat"
                  href={`${
                    process.env.PUBLIC_URL
                  }/${data.fld_categoryname.trim()}/${data.fld_categoryid}`}
                >
                  {/* <div key={index} style={{marginRight:"10px"}}> */}
                  {/* change by laxmi */}
                  <div key={index} className="categoryDisplay">
                    <img alt="category" src={data.fld_image} />
                    {/* <h4
                      style={{
                        textAlign: "center",

                        fontSize: "1.2rem",
                        paddingTop: "0.5em",
                      }}
                    > */}

                    <h4 className="categorynameFont">
                      {data.fld_categoryname}
                    </h4>
                  </div>
                </a>
              );
            })}
          </Slider>
        </div>
      </>
    );
  }
}

export default Category;
