import React from "react";
import Notiflix from "notiflix";
import PostApiCall from "../../Helper/Api";
import { Link } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "./ForgotPassword.css";
import ForgotpasswordImage from "../../Images/forgotpassword.webp";

var otp = Math.floor(100000 + Math.random() * 900000);

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NumRegex: /^[0-9]*$/,
      isPasswordVisible: false,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      PasswordRegex:
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
      Email: "",
      open: true,
      EnteredOtp: null,
      ShowInputforOtp: false,
      openPassword: false,
      NewPassword: "",
      ConfirmPassword: "",
      Salt: [],
      isNewPasswordVisible: false,
      isConPasswordVisible: false,
      MobileNo: "",
    };
  }
  componentDidMount() {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });
  }

  sendConfirmation() {
    if (this.state.Email != "") {
      if (this.state.EmailRegex.test(this.state.Email)) {
        this.onPostSalt();
      } else {
        Notiflix.Notify.Failure("Please enter valid Email Address.");
      }
    } else {
      Notiflix.Notify.Failure("Please enter your Email.");
    }
  }

  onPostSalt() {
    //////console.log(this.state.Email)
    PostApiCall.postRequest(
      {
        email: this.state.Email,
      },
      "Get_SaltByEmailId_ForgotPassword"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data != null) {
            this.setState(
              {
                Salt: obj.data,
              },
              () => {
                this.onConfirmation();
              }
            );
          }
        } else {
          Notiflix.Notify.Failure("Email Id is not registered with us");
        }
      })
    );
  }

  onConfirmation = () => {
    // //console.log(otp)
    // //console.log(this.state.Email)

    // Notiflix.Loading.Dots('Please wait...');
    PostApiCall.postRequest(
      {
        OTP: otp,
        email: this.state.Email,
      },
      "Mailer_ForgotPassword"
    ).then((result) => {
      //   Notiflix.Loading.Remove();
      Notiflix.Notify.Success(
        "Your verification OTP is Successfully sent to your Email Id."
      );

      this.setState({
        open: false,
        ShowInputforOtp: true,
      });
    });
  };

  VerifyOtp() {
    if (this.state.EnteredOtp != null) {
      if (this.state.EnteredOtp == otp) {
        this.setState({
          openPassword: true,
          ShowInputforOtp: false,
        });
      } else {
        Notiflix.Notify.Failure("Incorrect OTP.");
      }
    } else {
      Notiflix.Notify.Failure("Please enter OTP.");
    }
  }

  SavePassword() {
    if (this.state.NewPassword != "") {
      if (this.state.PasswordRegex.test(this.state.NewPassword)) {
        if (this.state.ConfirmPassword != "") {
          if (this.state.ConfirmPassword == this.state.NewPassword) {
            this.onPostPassword();
          } else {
            Notiflix.Notify.Failure(
              "New Password and Confirm Password do not match "
            );
          }
        } else {
          Notiflix.Notify.Failure("Please enter confirm password.");
        }
      } else {
        Notiflix.Notify.Failure(
          "Password must be at least 6 characters  & contains a number and a letter"
        );
      }
    } else {
      Notiflix.Notify.Failure("Please enter new password.");
    }
  }

  onPostPassword = () => {
    // Notiflix.Loading.Dots('Please wait...');

    PostApiCall.postRequest(
      {
        email: this.state.Email,
        password: this.state.ConfirmPassword,
        salt: this.state.Salt[0].fld_salt,
        updatedby: null,
      },
      "ResetPassword"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Notify.Success("Password Successfully changed");
          window.location.href = "/login";
        } else {
          // Notiflix.Loading.Remove();
          Notiflix.Notify.Failure("Something went wrong, try again later.");
        }
      })
    );
  };

  render() {
    return (
      <div>
        <div className="pd-3">
          <Breadcrumb className="breadcrumb-content">
            <Breadcrumb.Item>
              <Link to="/">Home </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to="/login">Forgot Password </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <section class="section-padding bg-grey mb-3">
          <div class="container-fluid">
            {/* <button type="button" class="goback-button" onClick={() => { this.props.history.goBack() }}><i class="fa fa-arrow-left" aria-hidden="true"></i>{" "}Go Back</button> */}

            <div class="row justify-content-center">
              {/* change by laxmi div size------------------------------------------------ */}
              <div class="col-md-8 col-lg-6 col-xl-8 mt-3">
                {/* <div className="col-md-8 mt-3 "> */}

                <div class="registration-box ">
                  <div class="text-center">
                    <h5 className="forgotpassHeading">
                      Forgot your Password? No Worries!
                    </h5>
                    <p className="forgotpassPehra">
                      We want to make sure it's really you. In order to verify
                      your identity tell us your email address to receive a
                      verification code.{" "}
                    </p>
                  </div>

                  <div class="row m-0">
                    {/* div size change by laxmi------------------------------------- */}
                    {/* <div class="col-md-6"> */}
                    <div class="col-12 col-md-12 col-lg-12 col-xl-6">
                      <img
                        src={ForgotpasswordImage}
                        width="100%"
                        alt="forgot_password"
                      />
                    </div>

                    {/* div size change by laxmi------------------------------------- */}
                    {/* <div class="mt-4 col-md-6"> */}
                    <div class="mt-4 col-12 col-md-12 col-lg-12 col-xl-6">
                      <p className="forgotpassHeading">Let's verify you!</p>

                      <div
                        class="row m-0 justify-content-between align-items-center mb-4"
                        style={{
                          display: this.state.open == true ? "flex" : "none",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Enter your Email Address"
                          class="form-control col-md-8 mb-3"
                          value={this.state.Email}
                          onChange={(email) => {
                            this.setState({ Email: email.target.value });
                          }}
                        ></input>

                        {/* change by laxmi col size-------------------------- */}

                        <a
                          class="butn col-md-3 col-sm-3 col-5"
                          onClick={this.sendConfirmation.bind(this)}
                        >
                          {/* <a
                          class="butn col-md-3 col-4"
                          onClick={this.sendConfirmation.bind(this)}
                        > */}
                          Get Code
                        </a>
                      </div>

                      <div
                        style={{
                          display:
                            this.state.ShowInputforOtp == true
                              ? "block"
                              : "none",
                        }}
                      >
                        <input
                          type={
                            this.state.isPasswordVisible ? "text" : "password"
                          }
                          placeholder="Enter OTP"
                          class="form-control"
                          value={this.state.EnteredOtp}
                          onChange={(otp) =>
                            this.setState({
                              EnteredOtp: otp.target.value,
                            })
                          }
                        ></input>
                        <span class="login-icon-change-pass">
                          {/* <i class="icon-dual" data-feather="lock"></i>*/}
                          <i
                            style={{
                              color: this.state.isPasswordVisible
                                ? ""
                                : "#777f80",
                            }}
                            className="fa fa-eye"
                            onClick={() => {
                              this.setState({
                                isPasswordVisible:
                                  !this.state.isPasswordVisible,
                              });
                            }}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display:
                            this.state.openPassword == false ? "none" : "block",
                        }}
                      >
                        <input
                          type={
                            this.state.isPasswordVisible ? "text" : "password"
                          }
                          placeholder="Enter new passowrd"
                          class="form-control mb-3"
                          value={this.state.NewPassword}
                          onChange={(password) =>
                            this.setState({
                              NewPassword: password.target.value,
                            })
                          }
                        ></input>
                        <span class="login-icon-change-pass">
                          {/* <i class="icon-dual" data-feather="lock"></i>*/}
                          <i
                            style={{
                              color: this.state.isPasswordVisible
                                ? ""
                                : "#777f80",
                            }}
                            className="fa fa-eye"
                            onClick={() => {
                              this.setState({
                                isPasswordVisible:
                                  !this.state.isPasswordVisible,
                              });
                            }}
                          />
                        </span>
                        <input
                          type={
                            this.state.isPasswordVisible ? "text" : "password"
                          }
                          placeholder="Confirm new password"
                          class="form-control mb-3"
                          value={this.state.ConfirmPassword}
                          onChange={(password) =>
                            this.setState({
                              ConfirmPassword: password.target.value,
                            })
                          }
                        ></input>
                        <span class="login-icon-change-pass">
                          {/* <i class="icon-dual" data-feather="lock"></i>*/}
                          <i
                            style={{
                              color: this.state.isPasswordVisible
                                ? ""
                                : "#777f80",
                            }}
                            className="fa fa-eye"
                            onClick={() => {
                              this.setState({
                                isPasswordVisible:
                                  !this.state.isPasswordVisible,
                              });
                            }}
                          />
                        </span>
                      </div>

                      {this.state.openPassword == false ? (
                        <div
                          class="row justify-content-between"
                          style={{
                            display: this.state.open == true ? "none" : "flex",
                          }}
                        >
                          <a
                            class="butn mt-3"
                            onClick={this.VerifyOtp.bind(this)}
                          >
                            Verify Code
                          </a>

                          <a
                            class="mt-3 float-right butn"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            style={{ cursor: "pointer" }}
                            onClick={this.sendConfirmation.bind(this)}
                          >
                            Resend Code
                          </a>
                        </div>
                      ) : (
                        <div
                          class="row justify-content-between align-items-center"
                          style={{
                            display: this.state.open == true ? "none" : "block",
                          }}
                        >
                          <a
                            class="butn "
                            onClick={this.SavePassword.bind(this)}
                          >
                            Save new password
                          </a>
                        </div>
                      )}

                      <div class="rgbox-bottom text-center mt-5">
                        <p>
                          <label>Start your Shopping</label>
                        </p>
                      </div>

                      <div class="row">
                        <div class="but col-md-12 d-flex justify-content-between">
                          <button
                            type="button"
                            class="butn signphnforgetpass"
                            onClick={() => {
                              window.location.href = "/register";
                            }}
                          >
                            Sign Up
                          </button>
                          <button
                            type="button"
                            class="butn loginphnforgetpass"
                            onClick={() => {
                              window.location.href = "/login";
                            }}
                          >
                            Login
                          </button>
                        </div>

                        {/* <div class="but col-md-5">
                          
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ForgotPassword;
