import { createContext, useState } from "react";

export const store = createContext()

const Provider = ({children}) =>{
    const [cart, setCart] = useState(0)

    return (
        <store.Provider value={{cart, setCart}}>
            {children}
        </store.Provider>
    )
}

export default Provider;