import Container from "react-bootstrap/Container";
import { AiOutlineUser } from "react-icons/ai";
import { MdDateRange } from "react-icons/md";
import Blogimg from "../../../Images/blog.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Content/content1.css";
import Card from "react-bootstrap/Card";
import "../../Home/Blog/Blog.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Blog() {
  var settings = {
    infinite: true,
    // dots: true,
    // autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="blog-main">
        <div>
          <div>
            <h2>latest blog</h2>
            <p>
              Interesting features is rich and colorful, each button to bring a
              surprise to the baby Introduction to give your child learn in
              advance.
            </p>
          </div>
          <div>
            <Slider {...settings}>
              <Row className="pad-row">
                <Col xs={12} md={6}>
                  <div className="blog-img">
                    <img alt="blog" src={Blogimg} />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="blog-h2p">
                    <h2 className="blog-h2">
                      Donec placerat, dolor ac tristique
                    </h2>
                    <p>
                      Phasellus ex est, vehicula et ante id, dapibus dictum
                      eros. Nunc molestie euismod pretium. Nunc feugiat arcu et
                      condimentum porttitor. In dapibus, sapien sed hendrerit
                      fringilla, odio quam dictum lacus, ut porta odio erat eget
                      augue. Nunc felis dolor, elementum eget risus nec,
                      faucibus dictum nulla. Aliquam erat volutpat. Maecenas...
                    </p>
                    <div className="blog-flex">
                      <div className="wbblogdate1">
                        <MdDateRange />
                        Feb 08, 2022
                      </div>
                      <div className="wbuserdate2">
                        {" "}
                        <AiOutlineUser />
                        Romit Gabani
                      </div>
                    </div>
                    <div className="but-margin-blog">
                      <div className="butn">Read More</div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="pad-row">
                <Col xs={12} md={6}>
                  <div className="blog-img">
                    <img alt="blog" src={Blogimg} />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="blog-h2p">
                    <h2 className="blog-h2">
                      Donec placerat, dolor ac tristique
                    </h2>
                    <p>
                      Phasellus ex est, vehicula et ante id, dapibus dictum
                      eros. Nunc molestie euismod pretium. Nunc feugiat arcu et
                      condimentum porttitor. Anupriya In dapibus, sapien sed
                      hendrerit fringilla, odio quam dictum lacus, ut porta odio
                      erat eget augue. Nunc felis dolor, elementum eget risus
                      nec, faucibus dictum nulla. Aliquam erat volutpat.
                      Maecenas...
                    </p>
                    <div className="blog-flex">
                      <div className="wbblogdate1">
                        <MdDateRange />
                        Feb 08, 2022
                      </div>
                      <div className="wbuserdate2">
                        {" "}
                        <AiOutlineUser />
                        Saumya Srivastava
                      </div>
                    </div>
                    <div className="but-margin-blog">
                      <div className="butn">Read More</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
