import Container from "react-bootstrap/Container";
import "../Footer/Footer.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import pplogo1 from "../../../Images/Logo.svg";
import { MdLocationOn, MdOutlinePhone, MdOutlineEmail } from "react-icons/md";
import { RiTwitterFill, RiFacebookFill } from "react-icons/ri";
import { BsPinterest } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import moment from "moment";
import { Link } from "react-router-dom";
import VisaImage from "../../../Images/visa.webp";
import ManageCustomerAPI from "../../ClinetDetailsApi/ManageCustomerAPI";
import { useState, useEffect } from "react";
import Slider from "react-slick";

function Footer() {
  var settings = {
    infinite: false,
    // dots: true,
    // autoplay: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        }
      }
    ],
  };
  const [clientName, setClientName] = useState([]);

  useEffect(() => {
    ManageCustomerAPI.postRequest(
      {
        whereClause: `where fld_company_id = ${3}`,
      },
      "API/GetClientData"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          //console.log(obj.data)
          // this.setState({
          //   clientLogo: obj.data[0].fld_storelogo,
          //   clientName: obj.data[0].fld_companyname,
          // });
          setClientName(obj.data[0].fld_companyname);

          //console.log(obj)
        }
      })
    );
  }, []);
  return (
    <>
      <div className="footer">
        {/* <div class="babystreetWaveWrapper waveAnimation">
          <div class="waveWrapperInner bgTop">
            <div class="wave waveTop">
            </div>
          </div>
          <div class="waveWrapperInner bgMiddle">
            <div class="wave waveMiddle">
            </div>
          </div>
          <div class="waveWrapperInner bgBottom">
            <div class="wave waveBottom">
            </div>
          </div>
        </div> */}
        <div className="footer-content">
          <Row>
            <Col md={5}>
              <div>

                <div className="footer-logo-wrap">
                  <img src={pplogo1} alt="ppl" width="100%" />
                </div>
              </div>

              {/* <p className="footer-col1p">
                The founder Neha Kannan was inspired by her 4 year old daughter
                Adhira Kannan to offer quality products to children across all
                ages.
              </p> */}
              <ul className="footer-col1-ul">
                <a
                  href="https://www.facebook.com/pinkparrotkids/"
                  target="_blank"
                >
                  <li>
                    <RiFacebookFill />
                  </li>
                </a>
                {/* <li>
                  <RiTwitterFill />
                </li>
                <li>
                  <BsPinterest />
                </li> */}
                <a href="https://www.instagram.com/pinkparrotkids/?igshid=f9jg2z9gtu6d">
                  <li>
                    <AiFillInstagram />
                  </li>
                </a>
              </ul>
            </Col>
            <Col md={3}>
              <ul className="foot-col2-UL">
                {/* <h3 className="foot-col2-h3">Service</h3> */}
                <a href="/about-us">
                  <li>About Us</li>
                </a>
                <a href="/termsandconditions">
                  <li>Terms & Conditions</li>
                </a>
                <a href="/privacy-policy">
                  <li>Privacy Policy</li>
                </a>
                <a href="/returns-refunds">
                  <li>Returns/Refund and Shipping Policy</li>
                </a>
                <a href="/faqs">
                  <li>FAQs</li>
                </a>
                <a href="/contact-us">
                  <li>Contact Us</li>
                </a>
              </ul>
            </Col>
            {/* <Col md={2}>
              <ul className="foot-col2-UL">
                <h3 className="foot-col2-h3">Extras</h3>
                <li>Search</li>
                <li>News</li>
                <li>Whishlist</li>
                <li>All Product</li>
              </ul>
            </Col> */}
            <Col
              md={4}
              style={{ paddingLeft: "60px" }}
              className="footerPaddingPhnview"
            >
              <ul className="foot-col2-UL">
                {/* <h3 className="foot-col2-h3">Contact Us</h3> */}
                {/* <h3 className="foot-col2-h3">Contact Details</h3> */}
                <li className="addressphnview">
                  <MdLocationOn
                    className="locationicon"
                    style={{ fontSize: "1.4em" }}
                  />
                  <span style={{ paddingLeft: "0.5em" }}>
                    Royal Classic New Link Road, {"\n"}
                  </span>
                </li>
                <li style={{ paddingLeft: "30px" }}>Andheri West,</li>
                <li style={{ paddingLeft: "30px" }}>Mumbai- 400053, India.</li>
                <a href="tel:+9199678 55001 ">
                  <li>
                    <MdOutlinePhone style={{ fontSize: "1.4em" }} />
                    <span style={{ paddingLeft: "0.5em" }}>
                      +91 99678 55001{" "}
                    </span>
                  </li>
                </a>
                <a href="mailto: neha@pinkparrotkids.com">
                  <li>
                    <MdOutlineEmail style={{ fontSize: "1.4em" }} />
                    <span style={{ paddingLeft: "0.5em" }}>
                      neha@pinkparrotkids.com
                    </span>
                  </li>
                </a>
              </ul>
            </Col>
          </Row>
          <div className="footer-con2">
            <p className="we-accept">We accept</p>
            {/* <Slider {...settings}>

              <div>
                <img
                  src={VisaImage}
                  className="paymentImgPhnview"
                  height="42px"
                />
              </div>
              <div>
                <svg
                  class="icon icon--full-color paymentImgPhnview"
                  viewBox="0 0 38 24"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-labelledby="pi-master"
                >
                  <title id="pi-master">Mastercard</title>
                  <path opacity=".07" />
                  <path
                    fill="#fafafa"
                    d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
                  />
                  <circle fill="#EB001B" cx="15" cy="12" r="7" />
                  <circle fill="#F79E1B" cx="23" cy="12" r="7" />
                  <path
                    fill="#FF5F00"
                    d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  class="icon icon--full-color paymentImgPhnview"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 38 24"
                  aria-labelledby="pi-american_express"
                >
                  <title id="pi-american_express">American Express</title>
                  <g fill="none">
                    <path fill="#000" opacity=".07" />
                    <path
                      fill="#006FCF"
                      d="M35,1 C36.1,1 37,1.9 37,3 L37,21 C37,22.1 36.1,23 35,23 L3,23 C1.9,23 1,22.1 1,21 L1,3 C1,1.9 1.9,1 3,1 L35,1"
                    />
                    <path
                      fill="#fafafa"
                      d="M8.971,10.268 L9.745,12.144 L8.203,12.144 L8.971,10.268 Z M25.046,10.346 L22.069,10.346 L22.069,11.173 L24.998,11.173 L24.998,12.412 L22.075,12.412 L22.075,13.334 L25.052,13.334 L25.052,14.073 L27.129,11.828 L25.052,9.488 L25.046,10.346 L25.046,10.346 Z M10.983,8.006 L14.978,8.006 L15.865,9.941 L16.687,8 L27.057,8 L28.135,9.19 L29.25,8 L34.013,8 L30.494,11.852 L33.977,15.68 L29.143,15.68 L28.065,14.49 L26.94,15.68 L10.03,15.68 L9.536,14.49 L8.406,14.49 L7.911,15.68 L4,15.68 L7.286,8 L10.716,8 L10.983,8.006 Z M19.646,9.084 L17.407,9.084 L15.907,12.62 L14.282,9.084 L12.06,9.084 L12.06,13.894 L10,9.084 L8.007,9.084 L5.625,14.596 L7.18,14.596 L7.674,13.406 L10.27,13.406 L10.764,14.596 L13.484,14.596 L13.484,10.661 L15.235,14.602 L16.425,14.602 L18.165,10.673 L18.165,14.603 L19.623,14.603 L19.647,9.083 L19.646,9.084 Z M28.986,11.852 L31.517,9.084 L29.695,9.084 L28.094,10.81 L26.546,9.084 L20.652,9.084 L20.652,14.602 L26.462,14.602 L28.076,12.864 L29.624,14.602 L31.499,14.602 L28.987,11.852 L28.986,11.852 Z"
                    />
                  </g>
                </svg>
              </div>
              <div>
                <svg
                  class="icon icon--full-color paymentImgPhnview"
                  viewBox="0 0 38 24"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-labelledby="pi-rupay"
                >
                  <title id="pi-rupay">RuPay</title>
                  <g fill="none" fill-rule="evenodd">
                    <rect
                      stroke-opacity=".07"
                      fill="#fafafa"
                      x=".5"
                      y=".5"
                      width="37"
                      height="23"
                      rx="3"
                    />
                    <path fill="#097A44" d="M32 15.77l2-7.41 2 3.82z" />
                    <path fill="#F46F20" d="M30.76 15.79l2-7.4 2 3.82z" />
                    <path
                      d="M20.67 8.2a2 2 0 0 0-1.56-.56h-3l-1.95 6.81h1.75l.66-2.31h1.23a3.4 3.4 0 0 0 1.9-.5 2.93 2.93 0 0 0 1.12-1.72 1.77 1.77 0 0 0-.15-1.72zm-3.21.94h1.12a.76.76 0 0 1 .55.15c.11.11.07.35 0 .53a1.08 1.08 0 0 1-.4.62 1.21 1.21 0 0 1-.7.2H17l.46-1.5zM9.14 9a1.64 1.64 0 0 0-.2-.61 1.3 1.3 0 0 0-.58-.53 2.75 2.75 0 0 0-1.08-.18H4l-2 6.75h1.73l.72-2.52H5.7c.47 0 .58.1.6.13.02.03.09.15 0 .65l-.16.6a3.35 3.35 0 0 0-.11.59v.55h1.79l.12-.43-.11-.08s-.07-.05-.06-.2c.027-.19.07-.377.13-.56l.1-.42a2.14 2.14 0 0 0 .1-1.11.88.88 0 0 0-.26-.41 2 2 0 0 0 .68-.54 2.79 2.79 0 0 0 .53-1c.07-.22.101-.45.09-.68zm-1.86.83a.84.84 0 0 1-.5.6 1.79 1.79 0 0 1-.64.09H4.86l.38-1.33h1.43a1.1 1.1 0 0 1 .53.09c.05 0 .21.07.08.5v.05zm4.9 2.17a2.11 2.11 0 0 1-.3.67 1 1 0 0 1-.87.43c-.34 0-.36-.14-.38-.2a1.24 1.24 0 0 1 .07-.52l.89-3.11H9.9l-.86 3a3 3 0 0 0-.15 1.32c.08.42.4.91 1.41.91.247.004.493-.03.73-.1a2.51 2.51 0 0 0 .6-.29l-.08.3h1.62l1.47-5.13H13L12.18 12zm12.93 1.1l.63-2.18c.24-.83-.07-1.21-.37-1.39A2.75 2.75 0 0 0 24 9.2a2.87 2.87 0 0 0-2 .68 2.75 2.75 0 0 0-.69 1.1l-.09.26h1.61v-.11a1.15 1.15 0 0 1 .25-.37.84.84 0 0 1 .56-.17.89.89 0 0 1 .46.08v.18c0 .06 0 .15-.25.23a2.13 2.13 0 0 1-.48.1l-.44.05a4 4 0 0 0-1.25.32c-.57.271-.99.78-1.15 1.39a1.25 1.25 0 0 0 .17 1.22c.289.307.7.468 1.12.44a2.43 2.43 0 0 0 1.07-.25l.4-.23v.33H25l.13-.48-.13-.07a.61.61 0 0 1 0-.22c0-.25.07-.43.11-.58zm-2.92-.1a.62.62 0 0 1 .34-.4 2.17 2.17 0 0 1 .57-.15l.29-.05.3-.07v.07a1.24 1.24 0 0 1-.51.75 1.44 1.44 0 0 1-.72.21.34.34 0 0 1-.25-.08.55.55 0 0 1-.02-.28zm7.91-3.68l-1.69 3v-3h-1.8l.39 5.13-.12.19a.8.8 0 0 1-.23.25.64.64 0 0 1-.24.08h-.68l-.39 1.37h.83a2 2 0 0 0 1.29-.34 9.55 9.55 0 0 0 1.27-1.71l3.17-5-1.8.03z"
                      fill="#302F82"
                    />
                  </g>
                </svg>
              </div>
              <div>
                <svg
                  class="icon icon--full-color paymentImgPhnview"
                  viewBox="0 0 38 24"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                  role="img"
                  aria-labelledby="pi-paytm"
                >
                  <title id="pi-paytm">Paytm</title>
                  <rect
                    x=".5"
                    y=".5"
                    width="37"
                    height="23"
                    rx="3"
                    ry="3"
                    fill="#fafafa"
                    stroke-opacity=".07"
                  />
                  <path
                    d="M14.17 13.32v2.6a.87.87 0 0 1-.74.91h-2.7a1.83 1.83 0 0 1-2-1.9 14.66 14.66 0 0 1 .06-2.08 1.81 1.81 0 0 1 1.69-1.54h1.19a.31.31 0 0 0 .34-.41.33.33 0 0 0-.23-.41H10c-.38 0-.46-.08-.46-.47V8.91a.3.3 0 0 1 .25-.35h2.39a1.87 1.87 0 0 1 1.92 2.1c.08.91.07 1.79.07 2.66zm-3.32 1.34a.34.34 0 0 0 .31.36h.61a.33.33 0 0 0 .36-.35v-1.13c0-.3-.16-.36-.72-.36s-.53.1-.56.37v1.11zm9.58-2.73v2.81a2 2 0 0 1-1.85 2.15h-2.45c-.34 0-.42-.07-.42-.42v-1.26a.3.3 0 0 1 .29-.35h2a.32.32 0 0 0 .36-.34.33.33 0 0 0-.31-.35h-1a1.94 1.94 0 0 1-2-1.86V9a.32.32 0 0 1 .26-.37h1.34c.34 0 .42.1.42.45v2.6c0 .45.1.54.55.54h.05c.62 0 .67-.05.67-.66V9a.36.36 0 0 1 .45-.5H20a.36.36 0 0 1 .42.42c.01 1.08.01 2.02.01 3.01zM4.57 14.48v1.94c0 .46-.06.51-.52.51H2.87a.3.3 0 0 1-.36-.36V9a.28.28 0 0 1 .22-.32H6.2a1.66 1.66 0 0 1 1.62 1.61 17.62 17.62 0 0 1 0 2.49 1.74 1.74 0 0 1-1.73 1.74H4.57zm0-2.08h.86a.32.32 0 0 0 .32-.31V11a.32.32 0 0 0-.28-.35h-.88v1.74z"
                    fill="#22346c"
                  />
                  <path
                    d="M28.94 9a2.2 2.2 0 0 1 2.86.1 7.28 7.28 0 0 1 1.15-.51 2.08 2.08 0 0 1 2.56 2v5.83c0 .36-.09.45-.45.45h-1.15a.35.35 0 0 1-.42-.42v-5.24a.6.6 0 0 0-.79-.64.55.55 0 0 0-.49.58v5.4a.31.31 0 0 1-.25.36h-1.43a.3.3 0 0 1-.35-.31v-5.43a.48.48 0 0 0-.29-.55 1.38 1.38 0 0 0-.71 0 .48.48 0 0 0-.26.53v5.21c0 .48-.06.55-.56.55h-1c-.36 0-.42-.08-.42-.44V9c0-.42.06-.47.46-.47h1.09a.42.42 0 0 1 .45.47zm-5.43 1.64h-.77a.33.33 0 0 1-.41-.4V9a.31.31 0 0 1 .25-.36h.1a2 2 0 0 0 1.74-1 2 2 0 0 1 .58-.57c.24-.16.42 0 .44.27v1.27h.7a.36.36 0 0 1 .42.42v1.22a.35.35 0 0 1-.42.42h-.66v5.83c0 .42-.07.48-.47.49h-1.09a.34.34 0 0 1-.42-.42c.01-1.87.01-5.78.01-5.92z"
                    fill="#24b8eb"
                  />
                </svg>
              </div>
              <div>
                <svg
                  class="icon icon--full-color paymentImgPhnview"
                  viewBox="0 0 38 24"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="38"
                  role="img"
                  aria-labelledby="pi-airtel_money"
                >
                  <title id="pi-airtel_money">Airtel Money</title>
                  <g fill-rule="evenodd" fill="none">
                    <path d="M34.5 24h-31C1.6 24 0 22.4 0 20.5v-17C0 1.6 1.6 0 3.5 0h31C36.4 0 38 1.6 38 3.5v17c0 1.9-1.6 3.5-3.5 3.5z" />
                    <path
                      fill="#fafafa"
                      d="M3.5 1C2.1 1 1 2.1 1 3.5v17C1 21.9 2.1 23 3.5 23h31c1.4 0 2.5-1.1 2.5-2.5v-17C37 2.1 35.9 1 34.5 1h-31z"
                    />
                    <path
                      fill="#EB2227"
                      d="M22.3 3c.6.1 1.2.2 1.7.3 2.2.4 3.9 2 4.5 4.1.3 1.6.1 3.3-.7 4.7-.9 1.8-2.1 3.3-3.7 4.5-2.1 1.8-4.6 3.2-7.3 4.1-.6.2-1.3.3-1.9.3-1.1.1-2.1-.7-2.2-1.8v-.5c.2-2.1 1.7-3.8 3.8-4.4.5 0 1 0 1.4.2.5.2.8.8.6 1.4-.1.2-.2.3-.3.4-.4.4-.8.8-1.2 1.1s-.7.6-1 1c-.2.2-.3.4-.4.6-.2.4 0 .6.4.6.2 0 .4 0 .6-.1 3.5-1.3 6.3-3.7 8.1-6.9.3-.5.4-1 .4-1.6.1-1.7-1-2.7-2.6-2.2-1.2.4-2.5.9-3.6 1.5-1.2.6-2.4 1.4-3.6 2-.9.5-1.9.8-3 .9-.5.1-1 0-1.4-.1-1-.1-1.8-1.1-1.6-2.3 0-.1 0-.2.1-.3.5-1.4 1.4-2.7 2.6-3.5 2.3-1.8 5-3.1 7.9-3.7.5-.1 1.1-.2 1.6-.3h.8z"
                    />
                  </g>
                </svg>
              </div>
              <div>
                <svg
                  class="icon icon--full-color paymentImgPhnview"
                  viewBox="0 0 38 24"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="38"
                  role="img"
                  aria-labelledby="pi-ola_money"
                >
                  <title id="pi-ola_money">Ola Money</title>
                  <g fill-rule="evenodd" fill="none">
                    <path d="M34.5 24h-31C1.6 24 0 22.4 0 20.5v-17C0 1.6 1.6 0 3.5 0h31C36.4 0 38 1.6 38 3.5v17c0 1.9-1.6 3.5-3.5 3.5z" />
                    <path
                      fill="#fafafa"
                      d="M3.5 1C2.1 1 1 2.1 1 3.5v17C1 21.9 2.1 23 3.5 23h31c1.4 0 2.5-1.1 2.5-2.5v-17C37 2.1 35.9 1 34.5 1h-31z"
                    />
                    <ellipse rx="8.8" ry="9" cy="12" cx="19" fill="#378F43" />
                    <path
                      fill="#8BC249"
                      d="M19.1 8.8L17 15.4h-2.2l2.1-6.6zm4.1 0l-2.1 6.5h-2.3l2.1-6.6z"
                    />
                    <path
                      fill="#fafafa"
                      d="M12.7 8.8l2.1 6.6H17l-2-6.6h-2.3zm4.2 0l2.1 6.6h2.3l-2.1-6.6h-2.3zm4.1 0l2.1 6.6h2.3l-2.1-6.6H21z"
                    />
                  </g>
                </svg>
              </div>

            </Slider> */}
            <ul class="payment-icons list--inline site-footer__icon-list">
              <li class="payment-icon">
                <img
                  src={VisaImage}
                  className="paymentImgPhnview"
                />
              </li>
              <li class="payment-icon">
                <svg
                  class="icon icon--full-color paymentImgPhnview"
                  viewBox="0 0 38 24"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-labelledby="pi-master"
                >
                  <title id="pi-master">Mastercard</title>
                  <path opacity=".07" />
                  <path
                    fill="#fafafa"
                    d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
                  />
                  <circle fill="#EB001B" cx="15" cy="12" r="7" />
                  <circle fill="#F79E1B" cx="23" cy="12" r="7" />
                  <path
                    fill="#FF5F00"
                    d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"
                  />
                </svg>
              </li>
              <li class="payment-icon">
                <svg
                  class="icon icon--full-color paymentImgPhnview"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 38 24"
                  aria-labelledby="pi-american_express"
                >
                  <title id="pi-american_express">American Express</title>
                  <g fill="none">
                    <path fill="#000" opacity=".07" />
                    <path
                      fill="#006FCF"
                      d="M35,1 C36.1,1 37,1.9 37,3 L37,21 C37,22.1 36.1,23 35,23 L3,23 C1.9,23 1,22.1 1,21 L1,3 C1,1.9 1.9,1 3,1 L35,1"
                    />
                    <path
                      fill="#fafafa"
                      d="M8.971,10.268 L9.745,12.144 L8.203,12.144 L8.971,10.268 Z M25.046,10.346 L22.069,10.346 L22.069,11.173 L24.998,11.173 L24.998,12.412 L22.075,12.412 L22.075,13.334 L25.052,13.334 L25.052,14.073 L27.129,11.828 L25.052,9.488 L25.046,10.346 L25.046,10.346 Z M10.983,8.006 L14.978,8.006 L15.865,9.941 L16.687,8 L27.057,8 L28.135,9.19 L29.25,8 L34.013,8 L30.494,11.852 L33.977,15.68 L29.143,15.68 L28.065,14.49 L26.94,15.68 L10.03,15.68 L9.536,14.49 L8.406,14.49 L7.911,15.68 L4,15.68 L7.286,8 L10.716,8 L10.983,8.006 Z M19.646,9.084 L17.407,9.084 L15.907,12.62 L14.282,9.084 L12.06,9.084 L12.06,13.894 L10,9.084 L8.007,9.084 L5.625,14.596 L7.18,14.596 L7.674,13.406 L10.27,13.406 L10.764,14.596 L13.484,14.596 L13.484,10.661 L15.235,14.602 L16.425,14.602 L18.165,10.673 L18.165,14.603 L19.623,14.603 L19.647,9.083 L19.646,9.084 Z M28.986,11.852 L31.517,9.084 L29.695,9.084 L28.094,10.81 L26.546,9.084 L20.652,9.084 L20.652,14.602 L26.462,14.602 L28.076,12.864 L29.624,14.602 L31.499,14.602 L28.987,11.852 L28.986,11.852 Z"
                    />
                  </g>
                </svg>
              </li>
              <li class="payment-icon">
                <svg
                  class="icon icon--full-color paymentImgPhnview"
                  viewBox="0 0 38 24"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-labelledby="pi-rupay"
                >
                  <title id="pi-rupay">RuPay</title>
                  <g fill="none" fill-rule="evenodd">
                    <rect
                      stroke-opacity=".07"
                      fill="#fafafa"
                      x=".5"
                      y=".5"
                      width="37"
                      height="23"
                      rx="3"
                    />
                    <path fill="#097A44" d="M32 15.77l2-7.41 2 3.82z" />
                    <path fill="#F46F20" d="M30.76 15.79l2-7.4 2 3.82z" />
                    <path
                      d="M20.67 8.2a2 2 0 0 0-1.56-.56h-3l-1.95 6.81h1.75l.66-2.31h1.23a3.4 3.4 0 0 0 1.9-.5 2.93 2.93 0 0 0 1.12-1.72 1.77 1.77 0 0 0-.15-1.72zm-3.21.94h1.12a.76.76 0 0 1 .55.15c.11.11.07.35 0 .53a1.08 1.08 0 0 1-.4.62 1.21 1.21 0 0 1-.7.2H17l.46-1.5zM9.14 9a1.64 1.64 0 0 0-.2-.61 1.3 1.3 0 0 0-.58-.53 2.75 2.75 0 0 0-1.08-.18H4l-2 6.75h1.73l.72-2.52H5.7c.47 0 .58.1.6.13.02.03.09.15 0 .65l-.16.6a3.35 3.35 0 0 0-.11.59v.55h1.79l.12-.43-.11-.08s-.07-.05-.06-.2c.027-.19.07-.377.13-.56l.1-.42a2.14 2.14 0 0 0 .1-1.11.88.88 0 0 0-.26-.41 2 2 0 0 0 .68-.54 2.79 2.79 0 0 0 .53-1c.07-.22.101-.45.09-.68zm-1.86.83a.84.84 0 0 1-.5.6 1.79 1.79 0 0 1-.64.09H4.86l.38-1.33h1.43a1.1 1.1 0 0 1 .53.09c.05 0 .21.07.08.5v.05zm4.9 2.17a2.11 2.11 0 0 1-.3.67 1 1 0 0 1-.87.43c-.34 0-.36-.14-.38-.2a1.24 1.24 0 0 1 .07-.52l.89-3.11H9.9l-.86 3a3 3 0 0 0-.15 1.32c.08.42.4.91 1.41.91.247.004.493-.03.73-.1a2.51 2.51 0 0 0 .6-.29l-.08.3h1.62l1.47-5.13H13L12.18 12zm12.93 1.1l.63-2.18c.24-.83-.07-1.21-.37-1.39A2.75 2.75 0 0 0 24 9.2a2.87 2.87 0 0 0-2 .68 2.75 2.75 0 0 0-.69 1.1l-.09.26h1.61v-.11a1.15 1.15 0 0 1 .25-.37.84.84 0 0 1 .56-.17.89.89 0 0 1 .46.08v.18c0 .06 0 .15-.25.23a2.13 2.13 0 0 1-.48.1l-.44.05a4 4 0 0 0-1.25.32c-.57.271-.99.78-1.15 1.39a1.25 1.25 0 0 0 .17 1.22c.289.307.7.468 1.12.44a2.43 2.43 0 0 0 1.07-.25l.4-.23v.33H25l.13-.48-.13-.07a.61.61 0 0 1 0-.22c0-.25.07-.43.11-.58zm-2.92-.1a.62.62 0 0 1 .34-.4 2.17 2.17 0 0 1 .57-.15l.29-.05.3-.07v.07a1.24 1.24 0 0 1-.51.75 1.44 1.44 0 0 1-.72.21.34.34 0 0 1-.25-.08.55.55 0 0 1-.02-.28zm7.91-3.68l-1.69 3v-3h-1.8l.39 5.13-.12.19a.8.8 0 0 1-.23.25.64.64 0 0 1-.24.08h-.68l-.39 1.37h.83a2 2 0 0 0 1.29-.34 9.55 9.55 0 0 0 1.27-1.71l3.17-5-1.8.03z"
                      fill="#302F82"
                    />
                  </g>
                </svg>
              </li>
              <li class="payment-icon">
                <svg
                  class="icon icon--full-color paymentImgPhnview"
                  viewBox="0 0 38 24"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                  role="img"
                  aria-labelledby="pi-paytm"
                >
                  <title id="pi-paytm">Paytm</title>
                  <rect
                    x=".5"
                    y=".5"
                    width="37"
                    height="23"
                    rx="3"
                    ry="3"
                    fill="#fafafa"
                    stroke-opacity=".07"
                  />
                  <path
                    d="M14.17 13.32v2.6a.87.87 0 0 1-.74.91h-2.7a1.83 1.83 0 0 1-2-1.9 14.66 14.66 0 0 1 .06-2.08 1.81 1.81 0 0 1 1.69-1.54h1.19a.31.31 0 0 0 .34-.41.33.33 0 0 0-.23-.41H10c-.38 0-.46-.08-.46-.47V8.91a.3.3 0 0 1 .25-.35h2.39a1.87 1.87 0 0 1 1.92 2.1c.08.91.07 1.79.07 2.66zm-3.32 1.34a.34.34 0 0 0 .31.36h.61a.33.33 0 0 0 .36-.35v-1.13c0-.3-.16-.36-.72-.36s-.53.1-.56.37v1.11zm9.58-2.73v2.81a2 2 0 0 1-1.85 2.15h-2.45c-.34 0-.42-.07-.42-.42v-1.26a.3.3 0 0 1 .29-.35h2a.32.32 0 0 0 .36-.34.33.33 0 0 0-.31-.35h-1a1.94 1.94 0 0 1-2-1.86V9a.32.32 0 0 1 .26-.37h1.34c.34 0 .42.1.42.45v2.6c0 .45.1.54.55.54h.05c.62 0 .67-.05.67-.66V9a.36.36 0 0 1 .45-.5H20a.36.36 0 0 1 .42.42c.01 1.08.01 2.02.01 3.01zM4.57 14.48v1.94c0 .46-.06.51-.52.51H2.87a.3.3 0 0 1-.36-.36V9a.28.28 0 0 1 .22-.32H6.2a1.66 1.66 0 0 1 1.62 1.61 17.62 17.62 0 0 1 0 2.49 1.74 1.74 0 0 1-1.73 1.74H4.57zm0-2.08h.86a.32.32 0 0 0 .32-.31V11a.32.32 0 0 0-.28-.35h-.88v1.74z"
                    fill="#22346c"
                  />
                  <path
                    d="M28.94 9a2.2 2.2 0 0 1 2.86.1 7.28 7.28 0 0 1 1.15-.51 2.08 2.08 0 0 1 2.56 2v5.83c0 .36-.09.45-.45.45h-1.15a.35.35 0 0 1-.42-.42v-5.24a.6.6 0 0 0-.79-.64.55.55 0 0 0-.49.58v5.4a.31.31 0 0 1-.25.36h-1.43a.3.3 0 0 1-.35-.31v-5.43a.48.48 0 0 0-.29-.55 1.38 1.38 0 0 0-.71 0 .48.48 0 0 0-.26.53v5.21c0 .48-.06.55-.56.55h-1c-.36 0-.42-.08-.42-.44V9c0-.42.06-.47.46-.47h1.09a.42.42 0 0 1 .45.47zm-5.43 1.64h-.77a.33.33 0 0 1-.41-.4V9a.31.31 0 0 1 .25-.36h.1a2 2 0 0 0 1.74-1 2 2 0 0 1 .58-.57c.24-.16.42 0 .44.27v1.27h.7a.36.36 0 0 1 .42.42v1.22a.35.35 0 0 1-.42.42h-.66v5.83c0 .42-.07.48-.47.49h-1.09a.34.34 0 0 1-.42-.42c.01-1.87.01-5.78.01-5.92z"
                    fill="#24b8eb"
                  />
                </svg>
              </li>
              <li class="payment-icon">
                <svg
                  class="icon icon--full-color paymentImgPhnview"
                  viewBox="0 0 38 24"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="38"
                  role="img"
                  aria-labelledby="pi-airtel_money"
                >
                  <title id="pi-airtel_money">Airtel Money</title>
                  <g fill-rule="evenodd" fill="none">
                    <path d="M34.5 24h-31C1.6 24 0 22.4 0 20.5v-17C0 1.6 1.6 0 3.5 0h31C36.4 0 38 1.6 38 3.5v17c0 1.9-1.6 3.5-3.5 3.5z" />
                    <path
                      fill="#fafafa"
                      d="M3.5 1C2.1 1 1 2.1 1 3.5v17C1 21.9 2.1 23 3.5 23h31c1.4 0 2.5-1.1 2.5-2.5v-17C37 2.1 35.9 1 34.5 1h-31z"
                    />
                    <path
                      fill="#EB2227"
                      d="M22.3 3c.6.1 1.2.2 1.7.3 2.2.4 3.9 2 4.5 4.1.3 1.6.1 3.3-.7 4.7-.9 1.8-2.1 3.3-3.7 4.5-2.1 1.8-4.6 3.2-7.3 4.1-.6.2-1.3.3-1.9.3-1.1.1-2.1-.7-2.2-1.8v-.5c.2-2.1 1.7-3.8 3.8-4.4.5 0 1 0 1.4.2.5.2.8.8.6 1.4-.1.2-.2.3-.3.4-.4.4-.8.8-1.2 1.1s-.7.6-1 1c-.2.2-.3.4-.4.6-.2.4 0 .6.4.6.2 0 .4 0 .6-.1 3.5-1.3 6.3-3.7 8.1-6.9.3-.5.4-1 .4-1.6.1-1.7-1-2.7-2.6-2.2-1.2.4-2.5.9-3.6 1.5-1.2.6-2.4 1.4-3.6 2-.9.5-1.9.8-3 .9-.5.1-1 0-1.4-.1-1-.1-1.8-1.1-1.6-2.3 0-.1 0-.2.1-.3.5-1.4 1.4-2.7 2.6-3.5 2.3-1.8 5-3.1 7.9-3.7.5-.1 1.1-.2 1.6-.3h.8z"
                    />
                  </g>
                </svg>
              </li>
              <li class="payment-icon">
                <svg
                  class="icon icon--full-color paymentImgPhnview"
                  viewBox="0 0 38 24"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="38"
                  role="img"
                  aria-labelledby="pi-ola_money"
                >
                  <title id="pi-ola_money">Ola Money</title>
                  <g fill-rule="evenodd" fill="none">
                    <path d="M34.5 24h-31C1.6 24 0 22.4 0 20.5v-17C0 1.6 1.6 0 3.5 0h31C36.4 0 38 1.6 38 3.5v17c0 1.9-1.6 3.5-3.5 3.5z" />
                    <path
                      fill="#fafafa"
                      d="M3.5 1C2.1 1 1 2.1 1 3.5v17C1 21.9 2.1 23 3.5 23h31c1.4 0 2.5-1.1 2.5-2.5v-17C37 2.1 35.9 1 34.5 1h-31z"
                    />
                    <ellipse rx="8.8" ry="9" cy="12" cx="19" fill="#378F43" />
                    <path
                      fill="#8BC249"
                      d="M19.1 8.8L17 15.4h-2.2l2.1-6.6zm4.1 0l-2.1 6.5h-2.3l2.1-6.6z"
                    />
                    <path
                      fill="#fafafa"
                      d="M12.7 8.8l2.1 6.6H17l-2-6.6h-2.3zm4.2 0l2.1 6.6h2.3l-2.1-6.6h-2.3zm4.1 0l2.1 6.6h2.3l-2.1-6.6H21z"
                    />
                  </g>
                </svg>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="co-bac-foot">
        <div class="footer-end">
          <div class="row">
            <div class="col-md-6 col-12 order-lg-1 order-2">
              <div className="copywriteGlobalname">
                <i>Powered by <a href="https://bechofy.in/">Bechofy</a></i> - A Product of{" "}
                <a href="http://globaltrendz.com/" target="_blank">Global Trendz</a>
              </div>
            </div>
            <div class="col-md-6 col-12 order-lg-2 order-1">
              {" "}
              <div className="copywriteyear">
                Copyright © {moment().format("YYYY")} {clientName}. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
