import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../Login/Login.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Link } from "react-router-dom";
import { Component } from "react";
import Notiflix from "notiflix";
import PostApiCall from "../../../Helper/Api";
import moment from "moment";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: null,
      Password: null,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      Loading: false,
      NumRegex: /^[0-9]*$/,
    };
  }

  componentDidMount() {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });
  }

  onPost() {
    if (this.state.Email != null) {
      if (
        this.state.EmailRegex.test(this.state.Email) ||
        this.state.NumRegex.test(this.state.Email)
      ) {
        if (this.state.Password != null) {
          this.setState(
            {
              Loading: true,
            },
            () => {
              this.onLogin();
            }
          );
        } else {
          Notiflix.Notify.Failure("Please enter your password.");
        }
      } else {
        Notiflix.Notify.Failure("Please enter valid email or mobile number.");
      }
    } else {
      Notiflix.Notify.Failure("Please enter your email or mobile number.");
    }
  }

  onLogin = () => {
    PostApiCall.postRequest(
      {
        email: this.state.Email,
        password: this.state.Password,
        action: "Login",
        actiondate: moment().format("lll"),
      },
      "AddwebsiteLogin"
    ).then((results) =>
      results.json().then((obj) => {
        if (
          (results.status == 200 || results.status == 201) &&
          localStorage.getItem("OrderData")
        ) {
          localStorage.setItem("CustomerData", JSON.stringify(obj.data));
          localStorage.setItem("access", JSON.stringify(obj.token));
          this.setState({
            Loading: false,
          });

          this.onPostMoveCart(obj);
        } else if (results.status == 200 || results.status == 201) {
          this.setState({
            Loading: false,
          });
          localStorage.setItem("CustomerData", JSON.stringify(obj.data));
          localStorage.setItem("access", JSON.stringify(obj.token));
          if (localStorage.getItem("currentPage") != null) {
            localStorage.getItem("currentPage") == "Cart"
              ? (window.location.href = "/checkout")
              : (window.location.href = "/");
          } else {
            localStorage.getItem("ReviewPage") == "ReviewPage"
              ? this.props.history.goBack()
              : (window.location.href = "/");
          }
        } else {
          this.setState({
            Loading: false,
          });
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };

  onPostMoveCart(obj) {
    //console.log('move to cart')
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        customerid: JSON.stringify(obj.data[0].fld_customerid),
        cartid: Orderdetails[0].order_id,
      },
      "MoveTmpCrtToMain"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          localStorage.removeItem("OrderData");

          if (localStorage.getItem("currentPage") != null) {
            localStorage.getItem("currentPage") == "Cart"
              ? (window.location.href = "/checkout")
              : (window.location.href = "/");
          } else {
            localStorage.getItem("ReviewPage") == "ReviewPage"
              ? this.props.history.goBack()
              : (window.location.href = "/");
          }
        } else {
          Notiflix.Notify.Failure(
            "This email address is not registered with us. Please create an account."
          );
        }
      })
    );
  }

  render() {
    return (
      <>
        <div>
          <div className="pd-3">
            <Breadcrumb className="breadcrumb-content">
              <Breadcrumb.Item>
                <Link to="/">Home </Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item>
                <Link to="/login">Account </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="pd-3">
            <div className="login">
              <h1 className="text-center-login">Already Registered?</h1>
              <div>
                <Row>
                  {/* col size chnage by laxmi----------------------- */}

                  {/* <Col xs={6}> */}
                  <Col xs={12} sm={12} md={6}>
                    <div className="card-login">
                      <h2 className="custheading">New Customer</h2>
                      <p className="card-login-p card-login-pspacecreateaccount">
                        By creating an account you will be able to shop faster,
                        be up to date on an order's status, and keep track of
                        the orders you have previously made.
                      </p>
                      <Link to="/register">
                        <div className="butn accountSignbutn">
                          Create Account
                        </div>
                      </Link>
                    </div>
                  </Col>

                  {/* col size chnage by laxmi----------------------- */}
                  {/* <Col xs={6}> */}
                  <Col xs={12} sm={12} md={6} className="phnviewmarginlogin">
                    <div className="card-login">
                      <h2 className="custheading">Login</h2>
                      <p className="card-login-p">
                        If you already have an account, please log in.
                      </p>
                      <div>
                        <Form>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Email address / Mobile Number"
                            className="mb-3"
                          >
                            <Form.Control
                              type="email"
                              placeholder="name@example.com"
                              className="input"
                              value={this.state.Email}
                              onChange={(email) => {
                                this.setState({
                                  Email: email.target.value,
                                });
                              }}
                            />
                          </FloatingLabel>
                          <FloatingLabel
                            controlId="floatingPassword"
                            label="Password"
                          >
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              value={this.state.Password}
                              onChange={(password) => {
                                this.setState({
                                  Password: password.target.value,
                                });
                              }}
                            />
                          </FloatingLabel>
                        </Form>
                      </div>
                      <div
                        className="mt-4"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="butn accountSignbutn"
                          onClick={this.onPost.bind(this)}
                        >
                          {this.state.Loading == true ? (
                            <div class="d-flex justify-content-center">
                              <div
                                class="spinner-border"
                                role="status"
                                style={{ width: "1.4rem", height: "1.4rem" }}
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          ) : (
                            "Sign In"
                          )}
                        </div>
                        <div>
                          {" "}
                          <Link
                            to="/forgot-password"
                            className="forgotpassfont"
                          >
                            Forgot your password?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
