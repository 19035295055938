import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Notiflix from "notiflix";
import PostApiCall from "../../Helper/Api";
import GetApiCall from "../../Helper/GetApi";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "./Address.css";
import { BsEmojiNeutralFill } from "react-icons/bs";
import InnerBanner from "../InnerBanner/Innerbanner";

export class EditAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: null,
      Title: null,
      Mobile: null,
      Area: null,
      FlatNo: null,
      Landmark: null,
      CountryData: [],
      CityData: [],
      StateData: [],
      CountryId: 0,
      StateId: 0,
      CityId: 0,
      Country: null,
      State: null,
      City: null,
      Pincode: null,
      AddressId: null,
      NumRegex: /^[0-9]*$/,
      GSTNumber: null,
      loading: false,
      customerId: null,
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });

    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);

    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${CustomerDetails[0].fld_customerid}`,
      },
      "Get_customerData_by_id"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //   setcustomerDetails(obj.data)
          //   setcustomerId(obj.data[0].fld_customerid)
          this.setState({
            customerId: obj.data[0].fld_customerid,
          });
        }
      })
    );

    if (localStorage.getItem("AddressData") != null) {
      const addressData = JSON.parse(localStorage.getItem("AddressData"));
      this.setState(
        {
          Name: addressData.fld_contact_name,
          Title: addressData.fld_address_type,
          Mobile: addressData.fld_contact_number,
          Area: addressData.fld_address_2,
          FlatNo: addressData.fld_address_1,
          Landmark: addressData.fld_landmark,
          Country: addressData.fld_country,
          State: addressData.fld_state,
          City: addressData.fld_city,
          GSTNumber: addressData.fld_gstin,
          Pincode: addressData.fld_pincode,
        },
        () => {
          GetApiCall.getRequest("GetCountry").then((resultdes) =>
            resultdes.json().then((obj) => {
              this.setState({
                CountryData: obj.data,
              });

              if (obj.data.length != 0) {
                this.setState({
                  CountryId: obj.data.filter(
                    (value) => value.label == addressData.CountryName
                  )[0].value,
                });
              }

              PostApiCall.postRequest(
                {
                  countryid: obj.data.filter(
                    (value) => value.label == addressData.CountryName
                  )[0].value,
                },
                "GetState"
              ).then((results) =>
                results.json().then((objstate) => {
                  if (results.status == 200 || results.status == 201) {
                    if (objstate.data.length != 0) {
                      this.setState({
                        StateId: objstate.data.filter(
                          (value) => value.label == addressData.StateName
                        )[0].value,

                        StateData: objstate.data,
                        State: objstate.data[0].value,
                      });
                    }

                    PostApiCall.postRequest(
                      {
                        stateid: objstate.data.filter(
                          (value) => value.label == addressData.StateName
                        )[0].value,
                      },
                      "GetCity"
                    ).then((resultscity) =>
                      resultscity.json().then((objcity) => {
                        if (
                          resultscity.status == 200 ||
                          resultscity.status == 201
                        ) {
                          if (objcity.data.length != 0) {
                            this.setState({
                              CityId: objcity.data.filter(
                                (value) => value.label == addressData.CityName
                              )[0].value,
                              CityData: objcity.data,
                              City: objcity.data[0].value,
                            });
                          }
                        }
                      })
                    );
                  }
                })
              );
            })
          );
        }
      );
    }
  }
  onChangeCountry(country) {
    // //console.log(typeof(country.target.value));

    //  //console.log(this.state.CountryData[parseInt(country.target.value) +1])
    this.setState({
      CountryId: country.target.value,
      Country: this.state.CountryData[parseInt(country.target.value) + 1].label,
    });
    //  this.props.setcountry(this.state.CountryData[country.target.value - 1].label);

    PostApiCall.postRequest(
      {
        countryid: country.target.value,
        Country:
          this.state.CountryData[parseInt(country.target.value) + 1].label,
      },
      "GetState"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //  this.props.setstate(obj.data[0].label)
          // //console.log(obj);
          this.setState({
            StateId: obj.data[0].value,
            State: obj.data[0].label,
          });

          PostApiCall.postRequest(
            {
              stateid: obj.data[0].value,
            },
            "GetCity"
          ).then((resultscity) =>
            resultscity.json().then((objcity) => {
              if (resultscity.status == 200 || resultscity.status == 201) {
                this.setState({
                  CityData: objcity.data,
                  StateData: obj.data,
                });
                if (objcity.data.length > 0) {
                  // this.props.setcity(objcity.data[0].label)

                  this.setState({
                    CityId: objcity.data[0].value,
                    City: objcity.data[0].label,
                  });
                }
                Notiflix.Loading.Remove();
              }
            })
          );
        }
      })
    );
  }

  onChangeState(state) {
    //  this.props.setstate(state.target.value)
    this.setState({
      StateId: state.target.value,
    });

    for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {
      if (this.state.StateData[i].value == state.target.value) {
        // this.props.setstate();
        this.setState({
          State: this.state.StateData[i].label,
        });
      }
    }

    PostApiCall.postRequest(
      {
        stateid: state.target.value,
      },
      "GetCity"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length > 0) {
            // this.props.setcity(obj.data[0].label)
            this.setState({
              CityData: obj.data,
              CityId: obj.data[0].value,
              City: obj.data[0].label,
            });
          }
          Notiflix.Loading.Remove();
          // this.props.cityData(obj.data)
        }
      })
    );
  }

  onChangeCity(city) {
    // this.props.setcity(city.target.value)
    this.setState({
      CityId: city.target.value,
    });

    for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
      if (this.state.CityData[i].value == city.target.value) {
        this.setState({
          City: this.state.CityData[i].label,
        });
        // this.props.setcity(this.state.CityData[i].label);
      }
    }
  }

  onSaveData() {
    if (this.state.Title != "") {
      if (this.state.Name != "") {
        if (this.state.Mobile != "") {
          if (this.state.Mobile.length == 10) {
            if (this.state.FlatNo != "") {
              if (this.state.Area != "") {
                if (this.state.Country != "") {
                  if (this.state.State != "") {
                    if (this.state.City != "") {
                      if (this.state.Pincode != "") {
                        if (this.state.Pincode.length == 6) {
                          this.onPost();
                        } else {
                          Notiflix.Notify.failure("Please enter valid pincode");
                        }
                      } else {
                        Notiflix.Notify.failure("Please enter pincode");
                      }
                    } else {
                      Notiflix.Notify.failure("Please enter city");
                    }
                  } else {
                    Notiflix.Notify.failure("Please enter state");
                  }
                } else {
                  Notiflix.Notify.failure("Please select country");
                }
              } else {
                Notiflix.Notify.failure(
                  "Please enter Area/Colony/Street/Sector/Village"
                );
              }
            } else {
              Notiflix.Notify.failure(
                "Please enter Flat/House/Company/Building/Apartment No."
              );
            }
          } else {
            Notiflix.Notify.failure("Please enter valid mobile number");
          }
        } else {
          Notiflix.Notify.failure("Please enter mobile number");
        }
      } else {
        Notiflix.Notify.failure("Please enter your Full Name");
      }
    } else {
      Notiflix.Notify.failure("Please select Address Title");
    }
  }

  onPost() {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var addressData = JSON.parse(localStorage.getItem("AddressData"));
    ////console.log(addressData.fld_address_id);

    PostApiCall.postRequest(
      {
        CUSTOMERID: logindetails[0].fld_customerid,
        addressid: addressData.fld_address_id,
        title: this.state.Title,
        name: this.state.Name,
        mobileNo: this.state.Mobile,
        area: this.state.Area,
        flatno: this.state.FlatNo,
        landmark: this.state.Landmark,
        gstNumber: this.state.GSTNumber,
        country: this.state.CountryId,
        state: this.state.StateId,
        city: this.state.CityId,
        pincode: this.state.Pincode,
        action: "Update",
        longitude: this.state.Longitude,
        latitude: this.state.Latitude,
      },
      "Add_CustomerAddress"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          this.setState({
            loading: false,
          });

          Notiflix.Notify.Success("Address updated successfully ");
          localStorage.removeItem("AddressData");
          window.location.href = `/address/${this.state.customerId}`;
        } else {
          this.setState({
            loading: false,
          });

          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  }

  render() {
    return (
      <React.Fragment>
        <InnerBanner />

        <div className="pd-3 mt-3">
          <div className="Address-form">
            <div>
              <div className="Address-form-edit">
                <h2> Edit Address</h2>
              </div>
              <div>
                <Form>
                  <Row>
                    <div class="col-md-12 center">
                      <div class="form-group">
                        <label for="validationCustom01">
                          Address Title<span class="mandatory">*</span>
                        </label>
                        <br />
                        <label class="radio-inline">
                          <input
                            type="radio"
                            name="optradio"
                            checked={
                              this.state.Title === "Office" ? true : false
                            }
                            onChange={() => {
                              this.setState({
                                Title: "Office",
                              });
                            }}
                          />{" "}
                          Office
                        </label>
                        <label
                          class="radio-inline"
                          style={{ marginLeft: "10px" }}
                        >
                          <input
                            type="radio"
                            name="optradio"
                            checked={this.state.Title === "Home" ? true : false}
                            onChange={() => {
                              this.setState({
                                Title: "Home",
                              });
                            }}
                          />{" "}
                          Home
                        </label>
                      </div>
                    </div>
                    {/* col size add by laxmi------------------------------------- */}
                    {/* <Col> */}
                    <Col xs={12} s={12} md={12} lg={6}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Name"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="name@example.com"
                          className="input"
                          value={this.state.Name}
                          onChange={(text) => {
                            this.setState({
                              Name: text.target.value,
                            });
                          }}
                        />
                      </FloatingLabel>
                    </Col>
                    {/* col size add by laxmi------------------------------------- */}
                    {/* <Col> */}
                    <Col xs={12} s={12} md={12} lg={6}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Mobile Number"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="name@example.com"
                          className="input"
                          value={this.state.Mobile}
                          onChange={(mobile) => {
                            if (
                              this.state.NumRegex.test(mobile.target.value) &&
                              mobile.target.value.length <= 10
                            ) {
                              this.setState({
                                Mobile: mobile.target.value,
                              });
                            }
                          }}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flat/House/Building No."
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="name@example.com"
                      className="input"
                      value={this.state.FlatNo}
                      onChange={(text) => {
                        this.setState({
                          FlatNo: text.target.value,
                        });
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Area/Street/Sector/Village"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="name@example.com"
                      className="input"
                      value={this.state.Area}
                      onChange={(text) => {
                        this.setState({
                          Area: text.target.value,
                        });
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Lankmark (optional)"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="name@example.com"
                      className="input"
                      value={this.state.Landmark}
                      onChange={(text) => {
                        this.setState({
                          Landmark: text.target.value,
                        });
                      }}
                    />
                  </FloatingLabel>
                  <Row>
                    {/* col size add by laxmi------------------------------------- */}
                    {/* <Col> */}
                    <Col xs={12} s={12} md={12} lg={6}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="GST Number (optional)"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="name@example.com"
                          className="input"
                          value={this.state.GSTNumber}
                          onChange={(text) => {
                            this.setState({
                              GSTNumber: text.target.value,
                            });
                          }}
                        />
                      </FloatingLabel>
                    </Col>
                    {/* col size add by laxmi------------------------------------- */}
                    {/* <Col> */}
                    <Col xs={12} s={12} md={12} lg={6}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Pincode"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Pincode"
                          className="input"
                          value={this.state.Pincode}
                          onChange={(pincode) => {
                            if (
                              this.state.NumRegex.test(pincode.target.value) &&
                              pincode.target.value.length <= 6
                            ) {
                              this.setState({
                                Pincode: pincode.target.value,
                              });
                            }
                          }}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>

                  <Row>
                    {/* col size add by laxmi------------------------------------- */}
                    {/* <Col> */}
                    <Col xs={12} s={12} md={12} lg={4}>
                      <Form.Label htmlFor="basic-url">Country</Form.Label>

                      <Form.Select
                        aria-label="Default select example"
                        className="mb-3 pd-country"
                        value={this.state.CountryId}
                        onChange={this.onChangeCountry.bind(this)}
                      >
                        {this.state.CountryData.map((schedule) => (
                          <option key={schedule.label} value={schedule.value}>
                            {schedule.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    {/* col size add by laxmi------------------------------------- */}
                    {/* <Col> */}
                    <Col xs={12} s={12} md={12} lg={4}>
                      <Form.Label htmlFor="basic-url">State</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="mb-3 pd-country"
                        value={this.state.StateId}
                        onChange={this.onChangeState.bind(this)}
                      >
                        {this.state.StateData.map((schedule) => (
                          <option key={schedule.label} value={schedule.value}>
                            {schedule.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    {/* col size add by laxmi------------------------------------- */}
                    {/* <Col> */}
                    <Col xs={12} s={12} md={12} lg={4}>
                      <Form.Label htmlFor="basic-url">City</Form.Label>

                      <Form.Select
                        aria-label="Default select example"
                        className="mb-3 pd-country"
                        value={this.state.CityId}
                        onChange={this.onChangeCity.bind(this)}
                      >
                        {this.state.CityData.map((schedule) => (
                          <option key={schedule.label} value={schedule.value}>
                            {schedule.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <div className="but-address-flex">
                    {/* <div className="butn pd-aou-but"> Update Address</div> */}
                    <div>
                      <button
                        className="butn butnaddAddressNew"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState(
                            {
                              loading: true,
                            },
                            () => {
                              this.onSaveData();
                            }
                          );
                        }}
                      >
                        {this.state.loading && this.state.loading == true ? (
                          <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          "Update Address"
                        )}
                      </button>{" "}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EditAddress;
