import "../Header/header.css";
import React, { useEffect, useState, useContext } from "react";
import pplogo1 from "../../../Images/pplogo1.webp";
import { BiSearch, BiChevronDown } from "react-icons/bi";
import { AiOutlineHeart, AiOutlineUser } from "react-icons/ai";
import Adcart from "../Adcart/Adcart";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Leftdrawe from "../Leftdrawer/Leftdrawer";
import PostApiCall from "../../../Helper/Api";
import moment from "moment";
import Notiflix from "notiflix";
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { store } from "../../../context/store";
import Logo from "../../../Images/Logo1.png";
import GetApiCall from "../../../Helper/GetApi";

import { Navbar, Nav, FormControl } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function Header() {
  const [show, setShow] = React.useState(false);
  const [show1, setShow1] = React.useState(true);
  const [showAccountDropdown, setshowAccountDropdown] = React.useState(false);
  const [logindetails, setlogindetails] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [suggestions, setSuggestions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [variantId, setVariantId] = React.useState(null);
  const [changePasswordCard, setchangePasswordCard] = React.useState(false);
  const [changePasswordVerify, setchangePasswordVerify] = React.useState(false);
  const [OldPassword, setOldPassword] = React.useState("");
  const [NewPassword, setNewPassword] = React.useState("");
  const [ConfirmPassword, setConfirmPassword] = React.useState("");
  const [PasswordRegex, setPasswordRegex] = React.useState(
    /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/
  );
  const [Salt, setSalt] = React.useState([]);
  const [Email, setEmail] = React.useState(null);
  const [openCartData, setopenCartData] = useState(false);
  const [categoryData, setcategoryData] = useState([]);
  const [offerData, setOfferData] = useState([]);
  const [offerName, setOfferName] = useState(null);
  const [offerPrice, setOfferPrice] = useState(null);
  const [name, setName] = useState(null)

  const cartCount = useContext(store);

  const history = useHistory();
  const onClick = () => setShow(false);
  const searchdiv = () => {
    setShow1(!show1);
  };

  useEffect(() => {
    if (
      localStorage.getItem("CustomerData") != null ||
      localStorage.getItem("CustomerData") != undefined
    ) {
      setlogindetails(JSON.parse(localStorage.getItem("CustomerData"))[0]);
      setEmail(JSON.parse(localStorage.getItem("CustomerData"))[0].fld_email);
      setName(JSON.parse(localStorage.getItem("CustomerData"))[0].fld_name)
    }
  }, []);

  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });

    if (suggestions.length == 0) {
      PostApiCall.postRequest(
        {
          variantName: "",
        },
        "SearchVariantByProduct"
      ).then((results) =>
        results.json().then((obj1) => {
          if (results.status == 200 || results.status == 201) {
            console.log(obj1.data);
            setSuggestions(obj1.data);
          }
        })
      );
    }

    PostApiCall.postRequest(
      {
        catCount: "*",
      },
      "GetProductCategories"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          //  console.log(obj1.data);
          setcategoryData(obj1.data);
        }
      })
    );

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    // console.log(localStorage.getItem("OrderData"))

    var Order = localStorage.getItem("OrderData");

    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null ||
            localStorage.getItem("OrderData") == undefined
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data.length == 0) {
          localStorage.removeItem("OrderData");
        }
      });

    GetApiCall.getRequest("GetOfferList").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length == 0) {
          setShow(false);
        } else {
          // console.log(obj.data)
          setShow(true);
          setOfferData(
            obj.data.filter((value) => value.fld_showonwebsite == "Active")
          );
          setOfferName(obj.data[0].fld_code);
          setOfferPrice(obj.data[0].fld_pricepercent);
        }
      })
    );
  }, []);

  function onPostSalt() {
    PostApiCall.postRequest(
      {
        email: Email,
      },
      "Get_SaltByEmailId_ForgotPassword"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setSalt(obj.data);
        } else {
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  }

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <>
      <div>
        {show ? (
          <div className="header-flex">
            <div className="parent-1" style={{ width: "95%" }}>
              <marquee width="100%" direction="left">
                Use coupon code {offerName} to get {offerPrice}% off on every
                order
              </marquee>
            </div>
            <div>
              <div onClick={onClick} style={{ cursor: "pointer" }}>
                <AiFillCloseCircle className="close-head" />
              </div>
            </div>
          </div>
        ) : null}
        {show1 ? (
          <Navbar
            bg="light"
            className={scroll ? "paddingPhnView fixed-top" : "paddingPhnView"}
          >
            <div className="contaner-head">
              <div className="pd-r-but">
                <Leftdrawe className="headerIconSize" />
              </div>
              <Navbar.Brand href="/">
                <div className="logo" style={{ width: "140px" }}>
                  <Link to="/">
                    <img alt="pink" src={Logo} width="100%" />{" "}
                  </Link>
                </div>
              </Navbar.Brand>

              <Navbar.Collapse className="flex-left-header">
                <Nav
                  className="headerphnseachiconPadding"
                  style={{ paddingBottom: "10px" }}
                >
                  <Nav.Link
                    href="#deets"
                    className="icons-header headerIconSize"
                  >
                    <BiSearch className="headerIconSize" onClick={searchdiv} />
                  </Nav.Link>
                  {/* <Nav.Link eventKey={2} href="#memes" className="icons-header">
                    <AiOutlineHeart />
                  </Nav.Link> */}
                  <Nav.Link
                    eventKey={2}
                    className="icons-header headerIconSize"
                    onMouseOver={() => {
                      setshowAccountDropdown(true);
                    }}
                    onMouseOut={() => {
                      setshowAccountDropdown(false);
                    }}
                  >
                    <Link to={logindetails.length == 0 ? "/login" : "/account"}>
                      <AiOutlineUser />
                    </Link>
                    {logindetails.length == 0 ? (
                      <div
                        class="account-dropdown"
                        style={{
                          display:
                            showAccountDropdown == true ? "block" : "none ",
                        }}
                        onMouseOver={() => {
                          setshowAccountDropdown(true);
                        }}
                        onMouseOut={() => {
                          setshowAccountDropdown(false);
                        }}
                      >
                        <Link
                          to="/login"
                          // add by laxmi
                          onClick={() => {
                            setshowAccountDropdown(false);
                          }}
                        >
                          Login
                        </Link>
                        <Link
                          to="/register"
                          // add by laxmi
                          onClick={() => {
                            setshowAccountDropdown(false);
                          }}
                        >
                          Sign up
                        </Link>
                      </div>
                    ) : (
                      <div
                        class="account-dropdown"
                        style={{
                          display:
                            showAccountDropdown == true ? "block" : "none ",
                        }}
                        onMouseOver={() => {
                          setshowAccountDropdown(true);
                        }}
                        onMouseOut={() => {
                          setshowAccountDropdown(false);
                        }}
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            setshowAccountDropdown(false);
                          }}
                        >
                          Hi, {name}
                        </Link>
                        <Link
                          to="/account"
                          // add by laxmi
                          onClick={() => {
                            setshowAccountDropdown(false);
                          }}
                        >
                          My Account
                        </Link>
                        <Link
                          to="#changepassword"
                          onClick={() => {
                            setchangePasswordVerify(true);

                            // add by laxmi
                            setshowAccountDropdown(false);
                          }}
                        >
                          Change Password
                        </Link>
                        <a
                          onClick={() => {
                            localStorage.removeItem("CustomerData");
                            window.location.href = "/";

                            // add by laxmi
                            setshowAccountDropdown(false);
                          }}
                        >
                          Logout
                        </a>
                      </div>
                    )}
                  </Nav.Link>
                  <Nav.Link
                    eventKey={3}
                    href="#deets"
                    className="icons-header headerIconSize"
                    onClick={() => {
                      setopenCartData(!openCartData);
                    }}
                    openCartFetchData={openCartData}
                  >
                    <Adcart
                      openCartFetchData={openCartData}
                    // className="headerIconSize"
                    />
                    <span openCartFetchData={openCartData} class="wbhcartitem">
                      {cartCount.cart}
                    </span>
                  </Nav.Link>
                </Nav>
                <Nav
                  className="my-2 my-lg-0 hide-mobile marin-ri-0"
                  style={{
                    maxHeight: "100px",
                    // margin: "auto"
                  }}
                >
                  <div className="header-2-div1">
                    <ul className="ul-inline">
                      <li>
                        <Link className="li-link" to="/">
                          Home
                        </Link>
                      </li>

                      <li className="dropdown1">
                        <Link className="li-link" to="/about-us">
                          About Us
                        </Link>
                      </li>
                      <li className="dropdown2">
                        <Link className="li-link" to="#">
                          Shop
                          <BiChevronDown />
                        </Link>

                        <div className="header-dropdown">
                          {categoryData.map((data, index) => {
                            return (
                              <a
                                href={`${process.env.PUBLIC_URL
                                  }/${data.fld_categoryname.trim()}/${data.fld_categoryid
                                  }`}
                              >
                                {data.fld_categoryname}
                              </a>
                            );
                          })}
                        </div>
                      </li>
                      <li>
                        <a
                          className="li-link"
                          href={`${process.env.PUBLIC_URL}/products/allproducts`}
                        >
                          All Products
                        </a>
                      </li>
                      <li>
                        <Link className="li-link" to="/contact-us">
                          Contact Us
                        </Link>
                      </li>

                      {/* <li>
                        <Link className="li-link" to="/blogs">
                          Blogs
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </Nav>
                {/* <Nav>
                  <Nav.Link href="#deets" className="icons-header">
                    <BiSearch onClick={searchdiv} />
                  </Nav.Link>
                 
                  <Nav.Link
                    eventKey={2}
                    className="icons-header"
                    onMouseOver={() => {
                      setshowAccountDropdown(true);
                    }}
                  >
                    <Link to={logindetails.length == 0 ? "/login" : "/account"}>
                      <AiOutlineUser />
                    </Link>
                    {logindetails.length == 0 ? (
                      <div
                        class="account-dropdown"
                        style={{ display: showAccountDropdown == true ? "block" : "none " }}
                        onMouseOver={() => {
                          setshowAccountDropdown(true);
                        }}
                        onMouseOut={() => {
                          setshowAccountDropdown(false);
                        }}
                      >
                        <Link to="/login">Login</Link>
                        <Link to="/register">Sign up</Link>
                      </div>
                    ) : (
                      <div
                        class="account-dropdown"
                        style={{ display: showAccountDropdown == true ? "block" : "none " }}
                        onMouseOver={() => {
                          setshowAccountDropdown(true);
                        }}
                        onMouseOut={() => {
                          setshowAccountDropdown(false);
                        }}
                      >
                        <Link to="/account">My Account</Link>
                        <Link
                          to="#changepassword"
                          onClick={() => {
                            setchangePasswordVerify(true);
                          }}
                        >
                          Change Password
                        </Link>
                        <a
                          onClick={() => {
                            localStorage.removeItem("CustomerData");
                            window.location.href = "/";
                          }}
                        >
                          Logout
                        </a>
                      </div>
                    )}
                  </Nav.Link>
                  <Nav.Link
                    eventKey={3}
                    href="#deets"
                    className="icons-header"
                    onClick={() => {
                      setopenCartData(!openCartData);
                    }}
                  >
                    <Adcart openCartFetchData={openCartData} />
                    <span class="wbhcartitem">{cartCount.cart}</span>
                  </Nav.Link>
                </Nav> */}
              </Navbar.Collapse>
            </div>
            <Modal
              backdrop={false}
              size="lg"
              isOpen={changePasswordVerify == true ? true : false}
            >
              <ModalHeader
                className="py-2"
                close={
                  <button
                    className=" crossChngepassword"
                    onClick={() => {
                      setchangePasswordVerify(false);
                    }}
                  >
                    ×
                  </button>
                }
              >
                Change Password
              </ModalHeader>
              <ModalBody>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    value={Email}
                    disabled
                  />
                </div>
                <p className="mb-2">To continue, first verify it’s you</p>
                <div class="input-group mb-3">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Enter your password"
                    value={OldPassword}
                    onChange={(text) => {
                      setOldPassword(text.target.value);
                    }}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  className="butn"
                  onClick={() => {
                    if (OldPassword != "") {
                      setLoading(true);
                      PostApiCall.postRequest(
                        {
                          email: Email,
                          password: OldPassword,
                          action: "Login",
                          actiondate: moment().format("lll"),
                        },
                        "AddwebsiteLogin"
                      ).then((results) =>
                        results.json().then((obj) => {
                          if (results.status == 200 || results.status == 201) {
                            Notiflix.Loading.Remove();
                            setLoading(false);
                            setchangePasswordVerify(false);
                            setchangePasswordCard(true);
                            onPostSalt();
                          } else {
                            setLoading(false);
                            Notiflix.Notify.Failure(obj.data);
                          }
                        })
                      );
                    } else {
                      Notiflix.Notify.Failure("Please enter your password.");
                    }
                  }}
                >
                  {loading == true ? (
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    "Verify"
                  )}
                </button>{" "}
              </ModalFooter>
            </Modal>
            <Modal
              backdrop={false}
              size="lg"
              isOpen={changePasswordCard == true ? true : false}
            >
              <ModalHeader
                close={
                  <button
                    className="close"
                    onClick={() => {
                      setchangePasswordCard(false);
                    }}
                  >
                    ×
                  </button>
                }
              >
                Change Password
              </ModalHeader>
              <ModalBody>
                <div class="input-group mb-3">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="New Password"
                    value={NewPassword}
                    onChange={(newPassword) => {
                      setNewPassword(newPassword.target.value);
                    }}
                  />
                </div>
                <div class="input-group mb-3">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Confirm New Password"
                    value={ConfirmPassword}
                    onChange={(confirmPassword) => {
                      setConfirmPassword(confirmPassword.target.value);
                    }}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  className="butn"
                  onClick={() => {
                    if (NewPassword != "") {
                      if (PasswordRegex.test(NewPassword)) {
                        if (ConfirmPassword != "") {
                          if (ConfirmPassword == NewPassword) {
                            setLoading(true);
                            var customer = localStorage.getItem("CustomerData");
                            var CustomerDetails = JSON.parse(customer);

                            PostApiCall.postRequest(
                              {
                                userid: CustomerDetails[0].fld_customerid,
                                password: ConfirmPassword,
                                salt: Salt[0].fld_salt,
                              },
                              "ChangeCustomerPassword"
                            ).then((results) =>
                              // const objs = JSON.parse(result._bodyText)
                              results.json().then((obj) => {
                                if (
                                  results.status == 200 ||
                                  results.status == 201
                                ) {
                                  setLoading(false);
                                  Notiflix.Notify.Success(
                                    "Password Successfully updated"
                                  );
                                  localStorage.removeItem("CustomerData");
                                  setchangePasswordCard(false);
                                  window.location.href = "/login";
                                } else {
                                  setLoading(false);
                                  Notiflix.Notify.Failure(
                                    "something went wrong, try again later"
                                  );
                                }
                              })
                            );
                          } else {
                            Notiflix.Notify.failure(
                              "New Password and Confirm Password do not match."
                            );
                          }
                        } else {
                          Notiflix.Notify.failure(
                            "Please confirm your password."
                          );
                        }
                      } else {
                        Notiflix.Notify.failure(
                          "Password must be at least 6 characters  & contains a number and a letter"
                        );
                      }
                    } else {
                      Notiflix.Notify.failure("Please enter new password.");
                    }
                  }}
                >
                  {loading == true ? (
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    "Change Password"
                  )}
                </button>{" "}
              </ModalFooter>
            </Modal>
          </Navbar>
        ) : (
          <div>
            <div className="div-searchrecip">
              {" "}
              <InputGroup className=" mb-32">
                <FormControl
                  placeholder="Search"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  className="input-search"
                  value={searchText}
                  onChange={(text) => {
                    setSearchText(text.target.value);
                  }}
                />
                <InputGroup.Text id="basic-addon2" className="search-but-add">
                  <BiSearch className="search-but-icon" />
                </InputGroup.Text>
                <AiFillCloseCircle
                  className="search-close-head"
                  onClick={searchdiv}
                />
              </InputGroup>
              <div
                class="predictive-search predictive-search--header"
                style={{ display: searchText == "" ? "none" : "block" }}
              >
                <div id="predictive-search-results">
                  <h2
                    id="predictive-search-products"
                    class="predictive-search__heading"
                  >
                    Products
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="spinner"
                      viewBox="0 0 66 66"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        class="path"
                        fill="none"
                        stroke-width="6"
                        cx="33"
                        cy="33"
                        r="30"
                      ></circle>
                    </svg>
                  </h2>
                  <ul
                    id="predictive-search-results-list"
                    class="predictive-search__results-list list-unstyled"
                  >
                    {suggestions
                      .filter((data, index) => {
                        if (
                          data.fld_variantname
                            .toLowerCase()
                            .includes(searchText.toLowerCase()) &&
                          searchText !== ""
                        ) {
                          return data;
                        }
                      })
                      .map((data, index) => {
                        return (
                          <li
                            id="predictive-search-option-1"
                            class="predictive-search__list-item"
                            role="option"
                            aria-selected="false"
                            key={index}
                          >
                            <a
                              href={`${process.env.PUBLIC_URL
                                }/productdetail/${data.fld_variantname.replace(
                                  /\s/g,
                                  "-"
                                )}/${data.fld_variantid}`}
                              class="predictive-search__item predictive-search__item--link"
                              tabindex="-1"
                            >
                              <a
                                href={`${process.env.PUBLIC_URL
                                  }/productdetail/${data.fld_variantname.replace(
                                    /\s/g,
                                    "-"
                                  )}/${data.fld_variantid}`}
                              >
                                <img
                                  class="predictive-search__image imgphnviewsearchheader"
                                  loading="lazy"
                                  src={data.fld_imageurl}
                                  alt={data.fld_variantname}
                                  width="80"
                                  height="80.0"
                                />
                              </a>
                              <div class="predictive-search__item-content">
                                <div className="row" style={{ width: "100%" }}>
                                  <div className="col-lg-4 col-md-4 col-sm-5 col-12">
                                    <a
                                      href={`${process.env.PUBLIC_URL
                                        }/productdetail/${data.fld_variantname.replace(
                                          /\s/g,
                                          "-"
                                        )}/${data.fld_variantid}`}
                                    >
                                      <h3 class="predictive-search__item-heading">
                                        {data.fld_variantname}
                                      </h3>
                                    </a>
                                  </div>

                                  <div className="col-lg-4 col-md-4 col-sm-3 col-12 text-center">
                                    <div class="price pricephnserchome price--on-sale ">
                                      <div>
                                        <div class="price__sale">
                                          <span class="price-item price-item--sale price-item--last">
                                            ₹ {data.fld_selling_price}
                                          </span>

                                          <span>
                                            <s class="price-item price-item--regular">
                                              ₹ {data.fld_mrp}
                                            </s>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4 col-sm-4 col-12 text-end">
                                    {data.fld_stock != "Out of Stock" ? (
                                      <a
                                        className="butn butnsearchphnhome butnsearchicondrps"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setVariantId(data.fld_variantid);
                                          setLoading(true);
                                          var login =
                                            localStorage.getItem(
                                              "CustomerData"
                                            );
                                          var logindetails = JSON.parse(login);

                                          var Order =
                                            localStorage.getItem("OrderData");
                                          var Orderdetails = JSON.parse(Order);

                                          PostApiCall.postRequest(
                                            {
                                              orderdate: moment().format("lll"),
                                              itemdetails: `
                                          [
                                          { 
                                              "Item":${data.fld_variantid}, 
                                              "Quantity":${1}
                                          }
                                          ]
                                          `,
                                              status: "INCART",
                                              customerid:
                                                localStorage.getItem(
                                                  "CustomerData"
                                                ) == null
                                                  ? null
                                                  : logindetails[0]
                                                    .fld_customerid,
                                              createdon: moment().format("lll"),
                                              updatedon: moment().format("lll"),
                                              orderid:
                                                localStorage.getItem(
                                                  "OrderData"
                                                ) == null
                                                  ? 0
                                                  : Orderdetails[0].order_id,

                                              updatedby:
                                                localStorage.getItem(
                                                  "CustomerData"
                                                ) == null
                                                  ? null
                                                  : logindetails[0].fld_userid,
                                            },
                                            "AddToCartWebsite"
                                          ).then((result) =>
                                            result.json().then((obj) => {
                                              if (
                                                result.status == 200 ||
                                                result.status == 201
                                              ) {
                                                setLoading(false);
                                                Notiflix.Notify.Success(
                                                  "Product added to cart."
                                                );
                                                if (
                                                  localStorage.getItem(
                                                    "OrderData"
                                                  ) == null
                                                ) {
                                                  localStorage.setItem(
                                                    "OrderData",
                                                    JSON.stringify(obj.data)
                                                  );
                                                  // this.getCartdetails();
                                                }
                                                // this.getCartdetails();
                                              } else {
                                                Notiflix.Notify.Failure(
                                                  obj.data
                                                );
                                              }
                                            })
                                          );
                                        }}
                                      >
                                        {variantId == data.fld_variantid &&
                                          loading == true ? (
                                          <div
                                            class="spinner-border text-light"
                                            role="status"
                                          >
                                            <span class="visually-hidden">
                                              Loading...
                                            </span>
                                          </div>
                                        ) : (
                                          "Add to Cart"
                                        )}
                                      </a>
                                    ) : (
                                      <button
                                        type="button"
                                        class="butn butnsearchphnhome butnsearchicondrpssoldout"
                                        style={{ background: "#a8aba8" }}
                                      >
                                        Sold Out
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                        );
                      })}

                    <li
                      id="predictive-search-option-search-keywords"
                      class="predictive-search__list-item"
                      role="option"
                    >
                      <button
                        class="searchdropitemlastarrow predictive-search__item predictive-search__item--term link link--text h5 animate-arrow d-flex"
                        tabindex="-1"
                      >
                        Search for "{searchText}"
                        <svg
                          viewBox="0 0 14 10"
                          fill="none"
                          aria-hidden="true"
                          focusable="false"
                          role="presentation"
                          class="icon icon-arrow"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
