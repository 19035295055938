import "./ImageGrid.css";
import Container from "react-bootstrap/Container";
import banner3 from "../../../Images/ParallaxProductBanner/parallaxproductsbanner1.webp";
import Card1Image from "../../../Images/BannerProductCardImages/Pillowcase.webp";
import Card2Image from "../../../Images/BannerProductCardImages/Backpack.webp";
import Card3Image from "../../../Images/BannerProductCardImages/Designerbag2.webp";
import Card4Image from "../../../Images/BannerProductCardImages/Pencilcase4.webp";
import Notiflix from "notiflix";
import moment from "moment";
import PostApiCall from "../../../Helper/Api";
import { store } from "../../../context/store";

import { Component } from "react";

class ImageBannerProducts extends Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      card1Open: false,
      card2Open: false,
      card3Open: true,
      card4Open: false,
      card5Open: false,
    };
  }

  componentDidMount() {
    const store = this.context;
  }

  getCartdetails = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        console.log(res1.data);
        if (res1.data) {
          this.setState({ ProductCart: res1.data });
          this.context.setCart(res1.data.length);
        }
      });
  };

  render() {
    return (
      <>
        <div className="ban3-pd">
          <div className="banner3">
            <div class="wblbook container-fluid">
              <div class="wblbookmain" id="wblbookmain">
                <img
                  loading="lazy"
                  src={banner3}
                  alt=""
                  class="img-fluid mx-auto"
                  width="1600"
                  height="600"
                />
                <div
                  class="wblookbook"
                  style={{ top: "50%", left: "17%" }}
                  onClick={() => {
                    this.setState({
                      card3Open: false,
                      card2Open: false,
                      card4Open: false,
                      card1Open: true,
                    });
                  }}
                >
                  <div class="wblookbtn " target="1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon icon-plus"
                      fill="none"
                      viewBox="0 0 10 10"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <div
                    class="wblbookcnt"
                    style={{
                      display: this.state.card1Open == true ? "block" : "none",
                    }}
                  >
                    <div class="wblookimg">
                      <a
                        href={`${
                          process.env.PUBLIC_URL
                        }/productdetail/${"World Map Pillowcase".trim()}/${13}`}
                      >
                        <img
                          loading="lazy"
                          src={Card1Image}
                          alt="World Map Pillowcase"
                          class="img-fluid mx-auto"
                        />
                      </a>
                    </div>
                    <div class="wblookdesc">
                      <h3>
                        <a
                          href={`${
                            process.env.PUBLIC_URL
                          }/productdetail/${"World Map Pillowcase".trim()}/${13}`}
                        >
                          World Map Pillowcase
                        </a>
                      </h3>
                      <div class="wblookprice">
                        <span class="new-price">₹1530.00</span>

                        <span class="old-price"> ₹1800.00</span>
                      </div>
                      <form
                        action="/cart/add"
                        method="post"
                        enctype="multipart/form-data"
                      >
                        <input type="hidden" name="id" value="42449023697135" />
                        <button
                          type="button"
                          className="butn butnPlus"
                          onClick={() => {
                            this.setState(
                              {
                                loading: true,
                              },
                              () => {
                                var login =
                                  localStorage.getItem("CustomerData");
                                var logindetails = JSON.parse(login);

                                var Order = localStorage.getItem("OrderData");
                                var Orderdetails = JSON.parse(Order);

                                // ===========with Login=========
                                PostApiCall.postRequest(
                                  {
                                    orderdate: moment().format("lll"),
                                    itemdetails: `
                                       [
                                       { 
                                           "Item":${13},
                                           "Quantity":${1}
                                       }
                                       ]
                                       `,
                                    status: "INCART",
                                    customerid:
                                      localStorage.getItem("CustomerData") ==
                                      null
                                        ? null
                                        : logindetails[0].fld_customerid,
                                    createdon: moment().format("lll"),
                                    updatedon: moment().format("lll"),
                                    orderid:
                                      localStorage.getItem("OrderData") == null
                                        ? 0
                                        : Orderdetails[0].order_id,
                                    updatedby:
                                      localStorage.getItem("CustomerData") ==
                                      null
                                        ? null
                                        : logindetails[0].fld_userid,
                                  },
                                  "AddToCartWebsite"
                                ).then((result) =>
                                  result.json().then((obj) => {
                                    if (
                                      result.status == 200 ||
                                      result.status == 201
                                    ) {
                                      Notiflix.Notify.Success(
                                        "Product added to Cart."
                                      );
                                      this.setState({
                                        loading: false,
                                      });
                                      // console.log(obj.data)
                                      if (
                                        localStorage.getItem("OrderData") ==
                                        null
                                      ) {
                                        //console.log('datastore')
                                        localStorage.setItem(
                                          "OrderData",
                                          JSON.stringify(obj.data)
                                        );
                                        this.getCartdetails();
                                      }
                                      this.getCartdetails();
                                    } else {
                                      Notiflix.Notify.failure(obj.data);
                                    }
                                  })
                                );
                              }
                            );
                          }}
                        >
                          {this.state.loading == true ? (
                            <div class="d-flex justify-content-center">
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          ) : (
                            "Add To Cart"
                          )}
                        </button>{" "}
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  class="wblookbook"
                  style={{ top: "41%", left: "55%" }}
                  onClick={() => {
                    this.setState({
                      card3Open: false,
                      card2Open: true,
                      card1Open: false,
                      card4Open: false,
                    });
                  }}
                >
                  <div class="wblookbtn" target="2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon icon-plus"
                      fill="none"
                      viewBox="0 0 10 10"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <div
                    class="wblbookcnt"
                    style={{
                      display: this.state.card2Open == true ? "block" : "none",
                    }}
                  >
                    <div class="wblookimg">
                      <a
                        href={`${
                          process.env.PUBLIC_URL
                        }/productdetail/${"World-map-backpack-color-in-&-learn".trim()}/${2}`}
                      >
                        <img
                          loading="lazy"
                          src={Card2Image}
                          alt="World-map-backpack-color-in-&-learn"
                          class="img-fluid mx-auto"
                        />
                      </a>
                    </div>
                    <div class="wblookdesc">
                      <h3>
                        <a
                          href={`${
                            process.env.PUBLIC_URL
                          }/productdetail/${"World-map-backpack-color-in-&-learn".trim()}/${2}`}
                        >
                          World map backpack - color in & learn
                        </a>
                      </h3>
                      <div class="wblookprice">
                        <span class="new-price">₹1530.00</span>

                        <span class="old-price"> ₹1800.00</span>
                      </div>
                      <form
                        action="/cart/add"
                        method="post"
                        enctype="multipart/form-data"
                      >
                        <input type="hidden" name="id" value="42449025007855" />
                        <button
                          type="button"
                          className="butn butnPlus"
                          onClick={() => {
                            this.setState(
                              {
                                loading: true,
                              },
                              () => {
                                var login =
                                  localStorage.getItem("CustomerData");
                                var logindetails = JSON.parse(login);

                                var Order = localStorage.getItem("OrderData");
                                var Orderdetails = JSON.parse(Order);

                                // ===========with Login=========
                                PostApiCall.postRequest(
                                  {
                                    orderdate: moment().format("lll"),
                                    itemdetails: `
                                       [
                                       { 
                                           "Item":${2},
                                           "Quantity":${1}
                                       }
                                       ]
                                       `,
                                    status: "INCART",
                                    customerid:
                                      localStorage.getItem("CustomerData") ==
                                      null
                                        ? null
                                        : logindetails[0].fld_customerid,
                                    createdon: moment().format("lll"),
                                    updatedon: moment().format("lll"),
                                    orderid:
                                      localStorage.getItem("OrderData") == null
                                        ? 0
                                        : Orderdetails[0].order_id,
                                    updatedby:
                                      localStorage.getItem("CustomerData") ==
                                      null
                                        ? null
                                        : logindetails[0].fld_userid,
                                  },
                                  "AddToCartWebsite"
                                ).then((result) =>
                                  result.json().then((obj) => {
                                    if (
                                      result.status == 200 ||
                                      result.status == 201
                                    ) {
                                      Notiflix.Notify.Success(
                                        "Product added to Cart."
                                      );
                                      this.setState({
                                        loading: false,
                                      });
                                      // console.log(obj.data)
                                      if (
                                        localStorage.getItem("OrderData") ==
                                        null
                                      ) {
                                        //console.log('datastore')
                                        localStorage.setItem(
                                          "OrderData",
                                          JSON.stringify(obj.data)
                                        );
                                        this.getCartdetails();
                                      }
                                      this.getCartdetails();
                                    } else {
                                      Notiflix.Notify.failure(obj.data);
                                    }
                                  })
                                );
                              }
                            );
                          }}
                        >
                          {this.state.loading == true ? (
                            <div class="d-flex justify-content-center">
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          ) : (
                            "Add To Cart"
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  class="wblookbook"
                  style={{ top: "17%", left: "79%" }}
                  onClick={() => {
                    this.setState({
                      card3Open: true,
                      card2Open: false,
                      card1Open: false,
                      card4Open: false,
                    });
                  }}
                >
                  <div class="wblookbtn" target="3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon icon-plus"
                      fill="none"
                      viewBox="0 0 10 10"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <span class="pulse"></span>

                  <div
                    class="wblbookcnt"
                    style={{
                      display: this.state.card3Open == true ? "block" : "none",
                    }}
                  >
                    <div class="wblookimg">
                      <a
                        href={`${
                          process.env.PUBLIC_URL
                        }/productdetail/${"Doodle-Designer-Accessory-Bag".trim()}/${5}`}
                      >
                        <img
                          loading="lazy"
                          src={Card3Image}
                          alt="Doodle-Designer-Accessory-Bag"
                          class="img-fluid mx-auto"
                        />
                      </a>
                    </div>
                    <div class="wblookdesc">
                      <h3>
                        <a
                          href={`${
                            process.env.PUBLIC_URL
                          }/productdetail/${"Designerbag1".trim()}/${5}`}
                        >
                          Doodle Designer Accessory Bag
                        </a>
                      </h3>
                      <div class="wblookprice">
                        <span class="new-price">₹999.00</span>

                        <span class="old-price"> ₹1234.00</span>
                        <span class="old-price hide"></span>
                      </div>
                      <form
                        action="/cart/add"
                        method="post"
                        enctype="multipart/form-data"
                      >
                        <input type="hidden" name="id" value="42449023009007" />
                        <button
                          type="button"
                          className="butn butnPlus"
                          onClick={() => {
                            this.setState(
                              {
                                loading: true,
                              },
                              () => {
                                var login =
                                  localStorage.getItem("CustomerData");
                                var logindetails = JSON.parse(login);

                                var Order = localStorage.getItem("OrderData");
                                var Orderdetails = JSON.parse(Order);

                                // ===========with Login=========
                                PostApiCall.postRequest(
                                  {
                                    orderdate: moment().format("lll"),
                                    itemdetails: `
                                       [
                                       { 
                                           "Item":${5},
                                           "Quantity":${1}
                                       }
                                       ]
                                       `,
                                    status: "INCART",
                                    customerid:
                                      localStorage.getItem("CustomerData") ==
                                      null
                                        ? null
                                        : logindetails[0].fld_customerid,
                                    createdon: moment().format("lll"),
                                    updatedon: moment().format("lll"),
                                    orderid:
                                      localStorage.getItem("OrderData") == null
                                        ? 0
                                        : Orderdetails[0].order_id,
                                    updatedby:
                                      localStorage.getItem("CustomerData") ==
                                      null
                                        ? null
                                        : logindetails[0].fld_userid,
                                  },
                                  "AddToCartWebsite"
                                ).then((result) =>
                                  result.json().then((obj) => {
                                    if (
                                      result.status == 200 ||
                                      result.status == 201
                                    ) {
                                      Notiflix.Notify.Success(
                                        "Product added to Cart."
                                      );
                                      this.setState({
                                        loading: false,
                                      });
                                      // console.log(obj.data)
                                      if (
                                        localStorage.getItem("OrderData") ==
                                        null
                                      ) {
                                        //console.log('datastore')
                                        localStorage.setItem(
                                          "OrderData",
                                          JSON.stringify(obj.data)
                                        );
                                        this.getCartdetails();
                                      }
                                      this.getCartdetails();
                                    } else {
                                      Notiflix.Notify.failure(obj.data);
                                    }
                                  })
                                );
                              }
                            );
                          }}
                        >
                          {this.state.loading == true ? (
                            <div class="d-flex justify-content-center">
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          ) : (
                            "Add To Cart"
                          )}
                        </button>{" "}
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  class="wblookbook"
                  style={{ top: "16%", left: "27%" }}
                  onClick={() => {
                    this.setState({
                      card3Open: false,
                      card2Open: false,
                      card1Open: false,
                      card4Open: true,
                    });
                  }}
                >
                  <div class="wblookbtn" target="3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon icon-plus"
                      fill="none"
                      viewBox="0 0 10 10"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <span class="pulse"></span>

                  <div
                    class="wblbookcnt"
                    style={{
                      display: this.state.card4Open == true ? "block" : "none",
                    }}
                  >
                    <div class="wblookimg">
                      <a
                        href={`${
                          process.env.PUBLIC_URL
                        }/productdetail/${"Butterfly-Pencil-Case".trim()}/${10}`}
                      >
                        <img
                          loading="lazy"
                          src={Card4Image}
                          alt="Butterfly Pencil Case"
                          class="img-fluid mx-auto"
                        />
                      </a>
                    </div>
                    <div class="wblookdesc">
                      <h3>
                        <a
                          href={`${
                            process.env.PUBLIC_URL
                          }/productdetail/${"Butterfly-Pencil-Case".trim()}/${10}`}
                        >
                          Butterfly Pencil Case
                        </a>
                      </h3>
                      <div class="wblookprice">
                        <span class="new-price">₹1360.00</span>

                        <span class="old-price"> ₹1600.00</span>
                        <span class="old-price hide"></span>
                      </div>
                      <form
                        action="/cart/add"
                        method="post"
                        enctype="multipart/form-data"
                      >
                        <input type="hidden" name="id" value="42449023009007" />
                        <button
                          type="button"
                          className="butn butnPlus"
                          onClick={() => {
                            this.setState(
                              {
                                loading: true,
                              },
                              () => {
                                var login =
                                  localStorage.getItem("CustomerData");
                                var logindetails = JSON.parse(login);

                                var Order = localStorage.getItem("OrderData");
                                var Orderdetails = JSON.parse(Order);

                                // ===========with Login=========
                                PostApiCall.postRequest(
                                  {
                                    orderdate: moment().format("lll"),
                                    itemdetails: `
                                       [
                                       { 
                                           "Item":${10},
                                           "Quantity":${1}
                                       }
                                       ]
                                       `,
                                    status: "INCART",
                                    customerid:
                                      localStorage.getItem("CustomerData") ==
                                      null
                                        ? null
                                        : logindetails[0].fld_customerid,
                                    createdon: moment().format("lll"),
                                    updatedon: moment().format("lll"),
                                    orderid:
                                      localStorage.getItem("OrderData") == null
                                        ? 0
                                        : Orderdetails[0].order_id,
                                    updatedby:
                                      localStorage.getItem("CustomerData") ==
                                      null
                                        ? null
                                        : logindetails[0].fld_userid,
                                  },
                                  "AddToCartWebsite"
                                ).then((result) =>
                                  result.json().then((obj) => {
                                    if (
                                      result.status == 200 ||
                                      result.status == 201
                                    ) {
                                      Notiflix.Notify.Success(
                                        "Product added to Cart."
                                      );
                                      this.setState({
                                        loading: false,
                                      });
                                      // console.log(obj.data)
                                      if (
                                        localStorage.getItem("OrderData") ==
                                        null
                                      ) {
                                        //console.log('datastore')
                                        localStorage.setItem(
                                          "OrderData",
                                          JSON.stringify(obj.data)
                                        );
                                        this.getCartdetails();
                                      }
                                      this.getCartdetails();
                                    } else {
                                      Notiflix.Notify.failure(obj.data);
                                    }
                                  })
                                );
                              }
                            );
                          }}
                        >
                          {this.state.loading == true ? (
                            <div class="d-flex justify-content-center">
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          ) : (
                            "Add To Cart"
                          )}
                        </button>{" "}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ImageBannerProducts;
