/* eslint-disable no-loop-func */
import React from "react";
import Notiflix from "notiflix"

import FailPayment from '../../Images/Payment/paymentfail.svg';

class PaymentFail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {




        };
    }

    componentDidMount() {

        Notiflix.Loading.Init({
            svgColor: "#0f3d74",
        });


    }

    render() {
        return (
            <div>
                <div class="container">

                    <div class="row marginbtm-240">



                        <div class="col-md-12">

                            <div class="privacy-box">
                                <div class="d-flex justify-content-center">
                                    <img src={FailPayment} class="paymentFail img-fluid" alt="payment failed" height="550px" width="550px"></img>

                                </div>
                                <div class="center-block mt-3" style={{ display: "grid", justifyContent: "center" }}>
                                    <a href="/shipping" class="butn mb-3"> Try Paying Again</a>
                                    <a href="/" class="butn">Go to Home Page</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentFail;
