import React, { Component } from "react";
import PostApiCall from "../../Helper/Api";
import ManageCustomerAPI from "../ClinetDetailsApi/ManageCustomerAPI";
import { ToWords } from "to-words";
import Notiflix from "notiflix";
import Parser from "html-react-parser";
import { store } from "../../context/store";
import GetApiCall from "../../Helper/GetApi";

class Shipping extends Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      ShippingAddress: [],
      BillingAddress: [],
      CartData: [],
      SummaryData: [],
      OfferData: [],

      NumRegex: /^0|[0-9]\d*$/,
      MobileRegex: /^[0-9]*$/,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      SpecialRegex: /[-!$%^&*()_+|~=`"{}\[\]:\/;<>?,.@#]/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,

      CustomerLoginData: [],

      BankOffer: [],
      MainOrder: [],
      TxnId: null,

      MerKey: "jscI4b",
      MerSalt: "v62h7IBoLPzF2XaAdncosoaD8leGtlL7",
      CustomerHash: "",
      AmountInWords: null,
      MainOrders: [],
      clientPhoneNumber: null,
      clientEmail: null,
      clientAddress: null,
      clientGST: null,
      clientWesite: null,
      cartData: [],
      Subtotal: 0,
      OfferDiscountAmount: 0,
      CouponDiscountAmount: 0,
      ShippingCharges: 0,
      GST: 0,
      GrandTotal: 0,
      GSTonShipping: null,
      StoreOpen: ""
    };
  }

  componentDidMount() {

    GetApiCall.getRequest("getstorestatus").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          StoreOpen: obj.data[0].fld_store_status
        })

      })
    );

    ManageCustomerAPI.postRequest(
      {
        whereClause: `where fld_company_id = ${1}`,
      },
      "API/GetClientData"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          console.log(obj.data);
          this.setState({
            clientLogo: obj.data[0].fld_storelogo,
            clientName: obj.data[0].fld_companyname,
            clientPhoneNumber: obj.data[0].fld_mobilenumber,
            clientEmail: obj.data[0].fld_email,
            clientAddress: obj.data[0].fld_address,
            clientGST: obj.data[0].fld_gst,
            clientWesite: obj.data[0].fld_website,
          });

          //console.log(obj)
        }
      })
    );
    const store = this.context;

    var OrderDetails = JSON.parse(localStorage.getItem("OrderDetails"));

    if (OrderDetails) {
      this.setState({
        TxnId: OrderDetails[0].transaction_id,
      });
      //console.log(OrderDetails[0].transaction_id)

      PostApiCall.postRequest(
        {
          whereClause: `where fld_order_id=${OrderDetails[0].order_id}`,
        },
        "GetWebsiteOrderDetails"
      ).then((results1) =>
        // const objs = JSON.parse(result._bodyText)
        results1.json().then((obj) => {
          if (results1.status == 200 || results1.status == 201) {
            console.log(obj.data);
            this.setState({
              MainOrder: obj.data,
              MainOrders: obj.data[0],
            });

            const payu = require("payu-sdk")({
              key: this.state.MerKey,
              salt: this.state.MerSalt, // should be on server side only
            });
            const hash = payu.hasher.generateHash({
              txnid: this.state.TxnId,
              amount: parseFloat(obj.data[0].Total_Inclusive_Tax).toFixed(2),
              productinfo: "PinkParrot Products",
              firstname: obj.data[0].CustomerName,
              email: obj.data[0].fld_email,
              phone: obj.data[0].fld_mobile,
            });

            this.setState({ CustomerHash: hash });

            this.state.MainOrder.map((data, i) => {
              if (i === 0) {
                const toWords = new ToWords();
                let words = toWords.convert(data.Total_Inclusive_Tax);
                this.setState({
                  AmountInWords: words,
                });
              }
            });
          }
        })
      );
    }

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : OrderDetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    ).then((result) => {
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          console.log(obj.data);
          this.setState(
            {
              cartData: obj.data,
              CartdID: obj.data[0].fld_cart_id,
              cartID: obj.data[0].fld_cart_id,
              CouponId: obj.data[0].fld_offerid,
              CouponDataId: obj.data[0].fld_coupon_id,
              CouponCode: obj.data[0].fld_code,
              Subtotal: obj.data[0].total_mrp,
              OfferDiscountAmount: obj.data[0].fld_cart_discount_amount,
              CouponDiscountAmount:
                obj.data[0].fld_coupon_discount == null ||
                  obj.data[0].fld_coupon_discount == 0
                  ? 0.0
                  : obj.data[0].fld_coupon_discount,
              ShippingCharges:
                obj.data[0].fld_shipping_charge == null
                  ? 0
                  : obj.data[0].fld_shipping_charge,
              GST: obj.data[0].fld_total_gst,
              GrandTotal: obj.data[0].fld_cart_amount,
              GSTonShipping: obj.data[0].fld_shipping_charge_gst == null
                ? 0.00
                : obj.data[0].fld_shipping_charge_gst,
            }
          );
        }
      });
    });
  }

  SendMailers() { }

  render() {
    return (
      <>
        <div className="content-shipping">
          <div className="wrap">
            <div className="main">
              <header class="main__header" role="banner">
                {/* <a
                  class="logo logo--left"
                  href="#"
                >
                  <img
                    alt="toys"
                    class="logo__image logo__image--medium"
                    src="//cdn.shopify.com/s/files/1/0623/7578/8783/files/logo.png?1321"
                  />
                </a> */}

                <h1 class="visually-hidden">Information</h1>
                <nav aria-label="Breadcrumb">
                  <ol class="breadcrumb " role="list">
                    <li class="breadcrumb__item breadcrumb__item--completed">
                      <a class="breadcrumb__link" href="/cart">
                        Cart
                      </a>
                      <svg
                        class="icon-svg icon-svg--color-adaptive-light icon-svg--size-10 breadcrumb__chevron-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        zoomAndPan="magnify"
                        viewBox="0 0 37.5 37.499999"
                        height="50"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                      >
                        <defs>
                          <clipPath id="id1">
                            <path
                              d="M 11.707031 4.90625 L 25.957031 4.90625 L 25.957031 32.65625 L 11.707031 32.65625 Z M 11.707031 4.90625 "
                              clip-rule="nonzero"
                            />
                          </clipPath>
                        </defs>
                        <g clip-path="url(#id1)">
                          <path
                            fill="rgb(45.098877%, 45.098877%, 45.098877%)"
                            d="M 25.882812 19.09375 C 25.890625 19.078125 25.902344 19.0625 25.910156 19.042969 C 25.917969 19.023438 25.921875 19.003906 25.929688 18.984375 C 25.933594 18.964844 25.941406 18.949219 25.945312 18.929688 C 25.949219 18.910156 25.949219 18.890625 25.949219 18.871094 C 25.953125 18.851562 25.957031 18.832031 25.957031 18.8125 C 25.957031 18.792969 25.953125 18.773438 25.949219 18.753906 C 25.949219 18.734375 25.949219 18.714844 25.945312 18.695312 C 25.941406 18.675781 25.933594 18.660156 25.929688 18.640625 C 25.921875 18.621094 25.917969 18.601562 25.910156 18.582031 C 25.902344 18.5625 25.890625 18.546875 25.882812 18.53125 C 25.875 18.511719 25.863281 18.492188 25.851562 18.476562 C 25.84375 18.457031 25.828125 18.441406 25.816406 18.425781 C 25.804688 18.414062 25.792969 18.398438 25.78125 18.386719 L 12.835938 5.179688 C 12.597656 4.9375 12.203125 4.933594 11.960938 5.167969 C 11.714844 5.40625 11.707031 5.792969 11.945312 6.035156 L 24.472656 18.8125 L 11.945312 31.589844 C 11.707031 31.832031 11.714844 32.21875 11.960938 32.457031 C 12.078125 32.574219 12.234375 32.628906 12.390625 32.628906 C 12.554688 32.628906 12.714844 32.566406 12.835938 32.445312 L 25.78125 19.242188 C 25.792969 19.226562 25.804688 19.210938 25.816406 19.199219 C 25.828125 19.183594 25.84375 19.167969 25.851562 19.148438 C 25.863281 19.132812 25.875 19.113281 25.882812 19.09375 Z M 25.882812 19.09375 "
                            fill-opacity="1"
                            fill-rule="nonzero"
                          />
                        </g>
                      </svg>{" "}
                    </li>

                    <li
                      class="breadcrumb__item breadcrumb__item"
                      aria-current="step"
                    >
                      <span class="breadcrumb__text">Information</span>
                      <svg
                        class="icon-svg icon-svg--color-adaptive-light icon-svg--size-10 breadcrumb__chevron-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        zoomAndPan="magnify"
                        viewBox="0 0 37.5 37.499999"
                        height="50"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                      >
                        <defs>
                          <clipPath id="id1">
                            <path
                              d="M 11.707031 4.90625 L 25.957031 4.90625 L 25.957031 32.65625 L 11.707031 32.65625 Z M 11.707031 4.90625 "
                              clip-rule="nonzero"
                            />
                          </clipPath>
                        </defs>
                        <g clip-path="url(#id1)">
                          <path
                            fill="rgb(45.098877%, 45.098877%, 45.098877%)"
                            d="M 25.882812 19.09375 C 25.890625 19.078125 25.902344 19.0625 25.910156 19.042969 C 25.917969 19.023438 25.921875 19.003906 25.929688 18.984375 C 25.933594 18.964844 25.941406 18.949219 25.945312 18.929688 C 25.949219 18.910156 25.949219 18.890625 25.949219 18.871094 C 25.953125 18.851562 25.957031 18.832031 25.957031 18.8125 C 25.957031 18.792969 25.953125 18.773438 25.949219 18.753906 C 25.949219 18.734375 25.949219 18.714844 25.945312 18.695312 C 25.941406 18.675781 25.933594 18.660156 25.929688 18.640625 C 25.921875 18.621094 25.917969 18.601562 25.910156 18.582031 C 25.902344 18.5625 25.890625 18.546875 25.882812 18.53125 C 25.875 18.511719 25.863281 18.492188 25.851562 18.476562 C 25.84375 18.457031 25.828125 18.441406 25.816406 18.425781 C 25.804688 18.414062 25.792969 18.398438 25.78125 18.386719 L 12.835938 5.179688 C 12.597656 4.9375 12.203125 4.933594 11.960938 5.167969 C 11.714844 5.40625 11.707031 5.792969 11.945312 6.035156 L 24.472656 18.8125 L 11.945312 31.589844 C 11.707031 31.832031 11.714844 32.21875 11.960938 32.457031 C 12.078125 32.574219 12.234375 32.628906 12.390625 32.628906 C 12.554688 32.628906 12.714844 32.566406 12.835938 32.445312 L 25.78125 19.242188 C 25.792969 19.226562 25.804688 19.210938 25.816406 19.199219 C 25.828125 19.183594 25.84375 19.167969 25.851562 19.148438 C 25.863281 19.132812 25.875 19.113281 25.882812 19.09375 Z M 25.882812 19.09375 "
                            fill-opacity="1"
                            fill-rule="nonzero"
                          />
                        </g>
                      </svg>{" "}
                    </li>
                    <li class="breadcrumb__item breadcrumb__item--completed">
                      {/* <a class="breadcrumb__link"> */}
                      Shipping & Payment
                      {/* </a> */}
                      {/* <svg
                        class="icon-svg icon-svg--color-adaptive-light icon-svg--size-10 breadcrumb__chevron-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        zoomAndPan="magnify"
                        viewBox="0 0 37.5 37.499999"
                        height="50"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                      >
                        <defs>
                          <clipPath id="id1">
                            <path
                              d="M 11.707031 4.90625 L 25.957031 4.90625 L 25.957031 32.65625 L 11.707031 32.65625 Z M 11.707031 4.90625 "
                              clip-rule="nonzero"
                            />
                          </clipPath>
                        </defs>
                        <g clip-path="url(#id1)">
                          <path
                            fill="rgb(45.098877%, 45.098877%, 45.098877%)"
                            d="M 25.882812 19.09375 C 25.890625 19.078125 25.902344 19.0625 25.910156 19.042969 C 25.917969 19.023438 25.921875 19.003906 25.929688 18.984375 C 25.933594 18.964844 25.941406 18.949219 25.945312 18.929688 C 25.949219 18.910156 25.949219 18.890625 25.949219 18.871094 C 25.953125 18.851562 25.957031 18.832031 25.957031 18.8125 C 25.957031 18.792969 25.953125 18.773438 25.949219 18.753906 C 25.949219 18.734375 25.949219 18.714844 25.945312 18.695312 C 25.941406 18.675781 25.933594 18.660156 25.929688 18.640625 C 25.921875 18.621094 25.917969 18.601562 25.910156 18.582031 C 25.902344 18.5625 25.890625 18.546875 25.882812 18.53125 C 25.875 18.511719 25.863281 18.492188 25.851562 18.476562 C 25.84375 18.457031 25.828125 18.441406 25.816406 18.425781 C 25.804688 18.414062 25.792969 18.398438 25.78125 18.386719 L 12.835938 5.179688 C 12.597656 4.9375 12.203125 4.933594 11.960938 5.167969 C 11.714844 5.40625 11.707031 5.792969 11.945312 6.035156 L 24.472656 18.8125 L 11.945312 31.589844 C 11.707031 31.832031 11.714844 32.21875 11.960938 32.457031 C 12.078125 32.574219 12.234375 32.628906 12.390625 32.628906 C 12.554688 32.628906 12.714844 32.566406 12.835938 32.445312 L 25.78125 19.242188 C 25.792969 19.226562 25.804688 19.210938 25.816406 19.199219 C 25.828125 19.183594 25.84375 19.167969 25.851562 19.148438 C 25.863281 19.132812 25.875 19.113281 25.882812 19.09375 Z M 25.882812 19.09375 "
                            fill-opacity="1"
                            fill-rule="nonzero"
                          />
                        </g>
                      </svg>{" "} */}
                    </li>
                  </ol>
                </nav>
                <div class="shown-if-js" data-alternative-payments=""></div>
              </header>
              <main className="main__content" role="main">
                <div
                  className="step"
                  data-step="contact_information"
                  data-last-step="false"
                >
                  <form
                    data-customer-information-form="true"
                    class="edit_checkout animate-floating-labels"
                  >
                    <div className="step__sections">
                      <div class="section">
                        {this.state.MainOrder.map((data, index) => {
                          if (index == 0) {
                            return (
                              <div class="content-box content-box-tab">
                                <div
                                  role="table"
                                  class="content-box__row content-box__row--tight-spacing-vertical"
                                >
                                  <div role="row" class="review-block">
                                    <div class="review-block__inner">
                                      <div
                                        role="rowheader"
                                        class="review-block__label"
                                      >
                                        Contact
                                      </div>
                                      <div
                                        role="cell"
                                        class="review-block__content"
                                      >
                                        <bdo dir="ltr">{data.fld_mobile}</bdo>
                                      </div>
                                    </div>
                                  </div>

                                  <div role="row" class="review-block">
                                    <div class="review-block__inner">
                                      <div
                                        role="rowheader"
                                        class="review-block__label"
                                      >
                                        Ship to
                                      </div>
                                      <div
                                        role="cell"
                                        class="review-block__content"
                                      >
                                        <address class="address address--tight">
                                          {data.fld_shippingaddress}{" "}
                                          {data.fld_shippingcity}-{" "}
                                          {data.fld_shippingPincode},{" "}
                                          {data.fld_state},{" "}
                                          {data.fld_shippingcountry},{" "}
                                          {data.fld_shippinglandmark}
                                          <address></address>
                                        </address>
                                      </div>
                                    </div>
                                    <div role="cell" class="review-block__link">
                                      <a
                                        class="link--small"
                                        href="/checkout"
                                        onClick={() => {
                                          window.location.href = "/checkout";
                                        }}
                                      >
                                        <span aria-hidden="true">Change</span>
                                      </a>{" "}
                                    </div>
                                  </div>
                                  <div role="row" class="review-block">
                                    <div class="review-block__inner">
                                      <div
                                        role="rowheader"
                                        class="review-block__label"
                                      >
                                        Payment Method
                                      </div>
                                      <div
                                        role="cell"
                                        class="review-block__content"
                                      >
                                        <address class="address address--tight">
                                          {data.Payment_Mode == "COD"
                                            ? "Cash on Delivery"
                                            : data.Payment_Mode}
                                          <address></address>
                                        </address>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                      <div class="section section--shipping-method continuetopayment-desktop">
                        <div className="shiping-flex">
                          {
                            this.state.StoreOpen == "Close" ?
                              <div className="store-close">
                                <p className="m-0">We are closed at the moment</p>
                              </div>
                              :

                              <div style={{ width: "160px" }}>
                                {this.state.MainOrders.Payment_Mode != "COD" ? (
                                  <form
                                    action="https://secure.payu.in/_payment"
                                    id="payment_form"
                                    method="Post"
                                  >
                                    <div style={{ display: "none" }}>
                                      <input
                                        type="hidden"
                                        id="udf5"
                                        name="udf5"
                                        value=""
                                      />

                                      <input
                                        type="hidden"
                                        id="surl"
                                        name="surl"
                                        value="https://manage.bechofy.in/pinkparrotkids-api/Response"
                                      />
                                      <input
                                        type="hidden"
                                        id="furl"
                                        name="furl"
                                        value="https://manage.bechofy.in/pinkparrotkids-api/Response"
                                      />
                                      <input
                                        type="hidden"
                                        id="curl"
                                        name="curl"
                                        value="https://manage.bechofy.in/pinkparrotkids-api/Response"
                                      />
                                      <input
                                        type="hidden"
                                        id="key"
                                        name="key"
                                        value={this.state.MerKey}
                                      />
                                      <div class="dv">
                                        <span class="text">
                                          <label>Transaction/Order ID:</label>
                                        </span>
                                        <span>
                                          <input
                                            type="text"
                                            id="txnid"
                                            name="txnid"
                                            placeholder="Transaction ID"
                                            value={this.state.TxnId}
                                          />
                                        </span>
                                      </div>

                                      <div class="dv">
                                        <span class="text">
                                          <label>Amount:</label>
                                        </span>
                                        <span>
                                          <input
                                            type="text"
                                            id="amount"
                                            name="amount"
                                            placeholder="Amount"
                                            value={parseFloat(
                                              this.state.MainOrders
                                                .Total_Inclusive_Tax
                                            ).toFixed(2)}
                                          />
                                        </span>
                                      </div>

                                      <div class="dv">
                                        <span class="text">
                                          <label>Product Info:</label>
                                        </span>
                                        <span>
                                          <input
                                            type="text"
                                            id="productinfo"
                                            name="productinfo"
                                            placeholder="Product Info"
                                            value={"PinkParrot Products"}
                                          />
                                        </span>
                                      </div>

                                      <div class="dv">
                                        <span class="text">
                                          <label>First Name:</label>
                                        </span>
                                        <span>
                                          <input
                                            type="text"
                                            id="firstname"
                                            name="firstname"
                                            placeholder="First Name"
                                            value={
                                              this.state.MainOrders.CustomerName
                                            }
                                          />
                                        </span>
                                      </div>

                                      <div class="dv">
                                        <span class="text">
                                          <label>Last Name:</label>
                                        </span>
                                        <span>
                                          <input
                                            type="text"
                                            id="Lastname"
                                            name="Lastname"
                                            placeholder="Last Name"
                                            value=""
                                          />
                                        </span>
                                      </div>

                                      <div class="dv">
                                        <span class="text">
                                          <label>Email ID:</label>
                                        </span>
                                        <span>
                                          <input
                                            type="text"
                                            id="email"
                                            name="email"
                                            placeholder="Email ID"
                                            value={this.state.MainOrders.fld_email}
                                          />
                                        </span>
                                      </div>

                                      <div class="dv">
                                        <span class="text">
                                          <label>Mobile/Cell Number:</label>
                                        </span>
                                        <span>
                                          <input
                                            type="text"
                                            id="phone"
                                            name="phone"
                                            placeholder="Mobile/Cell Number"
                                            value={this.state.MainOrders.fld_mobile}
                                          />
                                        </span>
                                      </div>

                                      <div class="dv">
                                        <span class="text">
                                          <label>Hash:</label>
                                        </span>
                                        <span>
                                          <input
                                            type="text"
                                            id="hash"
                                            name="hash"
                                            placeholder="Hash"
                                            value={this.state.CustomerHash}
                                          />
                                        </span>
                                      </div>
                                      <div class="dv">
                                        <span class="text">
                                          <label>Service Provider:</label>
                                        </span>
                                        <span>
                                          <input
                                            type="text"
                                            id="service_provider"
                                            name="service_provider"
                                            placeholder="service_provider"
                                            value={"payu_paisa"}
                                          />
                                        </span>
                                      </div>
                                    </div>

                                    <button class="butn">
                                      Continue to Payment
                                    </button>
                                  </form>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      localStorage.removeItem("OrderData");
                                      window.location.href = `/paymentprocess/${this.state.MainOrders.fld_payment_trx_num}`;
                                    }}
                                    class="butn"
                                  >
                                    Place Order
                                  </button>
                                )}
                              </div>
                          }
                          <a class="returntocartbtnphn step__footer__previous-link return_to_previous_page"
                            href="/checkout">
                            <svg
                              focusable="false"
                              aria-hidden="true"
                              class="icon-svg icon-svg--color-accent icon-svg--size-10 previous-link__icon"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 10 10"
                            >
                              <path d="M8 1L7 0 3 4 2 5l1 1 4 4 1-1-4-4"></path>
                            </svg>
                            <span>Return to checkout</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </main>
            </div>
            <aside class="sidebar">
              <div class="sidebar__header">
                <a class="logo logo--left" href="#">
                  <img
                    alt="toys"
                    class="logo__image logo__image--medium"
                    src="//cdn.shopify.com/s/files/1/0623/7578/8783/files/logo.png?1321"
                  />
                </a>

                <h1 class="visually-hidden">Information</h1>
              </div>
              <div class="sidebar__content">
                <div
                  id="order-summary"
                  class="order-summary order-summary--is-collapsed"
                >
                  <h2 class="visually-hidden-if-js orderphntopbottom">Order summary</h2>
                  <div class="order-summary__sections">
                    <div class="order-summary__section order-summary__section--product-list">
                      <div class="order-summary__section__content">
                        <table class="product-table">
                          <caption class="visually-hidden">
                            Shopping cart
                          </caption>
                          <thead class="product-table__header">
                            <tr>
                              <th scope="col">
                                <span class="visually-hidden">
                                  Product image
                                </span>
                              </th>
                              <th scope="col">
                                <span class="visually-hidden">Description</span>
                              </th>
                              <th scope="col">
                                <span class="visually-hidden">Quantity</span>
                              </th>
                              <th scope="col">
                                <span class="visually-hidden">Price</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody data-order-summary-section="line-items">
                            {this.state.cartData.map((data, index) => {
                              return (
                                <tr class="product">
                                  <td class="product__image">
                                    <div class="product-thumbnail ">
                                      <div class="product-thumbnail__wrapper">
                                        <img
                                          alt="Skylofts Rabbit LED Night Lamp Plug"
                                          class="product-thumbnail__image"
                                          src={data.VariantImage}
                                        />
                                      </div>
                                      <span
                                        class="product-thumbnail__quantity"
                                        aria-hidden="true"
                                      >
                                        {data.fld_quantity}
                                      </span>
                                    </div>
                                  </td>
                                  <th class="product__description" scope="row">
                                    <span class="product__description__name order-summary__emphasis">
                                      {data.fld_variantname}
                                    </span>
                                    <span class="product__description__variant order-summary__small-text"></span>
                                  </th>
                                  {
                                    data.fld_mrp == data.fld_website_discount_price
                                      ?
                                      <td class="product__price" >
                                        <span class="order-summary__emphasis skeleton-while-loading checkoutshowitemfontphn">
                                          ₹
                                          {parseFloat(
                                            data.fld_website_discount_price
                                          ).toFixed(2)}
                                        </span>
                                      </td>
                                      :
                                      <td class="product__price" >
                                        {/* checkoutshowitemfontphn add by laxmi-----------------for phn viewsize */}
                                        <del class="order-summary__small-text skeleton-while-loading--sm checkoutshowitemfontphn" >
                                          ₹ {parseFloat(data.fld_mrp).toFixed(2)}
                                        </del>
                                        <br />

                                        {/* checkoutshowitemfontphn add by laxmi-----------------for phn viewsize */}
                                        <span class="order-summary__emphasis skeleton-while-loading checkoutshowitemfontphn">
                                          ₹
                                          {parseFloat(
                                            data.fld_website_discount_price
                                          ).toFixed(2)}
                                        </span>
                                      </td>
                                  }
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {/* <div class="order-summary__scroll-indicator" aria-hidden="true" tabindex="-1" id="order-summary__scroll-indicator">
                                                    Scroll for more items
                                                    <svg aria-hidden="true" focusable="false" class="icon-svg icon-svg--size-12"> </svg>
                                                </div> */}
                      </div>
                    </div>
                    <div
                      class="order-summary__section order-summary__section--total-lines"
                      data-order-summary-section="payment-lines"
                    >
                      <table class="total-line-table">
                        <caption class="visually-hidden">Cost summary</caption>
                        <thead>
                          <tr>
                            <th scope="col">
                              <span class="visually-hidden">Description</span>
                            </th>
                            <th scope="col">
                              <span class="visually-hidden">Price</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="total-line-table__tbody">
                          <tr class="total-line total-line--subtotal">
                            <th class="total-line__name" scope="row">
                              Total MRP (Inclusive GST)
                            </th>
                            <td class="total-line__price">
                              <span
                                class="order-summary__emphasis skeleton-while-loading"
                                data-checkout-subtotal-price-target="68000"
                              >
                                ₹ {parseFloat(this.state.Subtotal).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr class="total-line total-line--shipping">
                            <th class="total-line__name" scope="row">
                              <span>Discount on MRP</span>
                            </th>
                            <td class="total-line__price">
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-total-shipping-target="1961"
                              >
                                ₹ {parseFloat(this.state.OfferDiscountAmount).toFixed(2)}
                              </span>
                            </td>
                          </tr>

                          <tr class="total-line total-line--shipping">
                            <th class="total-line__name" scope="row">
                              <span>Coupon Discount</span>
                            </th>
                            <td class="total-line__price">
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-total-shipping-target="1961"
                              >
                                ₹ {parseFloat(this.state.CouponDiscountAmount).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr class="total-line total-line--shipping">
                            <th class="total-line__name" scope="row">
                              <span>GST</span>
                            </th>
                            <td class="total-line__price">
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-total-shipping-target="1961"
                              >
                                ₹ {parseFloat(this.state.GST).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr class="total-line total-line--shipping">
                            <th class="total-line__name" scope="row">
                              <span>Convenience Fee</span>
                            </th>
                            <td class="total-line__price">
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-total-shipping-target="1961"
                              >
                                ₹ {parseFloat(this.state.ShippingCharges).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr class="total-line total-line--shipping">
                            <th class="total-line__name" scope="row">
                              <span>GST on Shipping</span>
                            </th>
                            <td class="total-line__price">
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-total-shipping-target="1961"
                              >
                                ₹ {parseFloat(this.state.GSTonShipping).toFixed(2)}
                              </span>
                            </td>
                          </tr>

                          <tr
                            class="total-line total-line--shipping"
                            style={{
                              borderBottom: "1px solid rgba(175,175,175,0.34)",
                            }}
                          >
                            <th class="total-line__name_plain" scope="row">
                              <span></span>
                            </th>
                            <td class="total-line__price_plain">
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-total-shipping-target="1961"
                              ></span>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot class="total-line-table__footer">
                          <tr class="total-line">
                            <th
                              class="total-line__name payment-due-label"
                              scope="row"
                              style={{ paddingTop: "1.5rem !important" }}
                            >
                              <span class="payment-due-label__total">
                                Total (Inclusive of all Taxes)
                              </span>
                              {/* <span class="payment-due-label__taxes order-summary__small-text hidden" data-checkout-taxes="">
                                                                Including <span data-checkout-total-taxes-target="0">$0.00</span> in taxes
                                                            </span> */}
                            </th>
                            <td
                              class="total-line__price payment-due"
                              data-presentment-currency="USD"
                            >
                              <span
                                class="skeleton-while-loading order-summary__emphasis"
                                data-checkout-payment-due-target="69961"
                              >
                                ₹ {parseFloat(this.state.GrandTotal).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div class="section section--shipping-method continuetopayment-tab">
                      <div className="shiping-flex  shippinphnblockview">
                        <div style={{ width: "160px" }}>
                          {this.state.MainOrders.Payment_Mode != "COD" ? (
                            <form
                              action="https://secure.payu.in/_payment"
                              id="payment_form"
                              method="Post"
                            >
                              <div style={{ display: "none" }}>
                                <input
                                  type="hidden"
                                  id="udf5"
                                  name="udf5"
                                  value=""
                                />

                                <input
                                  type="hidden"
                                  id="surl"
                                  name="surl"
                                  value="https://manage.bechofy.in/pinkparrotkids-api/Response"
                                />
                                <input
                                  type="hidden"
                                  id="furl"
                                  name="furl"
                                  value="https://manage.bechofy.in/pinkparrotkids-api/Response"
                                />
                                <input
                                  type="hidden"
                                  id="curl"
                                  name="curl"
                                  value="https://manage.bechofy.in/pinkparrotkids-api/Response"
                                />
                                <input
                                  type="hidden"
                                  id="key"
                                  name="key"
                                  value={this.state.MerKey}
                                />
                                <div class="dv">
                                  <span class="text">
                                    <label>Transaction/Order ID:</label>
                                  </span>
                                  <span>
                                    <input
                                      type="text"
                                      id="txnid"
                                      name="txnid"
                                      placeholder="Transaction ID"
                                      value={this.state.TxnId}
                                    />
                                  </span>
                                </div>

                                <div class="dv">
                                  <span class="text">
                                    <label>Amount:</label>
                                  </span>
                                  <span>
                                    <input
                                      type="text"
                                      id="amount"
                                      name="amount"
                                      placeholder="Amount"
                                      value={parseFloat(
                                        this.state.MainOrders
                                          .Total_Inclusive_Tax
                                      ).toFixed(2)}
                                    />
                                  </span>
                                </div>

                                <div class="dv">
                                  <span class="text">
                                    <label>Product Info:</label>
                                  </span>
                                  <span>
                                    <input
                                      type="text"
                                      id="productinfo"
                                      name="productinfo"
                                      placeholder="Product Info"
                                      value={"PinkParrot Products"}
                                    />
                                  </span>
                                </div>

                                <div class="dv">
                                  <span class="text">
                                    <label>First Name:</label>
                                  </span>
                                  <span>
                                    <input
                                      type="text"
                                      id="firstname"
                                      name="firstname"
                                      placeholder="First Name"
                                      value={this.state.MainOrders.CustomerName}
                                    />
                                  </span>
                                </div>

                                <div class="dv">
                                  <span class="text">
                                    <label>Last Name:</label>
                                  </span>
                                  <span>
                                    <input
                                      type="text"
                                      id="Lastname"
                                      name="Lastname"
                                      placeholder="Last Name"
                                      value=""
                                    />
                                  </span>
                                </div>

                                <div class="dv">
                                  <span class="text">
                                    <label>Email ID:</label>
                                  </span>
                                  <span>
                                    <input
                                      type="text"
                                      id="email"
                                      name="email"
                                      placeholder="Email ID"
                                      value={this.state.MainOrders.fld_email}
                                    />
                                  </span>
                                </div>

                                <div class="dv">
                                  <span class="text">
                                    <label>Mobile/Cell Number:</label>
                                  </span>
                                  <span>
                                    <input
                                      type="text"
                                      id="phone"
                                      name="phone"
                                      placeholder="Mobile/Cell Number"
                                      value={this.state.MainOrders.fld_mobile}
                                    />
                                  </span>
                                </div>

                                <div class="dv">
                                  <span class="text">
                                    <label>Hash:</label>
                                  </span>
                                  <span>
                                    <input
                                      type="text"
                                      id="hash"
                                      name="hash"
                                      placeholder="Hash"
                                      value={this.state.CustomerHash}
                                    />
                                  </span>
                                </div>
                                <div class="dv">
                                  <span class="text">
                                    <label>Service Provider:</label>
                                  </span>
                                  <span>
                                    <input
                                      type="text"
                                      id="service_provider"
                                      name="service_provider"
                                      placeholder="service_provider"
                                      value={"payu_paisa"}
                                    />
                                  </span>
                                </div>
                              </div>

                              <button class="butn">Continue to payment</button>
                            </form>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                localStorage.removeItem("OrderData");
                                window.location.href = `/paymentprocess/${this.state.MainOrders.fld_payment_trx_num}`;
                              }}
                              class="butn"
                            >
                              Place Order
                            </button>
                          )}
                        </div>
                        <a class="returntocartbtnphn step__footer__previous-link return_to_previous_page"
                          href="/checkout">
                          <svg
                            focusable="false"
                            aria-hidden="true"
                            class="icon-svg icon-svg--color-accent icon-svg--size-10 previous-link__icon"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 10 10"
                          >
                            <path d="M8 1L7 0 3 4 2 5l1 1 4 4 1-1-4-4"></path>
                          </svg>
                          <span className="returntocheckputphn">Return to checkout</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>

        {/* <footer class="main__footer-shipping" role="contentinfo">
          <p class="copyright-text ">All rights reserved toys</p>
        </footer> */}
      </>
    );
  }
}

export default Shipping;
