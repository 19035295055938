import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../Login/Login.css";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "../Register/Register.css";
import { Link } from "react-router-dom";
import { Component } from "react";
import DatePicker from "react-date-picker";
import Notiflix from "notiflix";
import PostApiCall from "../../../Helper/Api";
import moment from "moment";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Gender: null,
      GenderData: [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Others", value: "Others" },
      ],
      BirthDay: null,
      NumRegex: /^[0-9]*$/,
      isPasswordVisible: false,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      PasswordRegex:
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
      open: false,
      Email: null,
      openOtp: false,
      openPassword: false,
      Password: null,
      Salt: [],
      isNewPasswordVisible: false,
      isConPasswordVisible: false,
      Name: null,
      MobileNo: null,
      EmailAddress: null,
      ConfirmPassword: null,
      Loading: false,
    };
  }

  componentDidMount() {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });
  }

  SaveRegistration() {
    if (this.state.Name != null) {
      if (this.state.Email != null) {
        if (this.state.EmailRegex.test(this.state.Email)) {
          if (this.state.MobileNo != null) {
            if (this.state.MobileNo.length == 10) {
              if (this.state.Gender != null) {
                if (this.state.BirthDay != null) {
                  if (this.state.Password != null) {
                    if (this.state.PasswordRegex.test(this.state.Password)) {
                      if (this.state.ConfirmPassword != null) {
                        if (this.state.Password == this.state.ConfirmPassword) {
                          this.setState(
                            {
                              Loading: true,
                            },
                            () => {
                              this.onPostRegistration();
                            }
                          );
                        } else {
                          Notiflix.Notify.Failure(
                            "Password and Confirm Password does not match. Please check and try again"
                          );
                        }
                      } else {
                        Notiflix.Notify.Failure(
                          "Please enter confirm password"
                        );
                      }
                    } else {
                      Notiflix.Notify.Failure(
                        "Password must be at least 6 characters  & contains a number and a letter"
                      );
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter password");
                  }
                } else {
                  Notiflix.Notify.Failure("Please select your date of birth");
                }
              } else {
                Notiflix.Notify.Failure("Please select your gender");
              }
            } else {
              Notiflix.Notify.Failure("Please enter valid mobile number");
            }
          } else {
            Notiflix.Notify.Failure("Please enter your mobile number");
          }
        } else {
          Notiflix.Notify.Failure("Please enter valid email");
        }
      } else {
        Notiflix.Notify.Failure("Please enter your email address");
      }
    } else {
      Notiflix.Notify.Failure("Please enter your full name");
    }
  }

  onPostRegistration = () => {
    PostApiCall.postRequest(
      {
        name: this.state.Name,
        email: this.state.Email,
        mobile: this.state.MobileNo,
        status: "Website",
        password: this.state.Password,
        dob: this.state.BirthDay,
        gender: this.state.Gender,
        createdon: moment().format("lll"),
        updatedon: moment().format("lll"),
        updatedby: 0,
      },
      "AddCustomer"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          this.setState({
            Loading: false,
          });
          this.onLogin();
        } else {
          this.setState({
            Loading: false,
          });
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };

  //   ========End RegisterDetails================

  onLogin = () => {
    PostApiCall.postRequest(
      {
        email: this.state.Email,
        password: this.state.Password,
        action: "Login",
        actiondate: moment().format("lll"),
      },
      "AddwebsiteLogin"
    ).then((results) =>
      results.json().then((obj) => {
        if (
          (results.status == 200 || results.status == 201) &&
          localStorage.getItem("OrderData")
        ) {
          localStorage.setItem("CustomerData", JSON.stringify(obj.data));

          this.onPostMoveCart(obj);
        } else if (results.status == 200 || results.status == 201) {
          localStorage.setItem("CustomerData", JSON.stringify(obj.data));
          localStorage.setItem("access", JSON.stringify(obj.token));
          localStorage.getItem("currentPage") == "Cart"
            ? (window.location.href = "/checkout")
            : (window.location.href = "/");
        } else {
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };

  onPostMoveCart(obj) {
    //console.log('move to cart')
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        customerid: JSON.stringify(obj.data[0].fld_customerid),
        cartid: Orderdetails[0].order_id,
      },
      "MoveTmpCrtToMain"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          localStorage.removeItem("OrderData");

          console.log(this.context.currentPage);
          localStorage.getItem("currentPage") == "Cart"
            ? (window.location.href = "/checkout")
            : (window.location.href = "/");
        } else {
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  }

  render() {
    return (
      <>
        <div>
          <div className="pd-3">
            <Breadcrumb className="breadcrumb-content">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item>
                <Link to="/register">Create Account</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="pd-3">
            <div className="Register">
              <h1 className="text-center-login">Create Account</h1>
              <Form>
                <div>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      className="input"
                      value={this.state.Name}
                      onChange={(name) => {
                        this.setState({
                          Name: name.target.value,
                        });
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email"
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      className="input"
                      value={this.state.Email}
                      onChange={(email) => {
                        this.setState({
                          Email: email.target.value,
                        });
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Mobile Number"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Mobile Number"
                      className="input"
                      value={this.state.MobileNo}
                      onChange={(mobile) => {
                        if (
                          this.state.NumRegex.test(mobile.target.value) &&
                          mobile.target.value.length <= 10
                        ) {
                          this.setState({
                            MobileNo: mobile.target.value,
                          });
                        }
                      }}
                    />
                  </FloatingLabel>
                  <div className="col-md-12">
                    <select
                      className="gender-input mb-3"
                      value={this.state.Gender}
                      onChange={(text) => {
                        this.setState({
                          Gender: text.target.value,
                        });
                      }}
                    >
                      <option value=""> Gender</option>
                      {this.state.GenderData.map((data, i) => (
                        <option value={data.value} key={data.value}>
                          {data.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12">
                    <div className="form-group ">
                      <DatePicker
                        value={this.state.BirthDay}
                        onChange={(date) => {
                          this.setState({
                            BirthDay: date,
                          });
                        }}
                        className="birthdate mb-3"
                        clearIcon={null}
                        calendarIcon="BirthDay"
                        maxDate={new Date()}
                        place
                        format="dd-MM-y"
                      />
                      {/* <span class="login-icon-change-pass" style={{ bottom: '41px' }}>
                        <img src={Calendericon} alt="datepicker" className="btnicons"></img>
                      </span> */}
                    </div>
                  </div>
                  <FloatingLabel controlId="floatingPassword" label="Password">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      className="input mb-3"
                      value={this.state.Password}
                      onChange={(password) => {
                        this.setState({
                          Password: password.target.value,
                        });
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Confirm Password"
                  >
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      className="input mb-3"
                      value={this.state.ConfirmPassword}
                      onChange={(confirmpassword) => {
                        this.setState({
                          ConfirmPassword: confirmpassword.target.value,
                        });
                      }}
                    />
                  </FloatingLabel>
                </div>
                <div
                  className="butn mt-3"
                  onClick={this.SaveRegistration.bind(this)}
                >
                  {this.state.Loading == true ? (
                    <div class="d-flex justify-content-center">
                      <div
                        class="spinner-border"
                        role="status"
                        style={{ width: "1.4rem", height: "1.4rem" }}
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    "Sign Up"
                  )}
                </div>
                <div className="col-12 mt-4 text-center">
                  <Link to='/login'>
                    <h6>Do you have an account ? <span style={{ color: '#c83363' }}> Sign In </span></h6>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Register;
