import { Component } from "react"
import "./ProductReviews.css"
import Container from "./Container"
import Notiflix from "notiflix";
import PostApiCall from "../../Helper/Api";
import WebsiteReviewBanner from "../../Images/ReviewBanner/WebsiteReviewBanner.webp"
import ReviewBanner from "../../Images/ReviewBanner/ReviewBanner.webp"
import { isMobile } from "react-device-detect";



class WriteAReview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            VariantImage: null,
            VariantName: null,
            VariantId: null
        }
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#0f3d74",
        });

        PostApiCall.postRequest(
            {
                id: parseInt(this.props.match.params.reviewid),
            },
            "GetProductByVariant"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    console.log(obj.data)

                    this.setState({
                        ProductDetail: obj.data,

                    });
                   
                    for (var i = 0; i < Object.keys(obj.data).length; i++) {
                        if (obj.data[i].fld_id == this.props.match.params.varid) {
                            this.setState({
                                SelectedVariant: obj.data[i],
                                VariantName: obj.data[i].fld_variantname,
                                VariantImage: obj.data[i].VariantImage,
                                VariantId: obj.data[i].fld_variantid
                            });
                        }
                    }
                }
            })
        );
    }

    render() {
        // console.log(this.props.match.params)
        return (
            <>
               
                <section class="inner-banner">
                    <div class="main-banner mobileinner-banner">
                        <img
                            src={isMobile ? ReviewBanner : WebsiteReviewBanner}
                            alt=""
                            className="img-responsive"
                        />
                    </div>
                </section>
                <Container variantName={this.state.VariantName} variantImage={this.state.VariantImage} variantId={this.state.VariantId} />
                
            </>
        )
    }
}

export default WriteAReview