import Container from "react-bootstrap/Container";
import { AiOutlineUser } from "react-icons/ai";
import { MdDateRange } from "react-icons/md";
import Blogimg from "../../Images/aboutus.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InnerBanner from "../InnerBanner/Innerbanner"

function AboutUs() {
  return (
    <>
    <InnerBanner/>
      <div className="blog-main">
        <div>
          {/* <div>
            <h2>latest blog</h2>
            <p>
              Interesting features is rich and colorful, each button to bring a
              surprise to the baby Introduction to give your child learn in
              advance.
            </p>
          </div> */}
          <div>
          <div className="blog-h2p texttransform-about">
          <h2 className="blog-h2 mb-0 ">
                    Welcome to Pink Parrot Kids !
                  </h2>
                  </div>
            <Row className="pad-row">
            <Col xs={12} md={6}>
                <div className="blog-img">
                  <img alt="blog" src={Blogimg} />
                </div>
              </Col>
              <Col xs={12} md={6} className="align-items-center">
                <div className="blog-h2p texttransform-about">
                  <p className="rightup-p">
                  Pink Parrot Kids, a Mumbai-based company, was born in April 2019. The founder Neha Kannan was inspired by her 4-year-old daughter Adhira Kannan to offer quality products to children across all ages. She believes in fun learning and activities keeping young and energetic minds busy.
                  </p>
                  <p className="mt-2">
                  I, Neha Kannan, started this venture to break all the notions of traditional learning with the help of our doodle designing skills. Our prolific designs boost your kid's brain development by triggering their creative and analytical tendencies. We popularised the phrase "Learning can take place outside the classroom."
                  </p>
                  <p className="mt-2">
                  My 4-year-old daughter Adhira Kannan inspired us to give high-quality products to kids across all age groups.
                  </p>
                  <p className="mt-2">
                  We offer you plenty of products that engage your kid's minds while catering to their innovative thinking and intellect. Our innovative and educational products come in all shapes and sizes, ranging from planetary pillow covers to doodle notebook bags and world map tablecloths.
                  </p>

                  <p className="mt-2">
                  It brings us immense joy to add value to your kids' lives by offering them the products they deserve. Moreover, these products channelise their energy creatively and constructively. Our premium products are our sincere efforts to add value to your and your kids' lives.

                  </p>

                  <p className="mt-2">
                  Pink Parrot Kids is a patron of sustainable development. We consider it our moral duty to make the earth a better place for our kids. Our aim is to skyrocket their creative & analytical skills, thereby forming a bedrock for making the earth a better place to live.                  </p>
                  <p className="mt-2">
                  Our optimism and their enhanced innovation and intelligence can alter the very fabric of the earth. We believe in their ability to make a change and shape the planet.
                  </p>

                  {/* <div className="but-margin-blog">
                    <div className="butn">Read More</div>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
