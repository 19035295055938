import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../Myaccount/Myaccount.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CustomerOrderList from "../Myaccount/CustomerOrderList";
import { useEffect, useState } from "react";
import PostApiCall from "../../Helper/Api";
import { useHistory } from "react-router-dom";
import moment from "moment";
import InnerBanner from "../InnerBanner/Innerbanner";

function Myaccount() {
  const history = useHistory();

  const [customerDetails, setcustomerDetails] = useState([]);
  const [customerId, setcustomerId] = useState(null);

  useEffect(() => {
    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);

    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${CustomerDetails[0].fld_customerid}`,
      },
      "Get_customerData_by_id"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setcustomerDetails(obj.data);
          setcustomerId(obj.data[0].fld_customerid);
        }
      })
    );
  }, []);
  return (
    <>
      <InnerBanner />
      <div>
        <div className="d-flex justify-content-between">
          <div className="pd-3">
            <Breadcrumb className="breadcrumb-content">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

              <Breadcrumb.Item href="/account">My Account</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="Account pd-3">
            <h1 className="text-center-account">My Account</h1>
          </div>
        </div>
        <div className="pd-3">
          <div>
            <Row>
              {/* change by laxmi col size------------------------ */}
              {/* <Col xs={8}> */}
              <Col xs={12} md={10} lg={10} xxxl={8}>
                <div className="Account-headtext">
                  <h2>Account Details</h2>
                  <Row>
                    {/* <Col xs={12} lg={6} md={6}> */}

                    {/* change by laxmi------------------ maximize md count */}
                    <Col xs={12} lg={6} md={10}>
                      <Card className="account-details-card">
                        {customerDetails.map((data, index) => {
                          if (index == 0) {
                            return (
                              <div>
                                <p className="d-flex justify-content-between">
                                  <p style={{ fontWeight: "600" }}>Name:</p>
                                  <p
                                    className="text-capitalize"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {data.fld_name}
                                  </p>
                                </p>

                                <p className="d-flex justify-content-between">
                                  <p>DOB:</p>
                                  {data.fld_dob == null ? (
                                    "NA"
                                  ) : (
                                    <p style={{ float: "right" }}>
                                      {moment(data.fld_dob).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                  )}
                                </p>
                                <p className="d-flex justify-content-between">
                                  <p>Mobile No.:</p>
                                  {data.fld_phone_number == null ? (
                                    "NA"
                                  ) : (
                                    <p style={{ float: "right" }}>
                                      {data.fld_phone_number}
                                    </p>
                                  )}
                                </p>
                                <p className="d-flex justify-content-between">
                                  <p>Email:</p>
                                  {data.fld_email_address == null ? (
                                    "NA"
                                  ) : (
                                    <p style={{ float: "right" }}>
                                      {data.fld_email_address}
                                    </p>
                                  )}
                                </p>
                                <p className="d-flex justify-content-between">
                                  {data.fld_address_1 == null ? (
                                    "No Address found"
                                  ) : (
                                    <p style={{ float: "right" }}>
                                      {data.fld_address_1},{data.fld_address_2},{" "}
                                      {data.CityName}, {data.StateName} ,
                                      {data.CountryName}
                                    </p>
                                  )}
                                </p>
                              </div>
                            );
                          }
                        })}
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className="register-but-flex">
                  <div className="butn-account">
                    <div
                      className="accountbutn butn"
                      onClick={() => {
                        history.push(`/address/${customerId}`);
                      }}
                    >
                      {" "}
                      View Addresses
                    </div>
                  </div>
                  <div className="butn-account">
                    <div
                      className="butn accountbutn"
                      onClick={() => {
                        localStorage.removeItem("CustomerData");
                        window.location.href = "/";
                      }}
                    >
                      {" "}
                      Log Out
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="Account-headtext mt-5">
                  <CustomerOrderList />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default Myaccount;
