import img1 from "../../../Images/RectangleBanner/1.webp";
import img2 from "../../../Images/RectangleBanner/2.webp";
import img7 from "../../../Images/RectangleBanner/4.webp";
import img8 from "../../../Images/RectangleBanner/3.webp";
import img3 from "../../../Images/HomePageGrid/a.webp";
import img4 from "../../../Images/HomePageGrid/b.webp";
import img5 from "../../../Images/HomePageGrid/c.webp";
import img6 from "../../../Images/HomePageGrid/d.webp";

import "../Card4/card4.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { Container, Row, Col } from "react-bootstrap";
function Card4() {
  var settings = {
    infinite: true,
    dots: true,
    autoplay: true,
    // draggable: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };
  return (
    <>
      {/* deskview------------------------------------------------------- */}
      <div className="card4-pd deskview">
        <Slider {...settings}>
          <div>
            <Row className="card4-row">
              <Col lg={8} xs={8} className="card4LeftPaddingRow1Phn1">
                <div className="card4-mainrow">
                  <div className="card4-div1">
                    <img src={img2} width="100%" height="100%" alt="" />
                  </div>
                  <div className="collff">
                    <h2>Enable your kid to shoot the star</h2>

                    <p className="ban2-none">
                      Sleep is crucial to enhance the physical and mental health
                      of your kid. Enhance the quality of their sleep.
                    </p>

                    <div className="btnprimary-div card4butn butn">
                      <a href={`${process.env.PUBLIC_URL}/PillowCase/${4}`}>
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4} xs={4} className="card4RightPaddingRow1Phn1">
                <a href="/PencilCase/3">
                  <div className="card4-mainrow">
                    <div className="card4-div2">
                      <img src={img3} width="100%" height="100%" alt="" />
                    </div>

                    <div className="collff1">
                      <h2>Go backpacking</h2>

                      <p className="ban2-none">
                        A Designer Bag is your kid's best companion that helps
                        them explore all their innovative and artistic
                        tendencies.
                      </p>

                      <div className="btnprimary-div card4butn butn">
                        <a href={`${process.env.PUBLIC_URL}/DesignerBag/${2}`}>
                          Shop Now
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
              </Col>
            </Row>
            <Row className="card4-row2">
              <Col lg={4} xs={4} className="card4LeftPaddingRow2Phn1">
                <div className="card4-mainrow">
                  <div className="card4-div2">
                    <img src={img4} width="100%" height="100%" alt="" />
                  </div>
                  <div className="collff1">
                    <h2>Happy doodling</h2>

                    <p className="ban2-none">
                      Designing is a spiritual experience of its own kind, and
                      you can enhance it with our wash out doodles.
                    </p>

                    <div className="btnprimary-div card4butn butn">
                      <a
                        href={`${process.env.PUBLIC_URL}/Wash-out-Markers/${7}`}
                      >
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={8} xs={8} className="card4RightPaddingRow2Phn1">
                <div className="card4-mainrow">
                  <div className="card4-div1">
                    <img src={img8} width="100%" height="100%" alt="" />
                  </div>
                  <div className="collff">
                    <h2>Premium Quality Prolifc Pencil Cases</h2>

                    <p className="ban2-none">
                      Our aesthetically designed pencil cases help your artistic
                      child in innovative imagination.
                    </p>

                    <div className="btnprimary-div card4butn butn">
                      <a href={`${process.env.PUBLIC_URL}/PencilCase/${3}`}>
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row className="card4-row">
              <Col lg={8} xs={8} className="card4LeftPaddingRow1Phn1">
                <div className="card4-mainrow">
                  <div className="card4-div1">
                    <img src={img7} width="100%" height="100%" alt="" />
                  </div>
                  <div className="collff">
                    <h2>Perfect bag to craft your kid's zone</h2>

                    <p className="ban2-none">
                      Backpacking is the benevolent art of packing the goods
                      that can help you rule the world. Buy our supreme quality,
                      simplistic bags.
                    </p>

                    <div className="btnprimary-div card4butn butn">
                      <a href={`${process.env.PUBLIC_URL}/Backpack/${1}`}>
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4} xs={4} className="card4RightPaddingRow1Phn1">
                <div className="card4-mainrow">
                  <div className="card4-div2">
                    <img src={img5} width="100%" height="100%" alt="" />
                  </div>
                  <div className="collff1">
                    <h2>Premium pillow cases</h2>

                    <p className="ban2-none">
                      Enhance your kid's health with our premium pillow covers.
                    </p>

                    <div className="btnprimary-div card4butn butn">
                      <a href={`${process.env.PUBLIC_URL}/PillowCase/${4}`}>
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="card4-row2">
              <Col lg={4} xs={4} className="card4LeftPaddingRow2Phn1">
                <div className="card4-mainrow">
                  <div className="card4-div2">
                    <img src={img6} width="100%" height="100%" alt="" />
                  </div>
                  <div className="collff1">
                    <h2>The perfect backpack</h2>

                    <p className="ban2-none">
                      Backpacking is the benevolent art of packing the goods
                      that can help you rule the world.
                    </p>
                    <div className="btnprimary-div card4butn butn">
                      <a href={`${process.env.PUBLIC_URL}/Backpack/${1}`}>
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={8} xs={8} className="card4RightPaddingRow2Phn1">
                <div className="card4-mainrow">
                  <div className="card4-div1">
                    <img src={img1} width="100%" height="100%" alt="" />
                  </div>
                  <div className="collff">
                    <h2>Backpacking your kid's basics</h2>

                    <p className="ban2-none">
                      A Designer Bag is your kid's best companion that helps
                      them explore all their innovative and artistic tendencies.
                    </p>

                    <div className="btnprimary-div card4butn butn">
                      <a href={`${process.env.PUBLIC_URL}/DesignerBag/${2}`}>
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Slider>
      </div>

      {/* phn view----------------------------------------------------- */}

      <div className="card4-pd phnview">
        <div>
          <Row className="card4-row">
            <Col sm={12} >
              <div className="card4-mainrow">
                <div className="card4-div1">
                  <img src={img2} width="100%" height="100%" alt="" />
                </div>
                <div className="collffphn">
                  <h2>Enable your kid to shoot the star</h2>

                  <p className="ban2-none">
                    Sleep is crucial to enhance the physical and mental health
                    of your kid. Enhance the quality of their sleep.
                  </p>

                  <div className="btnprimary-div card4butn card4butnphn butn">
                    <a href={`${process.env.PUBLIC_URL}/PillowCase/${4}`}>
                      Shop Now
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} className='collffphntopmargintop'>
              <a href="/PencilCase/3">
                <div className="card4-mainrow">
                  <div className="card4-div1">
                    <img src={img3} width="100%" height="100%" alt="" />
                  </div>

                  <div className="collffphntopmargin">
                    <h2>Go backpacking</h2>

                    <p className="ban2-none">
                      A Designer Bag is your kid's best companion that helps
                      them explore all their innovative and artistic tendencies.
                    </p>

                    <div className="btnprimary-div card4butn card4butnphn butn">
                      <a href={`${process.env.PUBLIC_URL}/DesignerBag/${2}`}>
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </Col>
          </Row>
          <Row className="card4-row2 py-0 my-0">
            <Col sm={12} className='collffphntopmargintop'>
              <div className="card4-mainrow">
                <div className="card4-div2">
                  <img src={img4} width="100%" height="100%" alt="" />
                </div>
                <div className="collffphn">
                  <h2>Happy doodling</h2>

                  <p className="ban2-none">
                    Designing is a spiritual experience of its own kind, and you
                    can enhance it with our wash out doodles.
                  </p>

                  <div className="btnprimary-div card4butnphn card4butn butn">
                    <a href={`${process.env.PUBLIC_URL}/Wash-out-Markers/${7}`}>
                      Shop Now
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} className='collffphntopmargintop'>
              <div className="card4-mainrow">
                <div className="card4-div1">
                  <img src={img8} width="100%" height="100%" alt="" />
                </div>
                <div className="collffphntopmargin">
                  <h2>Premium Quality Prolifc Pencil Cases</h2>

                  <p className="ban2-none">
                    Our aesthetically designed pencil cases help your artistic
                    child in innovative imagination.
                  </p>

                  <div className="btnprimary-div card4butnphn card4butn butn">
                    <a href={`${process.env.PUBLIC_URL}/PencilCase/${3}`}>
                      Shop Now
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div></div>
      </div>
    </>
  );
}
export default Card4;
