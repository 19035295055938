/* eslint-disable no-loop-func */
import React from "react";
import PostApiCall from "../../Helper/Api";
import Notiflix from "notiflix";
import PaymentProcess from "../../Images/Payment/Paymentprocessing.svg";

class PaymentFail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ProdData: [],
      OrderNumber: null,
      CustomerEmail: null,
      CustomerName: null,
      TotalAmount: null,
      OrderData: [],
      DeliveryCharge: null,
      Subtotal: null,
      Taxes: null,
      CouponDiscount: null,
      OrderDate: null,
      OfferDiscount: null,
      gstonDelivery: null
    };
  }

  componentDidMount() {

    // console.log("thgtg")



    PostApiCall.postRequest(
      {
        transactionnumber:
          this.props.match.params.txnid + "/" + this.props.match.params.mipid,
        paymentStatus: "Successful",
      },
      "update_paymentStatus"
    ).then((results1) =>
      // const objs = JSON.parse(result._bodyText)
      results1.json().then((obj) => {
        if (results1.status == 200 || results1.status == 201) {
          this.getOrderData();
        }
      })
    );
  }

  getOrderData = () => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_payment_trx_num='${this.props.match.params.txnid}/${this.props.match.params.mipid}'`,
      },
      "GetWebsiteOrderDetails"
    ).then((results1) =>
      // const objs = JSON.parse(result._bodyText)
      results1.json().then((obj) => {
        if (results1.status == 200 || results1.status == 201) {
          console.log(obj.data)
          this.setState(
            {
              OrderData: obj.data[0],
              ProdData: obj.data,
              CustomerEmail: JSON.parse(localStorage.getItem("CustomerData"))[0].fld_email,
              TotalAmount: obj.data[0].Total_Inclusive_Tax,
              OrderNumber: obj.data[0].fld_orderNumber,
              DeliveryCharge: obj.data[0].Shipping_Charg,
              Subtotal: obj.data[0].fld_sub_total_mrp,
              Taxes: obj.data[0].fld_gst,
              CouponDiscount: obj.data[0].fld_coupon_discount,
              CustomerName: obj.data[0].CustomerName,
              OrderDate: obj.data[0].fld_order_date,
              OfferDiscount: obj.data[0].Total_Discount,
              gstonDelivery: obj.data[0].fld_shipping_gst == null
                ? "0.00"
                : obj.data[0].fld_shipping_gst
            },
            () => {
              this.SendMailers();
            }
          );
        }
      })
    );
  };

  SendMailers() {
    //   //console.log(this.state.OrderData)
    console.log(this.state.ProdData)

    // fetch(
    //     "https://www.instaalerts.zone/SendSMS/sendmsg.php?uname=globaltrendz&pass=abc321&send=RIGHTC&dest=" +
    //     this.state.OrderData.fld_mobile +
    //       "&msg=Hi " +
    //       this.state.OrderData.fld_name +
    //       ", Your Order - " +
    //       this.state.OrderData.fld_ordernumber +
    //       " has been successfully placed. Team BeatMySugar"
    //   ).then((response) => response.json());

    PostApiCall.postRequest(
      {
        orderData: this.state.ProdData,
        ordernumber: this.state.OrderNumber,
        customeremail: this.state.CustomerEmail,
        customername: this.state.CustomerName,
        totalamount: parseFloat(this.state.TotalAmount).toFixed(2),
        deliverycharge: this.state.DeliveryCharge == null ? "0.00" : parseFloat(this.state.DeliveryCharge).toFixed(2),
        subtotal: parseFloat(this.state.Subtotal).toFixed(2),
        taxes: parseFloat(this.state.Taxes).toFixed(2),
        couponDiscount: this.state.CouponDiscount == null ? "0.00" : parseFloat(this.state.CouponDiscount).toFixed(2),
        orderdate: this.state.OrderDate,
        offerdiscount: parseFloat(this.state.OfferDiscount).toFixed(2),
        gstonDelivery: parseFloat(this.state.gstonDelivery).toFixed(2)
      },
      "CustomerOrderMailer"
    ).then((results2) =>
      // const objs = JSON.parse(result._bodyText)
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          // localStorage.removeItem('OrderDetails')
          localStorage.removeItem("OrderDetails");
          localStorage.removeItem("OrderData");

          window.location.href = `/paymentsuccess/${this.props.match.params.txnid}/${this.props.match.params.mipid}`;
        }
      })
    );
  }

  render() {
    console.log(this.props.match.params)
    return (
      <>

        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex justify-content-center text-align-center mt-5 text-success">
                <div class="spinner-border" role="status"></div>
                <p style={{ marginLeft: "15px" }}>
                  Order Processing...
                </p>
              </div>

              <div class="d-flex justify-content-center">
                <img
                  src={PaymentProcess}
                  class="paymentFail img-fluid"
                  alt="payment failed"
                  height="550px"
                  width="550px"
                ></img>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default PaymentFail;
