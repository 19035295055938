import Carousel from "react-bootstrap/Carousel";
import "../Banner/Banner.css";
import banner1 from "../../../Images/HomePageBanner/l.webp";
import banner2 from "../../../Images/HomePageBanner/a.webp";
import banner3 from "../../../Images/HomePageBanner/d.webp";
import banner4 from "../../../Images/HomePageBanner/g.webp";

function Banner() {
  return (
      <div>
        <Carousel className="banner1">
          <Carousel.Item >
            <img className="d-block w-100" src={banner1} alt="First slide" />
            <Carousel.Caption className="left-text-banner">
              <div>
                <h2>Shop Best Home Accessories For Your Children</h2>
                <p>
                  Accentuate your Kid's learning experience with Pink Parrot
                  Kid's aesthetically designed Table Cloths and Designer Bags,
                  while inspiring innovation.
                </p>
                <div className="homeBannerbtn">
                  <a
                    className=""
                    href={`${process.env.PUBLIC_URL}/PillowCase/${4}`}
                  >
                    Shop Now
                  </a>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item >
            <img className="d-block w-100" src={banner2} alt="Second slide" />

            <Carousel.Caption className="left-text-banner">
              <div>
                <h2>Little Heaven for your Little Angel</h2>
                <p>
                  A Designer Bag is your kid's best companion that helps them
                  explore all their innovative and artistic tendencies. Buy
                  these bags and expand the horizon of your kid's creativity.
                </p>

                <div className="homeBannerbtn">
                  <a
                    className=""
                    href={`${process.env.PUBLIC_URL}/DesignerBag/${2}`}
                  >
                    Shop Now
                  </a>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item >
            <img className="d-block w-100" src={banner3} alt="Third slide" />

            <Carousel.Caption className="left-text-banner">
              <div>
                <h2>Treat your Cutie Pie Like a Prince/Princess</h2>
                <p>
                  Backpacking is the benevolent art of packing the goods that
                  can help you rule the world. Buy our supreme quality,
                  simplistic bags and give them the goodness they deserve.
                </p>
                <div className="homeBannerbtn">
                  <a
                    className=""
                    href={`${process.env.PUBLIC_URL}/DesignerBag/${2}`}
                  >
                    Shop Now
                  </a>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item >
            <img className="d-block w-100" src={banner4} alt="Third slide" />

            <Carousel.Caption className="left-text-banner">
              <div>
                <h2>Make your kid’s room more warm and welcoming</h2>
                <p>
                  Our aesthetically designed pencil cases help your artistic
                  child in innovative imagination. Encourage and nurture the
                  artist within your kids by buying our designer pencil cases.
                </p>
                <div className="homeBannerbtn">
                  <a
                    className=""
                    href={`${process.env.PUBLIC_URL}/PencilCase/${3}`}
                  >
                    Shop Now
                  </a>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
  );
}
export default Banner;
