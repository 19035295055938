import React from "react";
import InnerBanner from "../InnerBanner/Innerbanner";
import Collapsible from "react-collapsible";


const FAQs = () => {
    return (
        <>
            <div className="row">
                <div className="12">
                    <InnerBanner />
                </div>
            </div>
            <div className="static-page-main">
                <div className="row">
                    <div className="col-12">
                        <div className="termsandconditions-heading">
                            <h2>FAQs</h2>
                        </div>
                    </div>
                    <div className="col-12 faqs">
                    <Collapsible trigger="What is Pink Parrot Kids?">
                            <p>
                            Pink Parrot Kids is one of Asia's largest kids products companies that caters to kids and parents by making products that make learning fun for their kids.
                            </p>
                        </Collapsible>
                    <Collapsible trigger="Is it 100% cotton?">
                            <p>
                                Yes, It is 100% cotton.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="Is the product washable?">
                            <p>
                                Yes, All the products like bags, pencil pouch, table cloths etc. are machine washable.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="Does the color gets washedout?">
                            <p>
                                Yes, With every wash the color gets washed off. It help the kids to reuse it.
                            </p>
                        </Collapsible>
                        
                        <Collapsible trigger="Where are the offices of Pink Parrot Kids located?">
                            <p>
                                Currently, our office is located in Mumbai while the orders are shipped from our warehouses located across India.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="What's the minimum order value required to place an order?">
                            <p>
                                There is no minimum value required to order.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="How do I know my order has been confirmed?">
                            <p>
                                After checking out during the payment process, you will get a confirmation that your payment has been processed successfully. You will also get a mail in your registered email id, along with an SMS to your registered mobile number confirming the order.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="What is your Replacement Policy?">
                            <p>
                                To view the Replacement Policy applicable to each product, please visit the product/combo page that you wish to purchase. The product is applicable for replacement within the specified window after the delivery of the product. In case of replacement, the original product will be picked up. Once the original product has been received at our facility and the quality check has been carried out successfully, the replacement product will be shipped to you. Please note that the product should be unused, unwashed with all original tags intact and should be returned in the original packaging with a copy of the original invoice. If the product is not eligible for a replacement after delivery due to any eligible reasons, such as that the product is out of stock, the return pickup for the product is unavailable, or any other similar reason, you may return the product within the specified window after the delivery of the product. The refund will be processed after a successful quality check of the returned product. For more details, check out our Replacement Policy Section.                             </p>
                        </Collapsible>
                        <Collapsible trigger="How long will it take to receive my orders?">
                            <p>
                                For all areas serviced by reputed couriers, the delivery time would be within 3 to 4 business days after dispatch (business days exclude Sundays and other holidays). However, items weighing over 2 kilos may take a couple of days longer to reach.</p>
                        </Collapsible>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQs