import "../Order/Orderconfirm.css";
import React, { Component } from "react";
// import img1 from "../images/pink/pink1.webp";
// import img2 from "../images/pink/pink2.webp";
// import img3 from "../images/pink/pink3.webp";
// import img4 from "../images/pink/pink4.webp";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import ban1 from "../../Images/ban1.webp";
import ban2 from "../../Images/ban2.webp";
function Orderconfirm() {
  return (
    <>
      <div className="pd-3">
        <div className="flex-confirm">
          <div className="card">
            <Container>
              <Row>
                <Col xl="7" className="container px-1 py-1">
                  <div className="hero_image imgSizeBanner6overflow">
                    <img
                      className="mx-auto img-fluid imgSizeBanner6"
                      src={ban1}
                      alt=""
                    />
                  </div>
                  <div className="hero_textLargeCol">
                    <h2 className="banner1pinkfont">
                      Beautiful Child Stationary Accessories
                    </h2>
                    <p className="banner1pinkpehra">
                      Interesting features is rich and colorful, each button to
                      bring a surprise to the baby Introduction to give your
                      child learn in advance.
                    </p>
                    <a href="" class="btn btnBanner6">
                      Shop Now
                    </a>
                  </div>
                </Col>
                <Col xl="5" className="container px-1 py-1">
                  <div className="hero_image imgSizeBanner6overflow">
                    <img
                      className="mx-auto img-fluid imgSizeBanner6"
                      src={ban2}
                      alt=""
                    />
                  </div>
                  <div className="hero_textSmallCol">
                    <h2 className="banner1pinkfont">Amazing Soft toys</h2>
                    <p className="banner1pinkpehra">
                      Each button to bring a surprise to the baby Introduction
                      to give your child learn in advance.
                    </p>
                    <a href="" class="btn btnBanner6">
                      Shop Now
                    </a>
                  </div>
                </Col>
              </Row>
              {/* <Row style={{ marginTop: "30px", position: "relative" }}> */}
              <Row>
                <Col xl="5" className="container px-1 py-1">
                  <div className="hero_image imgSizeBanner6overflow">
                    <img
                      className="mx-auto img-fluid imgSizeBanner6"
                      src={ban1}
                      alt=""
                    />
                  </div>
                  <div className="hero_textSmallCol">
                    <h2 className="banner1pinkfont">Small Seat Sofa</h2>
                    <p className="banner1pinkpehra">
                      Each button to bring a surprise to the baby Introduction
                      to give your child learn in advance.
                    </p>
                    <a href="" class="btn btnBanner6">
                      Shop Now
                    </a>
                  </div>
                </Col>
                <Col xl="7" className="container px-1 py-1">
                  <div class="hero_image imgSizeBanner6overflow">
                    <img
                      className="mx-auto img-fluid imgSizeBanner6"
                      src={ban1}
                      alt=""
                    />
                  </div>
                  <div class="hero_textLargeCol">
                    <h2 className="banner1pinkfont">
                      Latest Toy House For Your Children
                    </h2>
                    <p className="banner1pinkpehra">
                      Interesting features is rich and colorful, each button to
                      bring a surprise to the baby Introduction to give your
                      child learn in advance.
                    </p>
                    <a href="" class="btn btnBanner6">
                      Shop Now
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default Orderconfirm;
