import React from "react";
import InnerBanner from "../InnerBanner/Innerbanner";


const ReturnsandRefunds = () => {
    return (
        <>
            <div className="row">
                <div className="12">
                    <InnerBanner />
                </div>
            </div>
            <div className="static-page-main">
                <div className="row">
                    <div className="col-12">
                        <div className="termsandconditions-heading">
                            <h2>Returns/Refund and Shipping Policy</h2>
                        </div>
                    </div>
                    <div className="col-12">
                        <b>Expected Delivery Timelines
                        </b>
                        <p>Due to the nature of small creative businesses and the unique things they create, several products are made to order, and some products take longer than others to make. As a result, each product may have a different shipping time. Please check the product page to know how many days your chosen product will ship in. Once shipped, we will send you a notification with the tracking details so you know when to expect it.
                        </p>
                        <p>Please Note: Orders per Store will be clubbed into as few shipments as possible. If you need a part of your order earlier, please place an order for it separately.
                        </p>
                        <b>Shipping and Handling Fees</b>
                        <p>Shipping and Handling Fee of Rs. 100 will be charged at the time of checkout. If you choose to pay through Cash on Delivery, an additional Convenience Fee of Rs.60 per shipment will be charged at the time of checkout.
                        </p>
                        <b>Promotions</b>
                        <p>If you are using a promo code or discount, the reduction is applicable only toward the price of the product(s). They cannot be applied towards Pink Parrot Kids LLP's Shipping and Handling Fee or Convenience Fee.
                        </p>
                        <b>Shipping of Non-Standard Products</b>
                        <p>A non-standard item is something that is considered to be fragile, perishable, of high value, large or heavy or something that has exceptional delivery requirements. The above charges may not apply for such products and you may be charged an additional Shipping and Handling Fee at the time of checkout. Please check the product page to see if your product is considered to be 'non-standard'.
                        </p>
                        <b>Returns and Exchanges
                        </b>
                        <p>1. I DON'T LIKE THE PRODUCT AND WANT TO RETURN OR EXCHANGE IT.</p>
                        <p>If you do not like the product you have purchased or if you need a different size, and the respective store accepts returns, you can initiate a return request by sending an email to Pink Parrot Kids LLP  at neha@pinkparrotkids.com within 5 days (or as specified in the respective Store's policies) of the delivery of the product.
                        </p>
                        <p>Once your return request is reviewed by Pink Parrot Kids LLP, we will contact you with directions on how to proceed further with the resolution or return of the product.
                        </p>
                        <p>Please note that you will be responsible for bearing the cost of shipping the product back to the seller. Kindly ensure the product is packed well, includes everything that came with it and that you use a traceable and insured logistics service. If the product is not returned in its original condition, the loss in value will be the responsibility of the buyer. The product must be returned to the seller within 5 days of the approval of the return request.
                        </p>
                        <p>Once the seller receives the product and it meets the conditions for return, the amount equal to the product value will be refunded to your Pink Parrot Kids LLP  account in the form of Pink Parrot Kids LLP  Store Credits. Please note that Shipping & Handling Fee and COD Convenience Fee are non-refundable.
                        </p>
                        <p>In case of an exchange request, a replacement, if available with the seller, will be shipped to you at no additional charge.
                        </p>
                        <p>Please note that returns or exchanges are not accepted if:</p>
                        <ul className="mb-3">
                            <li> a) The seller does not accept returns or exchanges.
                            </li>
                            <li> b) The return or exchange request is made outside the specified time frame.
                            </li>
                            <li>c) The product is made to order, customised or personalised.</li>
                            <li> d) The product returned appears to be used, altered, resized or damaged in any other manner.</li>
                            <li> e) The product returned is not in the same condition as when you received it.</li>
                            <li> f)The product returned is missing original fixtures like boxes, seller invoice, tags, labels, original packing, spare buttons, freebies and other accessories, or such fixtures are torn or damaged.</li>
                            <li> g) The product belongs to specific categories like innerwear (lingerie, swimwear, nappies etc.), food and drink, or any other product that is fragile, perishable or may have a hygiene-related issue.</li>
                            <li>h) The product is on sale.</li>
                        </ul>
                        <p>2. MY ORDER IS DAMAGED/MISSING OR INCORRECT.
                        </p>
                        <p>If the product you have received is damaged, defective or incorrect, or if you have received an empty package or an item mentioned on the invoice is missing, we are extremely sorry! Please contact us on neha@pinkparrotkids.com within 24 hours of receiving the order along with relevant photographs so we can help resolve the issue.
                        </p>
                        <p>Once your return request is approved by Pink Parrot Kids LLP, we will arrange for the pickup of the product. If our reverse pickup service is not available at your address, we will ask you to ship the product yourself. The courier charges for the same will be reimbursed to you.
                        </p>
                        <p>* For orders placed using Cash on Delivery as the payment method, refunds can be processed to your bank account via National Electronic Funds Transfer (NEFT). You will need to share the following information to enable us to process a refund to your account:
                        </p>
                        <ul className="mb-3">
                            <li>a) Bank Account Number
                            </li>
                            <li> b) IFSC Code</li>
                            <li>c) Account Holder's Name</li>
                            <li>d) Bank Branch</li>
                            <li>e) Bank Name</li>
                        </ul>
                        <p>Please Note: A replacement or refund will only be given after the seller receives the returned product in its original condition. If the product received is in not the condition in which it was shipped to you, no replacement or refund will be given. The original product will be shipped back to the customer and the cost of shipping will be recovered from the customer.
                        </p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ReturnsandRefunds;