import { Component } from "react";
import Card from "../ListingPage/Card";
import "./RelatedProduct.css";

class RelatedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <section class="spaced-section">
          <div className="listing-page-container">
            <h2 class="heading headingDeatilsitemsproduct-page text-center">
              <span>You may also like</span>
            </h2>
            <div class="row rless related_products">
              {this.props.relatedProductData.map((data, index) => {
                return (
                  <div class="grid__item col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 cless">
                    <Card itemdata={data} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default RelatedProducts;
