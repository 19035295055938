import { Component } from "react";
import "./Checkout.css"
import Information from "./Information";

class Index extends Component {
    render() {
        return (
            <>
                <div >
                    <Information />
                </div>
            </>

        )
    }
}

export default Index;