import Router from "./Router/Router";

function Pinkparrot() {
  return (
    <>
      <Router />
    </>
  );
}

export default Pinkparrot;
