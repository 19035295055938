import { Component } from "react";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import Header from "../Home/Header/Header";
import Footer from "../Home/Footer/Footer";

class StaticPages extends Component {
  render() {
    return (
      <>
        {/* <Header /> */}
        <div class="static-page-container">
          <Container>
            <Breadcrumb className="breadcrumb-content">
              <Breadcrumb.Item>
                <Link to="/">Home </Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item>
                <Link to="#">About us </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Container>

          {/* <nav
            class="breadcrumb row"
            role="navigation"
            aria-label="breadcrumbs"
          >
            <div class="col-12 listing-page">
              <a href="/" title="Home">
                Home
              </a>

              <span aria-hidden="true">
                <svg viewBox="0 0 512 512" class="arrowrightw">
                  <polygon points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 "></polygon>
                </svg>
              </span>
              <span>about us</span>
            </div>
          </nav> */}
          <main
            id="MainContent"
            class="content-for-layout focus-none"
            role="main"
            tabindex="-1"
          >
            <section
              id="shopify-section-template--15673555812591__main"
              class="shopify-section"
            >
              <div class="container">
                <h1 class="heading text-center">
                  <span>about us</span>
                </h1>
                <div class="rte">
                  <span>
                    Phasellus at finibus tellus. Curabitur ligula mi, tempor id
                    ullamcorper eget, efficitur vitae diam. Pellentesque
                    habitant morbi tristique senectus et netus et malesuada
                    fames ac turpis egestas. Vestibulum eu lacinia nisl, nec
                    placerat libero. Morbi nec finibus ex. Duis consectetur orci
                    turpis, vitae sagittis turpis commodo eget. Aliquam
                    malesuada, ante vitae commodo congue, lacus odio
                    pellentesque neque, in molestie ex nisi sollicitudin odio.
                    Fusce pharetra lobortis odio, a mollis sem. Quisque ipsum
                    arcu, lobortis vel facilisis sit amet, ornare sit amet urna.
                    Nam ligula sem, congue a semper ac, molestie eget dolor.
                    Pellentesque imperdiet dui ut mi mattis vehicula. Aenean
                    justo elit, eleifend ac convallis nec, mollis id sapien.
                    Nunc a ipsum eget risus molestie varius hendrerit a nibh.
                  </span>
                </div>
              </div>
            </section>
          </main>
        </div>
        {/* <Footer /> */}
      </>
    );
  }
}

export default StaticPages;
