import { Route, Switch } from "react-router-dom";
import Header from "../Home/Header/Header";
import Footer from "../Home/Footer/Footer";
import Home from "../Home/Home";
import Login from "../UserAccount/Login/Login";
import Register from "../UserAccount/Register/Register";
import Account from "../Myaccount/Myaccount";
import Address from "../Address/Address";
import Detail from "../DetailPage/DetailPage";
import Listing from "../ListingPage/ListingPage";
import Cart from "../Cart/Cart";
import Checkout from "../Checkout/index";
import Shipping from "../Checkout/Shipping";
import Payment from "../Checkout/Payment";
import StaticPages from "../Staticpages/StaticPages";
import Order from "../Order/Orderconfirm";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import EditAddress from "../Address/EditAddress";
import Provider from "../../context/store";
import WriteAReview from "../ProductReviews/WriteAReview";
import AllReviews from "../ProductReviews/AllReviews";
import PaymentProcess from "../Payment/PaymentProcess";
import PaymentFail from "../Payment/PaymentFail";
import PaymentSuccess from "../Payment/PaymentSuccess";
import Contact from "../Staticpages/ContactUs";
import AboutUs from "../Staticpages/AboutUs";
import Error from "../../Pinkparrot/Error/PageNotFound";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import { useEffect, useState } from "react";
import TermsandConditions from "../Staticpages/TermsandConditions";
import PrivacyPolicy from "../Staticpages/PrivacyPolicy";
import ReturnsandRefunds from "../Staticpages/ReturnsAndRefunds";
import FAQs from "../Staticpages/FAQs";
import Manage from "../Manage"

function Router() {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Provider>
          <Header />
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/writereview/:reviewid`}
              component={WriteAReview}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/all-reviews/:variantid`}
              component={AllReviews}
            ></Route>
            <Route path="/account" component={Account} />
            <Route path="/address/:id" component={Address} />
            <Route
              path="/productdetail/:productname/:variantid"
              component={Detail}
            />
            <Route
              exact
              path="/:categoryname/:categoryid"
              component={Listing}
            />
            <Route path="/cart" component={Cart} />
            {/* <Route path="/cart" component={Cart} /> */}
            <ProtectedRoute
              path="/checkout"
              component={Checkout}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            />
            <ProtectedRoute
              path="/shipping"
              component={Shipping}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            />
            <ProtectedRoute
              path="/payment"
              component={Payment}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            />
            <Route path="/staticPages" component={StaticPages} />
            <Route path="/order" component={Order} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <ProtectedRoute
              path="/edit-address"
              component={EditAddress}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/paymentprocess/:txnid/:mipid`}
              component={PaymentProcess}
            // isAuth={localStorage.getItem("CustomerData") == null ? false : true}
            />
            <ProtectedRoute
              exact
              path={"/paymentfail/:txnid/:mipid"}
              component={PaymentFail}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            />
            <ProtectedRoute
              exact
              path={`${process.env.PUBLIC_URL}/paymentsuccess/:txnid/:mipid`}
              component={PaymentSuccess}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact-us`}
              component={Contact}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about-us`}
              component={AboutUs}
            ></Route>
            <Route path="/termsandconditions" component={TermsandConditions} />
            <Route exact path="/manage" component={Manage} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/returns-refunds" component={ReturnsandRefunds} />
            <Route path="/faqs" component={FAQs} />
            <Route component={Error} />
          </Switch>
          <Footer />
        </Provider>
      </div>
    </>
  );
}
export default Router;
