import React, { Component } from "react";
import "./DetailPage.css";
import ProductReviews from "../ProductReviews/ProductReviews";
import RelatedProducts from "./RelatedProducts";
import PostApiCall from "../../Helper/Api";
import Notiflix from "notiflix";
import ReactImageMagnify from "react-image-magnify";
import Loader from "../../LoaderComponent/Loader";
import Parser from "html-react-parser";
import moment from "moment";
import { store } from "../../context/store";

class DetailPage extends Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      ProductDetail: [],
      SelectedVariant: [],
      RelatedProductDetail: [],
      ProductCart: [],
      SelectedCartData: [],
      Quantity: 1,
      VariantImage: null,
      VariantId: null,
      ProductId: null,
      VariantName: null,
      ItemName: null,
      VariantList: null,
      MRP: null,
      DiscountPrice: null,
      YouSave: null,
      SavePer: null,
      Stock: null,
      CategoryName: null,
      Rating: null,
      Weight: null,
      CountryOfOrigin: null,
      ShortDescription: null,
      LongDescription: null,
      MarketedBy: null,
      checkedDiv: 0,
      loading: false,
      DataLoading: true,
    };
  }

  componentDidMount() {
    const store = this.context;

    PostApiCall.postRequest(
      {
        id: parseInt(this.props.match.params.variantid),
      },
      "GetProductByVariant"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          console.log(obj.data);

          this.setState({
            ProductDetail: obj.data,
            done: true,
          });

          for (var i = 0; i < Object.keys(obj.data).length; i++) {
            if (obj.data[i].fld_id == this.props.match.params.varid) {
              this.setState({
                SelectedVariant: obj.data[i],
                //VariantImage: obj.data[i],
                MRP: obj.data[i].fld_mrp,
                DiscountPrice: obj.data[i].fld_selling_price,
                SavePer: obj.data[i].fld_discount_percent,
                YouSave: obj.data[i].YouSave,
                Stock: obj.data[i].available_stock,
                VariantId: obj.data[i].fld_variantid,
                VariantName: obj.data[i].fld_variantname,
                CategoryName: obj.data[i].fld_categoryname,
                Rating: obj.data[i].fld_categoryname,
                Weight: obj.data[i].weight,
                CountryOfOrigin: obj.data[i].fld_origin_country,
                ShortDescription: obj.data[i].fld_short_description,
                LongDescription: obj.data[i].fld_long_description,
                MarketedBy: obj.data[i].fld_marketedby,
                DataLoading: false,
              });
              PostApiCall.postRequest(
                {
                  categoryid: parseInt(obj.data[i].fld_categoryid),
                  stateid: null,
                },
                "GetBestSellingProduct"
              ).then((results) =>
                results.json().then((obj1) => {
                  if (results.status == 200 || results.status == 201) {
                    //console.log(obj1.data)
                    this.setState({
                      RelatedProductDetail: obj1.data,
                    });
                  }
                })
              );
            }
          }
        }
      })
    );
  }

  getCartdetails = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          this.setState({
            ProductCart: res1.data,
          });

          this.context.setCart(res1.data.length);

          // console.log(this.context)
        }
      });
  };

  render() {
    return (
      <>
        {this.state.DataLoading == true ? (
          <div class="d-flex justify-content-center m-5">
            <div
              class="spinner-border"
              role="status"
              style={{ color: "#c83363", width: "3rem", height: "3rem" }}
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div class="detail-page-container mt-3">
            <div class="product product--medium product--thumbnail_slider grid grid--1-col grid--2-col-tablet">
              <div class="grid__item product__media-wrapper wbproleftimg">
                <div class="w-card">
                  <div class="row">
                    {/* div size change by laxmi for phn view -------small img and large img ----------*/}
                    {/* <div class="col-md-2 smallImagesScroll"> */}
                    <div class="col-md-2 col-3 col-sm-3 smallImagesScroll">
                      {/* end col size changes--------------------------- */}

                      <div class="thumbnail-img">
                        {this.state.SelectedVariant &&
                          this.state.SelectedVariant.VariantImage &&
                          this.state.VariantImage == null
                          ? this.state.SelectedVariant.VariantImage.split(
                            "#"
                          ).map((url, index) => (
                            <div class="swiper-slide">
                              <img
                                src={url}
                                onMouseOver={() => {
                                  this.setState({
                                    selectedVariantImg: url,
                                  });
                                }}
                                class="onerone"
                              ></img>
                            </div>
                          ))
                          : this.state.VariantImage != null
                            ? this.state.VariantImage.split("$").map(
                              (url, index) => (
                                <div class="swiper-slide">
                                  <img
                                    src={url}
                                    onMouseOver={() => {
                                      this.setState({
                                        selectedVariantImg: url,
                                      });
                                    }}
                                    class="onerone"
                                  ></img>
                                </div>
                              )
                            )
                            : ""}
                      </div>
                    </div>

                    {/* div size change by laxmi for phn view -------small img and large img ----------*/}

                    {/* <div class="col-md-10"> */}
                    <div class="col-md-10 col-9 col-sm-9 large-img">
                      {/* end col size changes--------------------------- */}

                      {/* <div
                        class="large-img"
                        style={{
                          maxHeight: "525px",
                          overflow: "hidden",
                          borderRadius: "5px",
                        }}
                      > */}
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: "product images",
                            isFluidWidth: true,
                            src:
                              this.state.selectedVariantImg == undefined
                                ? this.state.VariantImage == null
                                  ? this.state.SelectedVariant.VariantImage
                                  : this.state.VariantImage.split("$")[0]
                                : this.state.selectedVariantImg,
                          },
                          largeImage: {
                            src:
                              this.state.selectedVariantImg == undefined
                                ? this.state.VariantImage == null
                                  ? this.state.SelectedVariant.VariantImage
                                  : this.state.VariantImage.split("$")[0]
                                : this.state.selectedVariantImg,
                            width: 800,
                            height: 1000,
                          },
                          lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
                          isHintEnabled: true,
                          shouldHideHintAfterFirstActivation: false,
                          enlargedImageContainerDimensions: {
                            width: "100%",
                            height: "100%",
                          },
                        }}
                      />
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* ps-3 add by laxmi */}
              <div class="product__info-wrapper grid__item ps-3 detailpagepehraphnview">
                <div
                  id="ProductInfo-template--15673555910895__main"
                  class="product__info-container product__info-container--sticky"
                >
                  <p class="product__text">{this.state.CategoryName}</p>
                  <h1 class="product__title">{this.state.VariantName}</h1>
                  <div
                    class="no-js-hidden mainp-price"
                    id="price-template--15673555910895__main"
                    role="status"
                  >
                    <div class="price price--large price--on-sale  price--show-badge">
                      <div>
                        <div class="price__sale">
                          <span class="visually-hidden visually-hidden--inline">
                            Sale price
                          </span>
                          <span class="price-item price-item--sale price-item--last">
                            ₹{" "}
                            {isNaN(
                              parseFloat(this.state.DiscountPrice).toFixed(2)
                            ) ? (
                              <Loader />
                            ) : (
                              parseFloat(this.state.DiscountPrice).toFixed(2)
                            )}
                          </span>
                          <span class="visually-hidden visually-hidden--inline">
                            Regular price
                          </span>
                          <span style={{ display: this.state.DiscountPrice == this.state.MRP ? "none" : "block" }}>
                            <s class="price-item price-item--regular">
                              {" "}
                              ₹
                              {isNaN(parseFloat(this.state.MRP).toFixed(2))
                                ? "0"
                                : parseFloat(this.state.MRP).toFixed(2)}{" "}
                            </s>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class="product__description rte "> */}

                  {/* change by laxmi remove product_description */}
                  <div class="rte detaildescriptionAlign">
                    <p data-mce-fragment="1">
                      {Parser(
                        ("<p>" + this.state.ShortDescription + "</p>")
                          .replace(/font-family/g, "")
                          .replace(/<br\/?>/gi, " ")
                      )}
                    </p>
                    <p className="" data-mce-fragment="1">
                      {Parser(
                        (
                          "<p className=''>" +
                          this.state.LongDescription +
                          "</p>"
                        )
                          .replace(/font-family/g, "")
                          .replace(/<br\/?>/gi, " ")
                      )}
                    </p>
                  </div>

                  {this.state.Stock == "Out Of Stock" ? (
                    <div className="button-soldout-list">Sold Out</div>
                  ) : (
                    <div class="product-form__buttons">
                      <div
                        className="butn deatilpagebtnphnview"
                        onClick={() => {
                          this.setState(
                            {
                              loading: true,
                            },
                            () => {
                              var login = localStorage.getItem("CustomerData");
                              var logindetails = JSON.parse(login);

                              var Order = localStorage.getItem("OrderData");
                              var Orderdetails = JSON.parse(Order);

                              // ===========with Login=========
                              PostApiCall.postRequest(
                                {
                                  orderdate: moment().format("lll"),
                                  itemdetails: `
                                       [
                                       { 
                                           "Item":${this.state.VariantId},
                                           "Quantity":${1}
                                       }
                                       ]
                                       `,
                                  status: "INCART",
                                  customerid:
                                    localStorage.getItem("CustomerData") == null
                                      ? null
                                      : logindetails[0].fld_customerid,
                                  createdon: moment().format("lll"),
                                  updatedon: moment().format("lll"),
                                  orderid:
                                    localStorage.getItem("OrderData") == null
                                      ? 0
                                      : Orderdetails[0].order_id,
                                  updatedby:
                                    localStorage.getItem("CustomerData") == null
                                      ? null
                                      : logindetails[0].fld_userid,
                                },
                                "AddToCartWebsite"
                              ).then((result) =>
                                result.json().then((obj) => {
                                  if (
                                    result.status == 200 ||
                                    result.status == 201
                                  ) {
                                    Notiflix.Notify.Success(
                                      "Product added to Cart."
                                    );
                                    this.setState({
                                      loading: false,
                                    });
                                    // console.log(obj.data)
                                    if (
                                      localStorage.getItem("OrderData") == null
                                    ) {
                                      //console.log('datastore')
                                      localStorage.setItem(
                                        "OrderData",
                                        JSON.stringify(obj.data)
                                      );
                                      this.getCartdetails();
                                    }
                                    this.getCartdetails();
                                  } else {
                                    Notiflix.Notify.failure(obj.data);
                                  }
                                })
                              );
                            }
                          );
                        }}
                      >
                        {this.state.loading == true ? (
                          <div class="d-flex justify-content-center">
                            <div
                              class="spinner-border"
                              role="status"
                              style={{ width: "1.4rem", height: "1.4rem" }}
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          "Add To Cart"
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <ProductReviews
              variantid={this.props.match.params.variantid}
              variantName={this.state.VariantName}
            />
            <RelatedProducts
              relatedProductData={this.state.RelatedProductDetail}
            />
          </div>
        )}
      </>
    );
  }
}

export default DetailPage;
