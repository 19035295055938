import React, { useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../Address/Address.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { AiOutlineLeft } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useHistory } from "react-router-dom";
import PostApiCall from "../../Helper/Api";
import GetApiCall from "../../Helper/GetApi";
import Notiflix from "notiflix";
import InnerBanner from "../InnerBanner/Innerbanner";
import { Link } from "react-router-dom";

function Address() {
  const [edit, setEdit] = React.useState(false);
  const [address, setAddress] = React.useState(false);
  const [AddressData, setAddressData] = React.useState([]);
  const [Title, setTitle] = React.useState("Home");
  const [Name, setName] = React.useState(null);
  const [mobileNumber, setmobileNumber] = React.useState(null);
  const [flatNumber, setflatNumber] = React.useState(null);
  const [area, setArea] = React.useState(null);
  const [landmark, setLandmark] = React.useState(null);
  const [gst, setgst] = React.useState(null);
  const [country, setCountry] = React.useState(null);
  const [countryData, setcountryData] = React.useState([]);
  const [countryId, setcountryId] = React.useState(0);
  const [customerstate, setcustomerstate] = React.useState(null);
  const [stateData, setStateData] = React.useState([]);
  const [stateId, setStateId] = React.useState(0);
  const [customercity, setcustomercity] = React.useState(null);
  const [cityData, setCityData] = React.useState([]);
  const [cityId, setcityId] = React.useState(0);
  const [pincode, setpincode] = React.useState(null);
  const [numRegex, setnumRegex] = React.useState(/^[0-9]*$/);
  const [loading, setLoading] = React.useState(false);
  const [addressId, setAddressId] = React.useState(null);
  const [longitude, setlongitude] = React.useState(null);
  const [latitude, setlatitude] = React.useState(null);
  const [updateCustomerAddressData, setupdateCustomerAddressData] =
    React.useState(false);
  const [loaderAddressId, setloaderAddressId] = React.useState(null);

  const history = useHistory();
  const Edit = () => setEdit(!edit);

  useEffect(() => {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    PostApiCall.postRequest(
      {
        whereClause: `where fld_customerid=${logindetails[0].fld_customerid}`,
      },
      "Get_Customer_Address"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          console.log(obj.data);
          setAddressData(obj.data);
        }
      })
    );
  }, [updateCustomerAddressData]);

  useEffect(() => {
    GetApiCall.getRequest("GetCountry").then((resultdes) =>
      resultdes.json().then((obj) => {
        setcountryData(obj.data);

        if (obj.data.length != 0) {
          setcountryId(101);
          setCountry(obj.data[102].label);
        }
        PostApiCall.postRequest(
          {
            countryid: 101,
          },
          "GetState"
        ).then((results) =>
          results.json().then((objstate) => {
            if (results.status == 200 || results.status == 201) {
              if (objstate.data.length != 0) {
                setStateId(objstate.data[0].value);
                setStateData(objstate.data);
                setcustomerstate(objstate.data[0].label);
              }

              PostApiCall.postRequest(
                {
                  stateid: objstate.data[0].value,
                },
                "GetCity"
              ).then((resultscity) =>
                resultscity.json().then((objcity) => {
                  if (resultscity.status == 200 || resultscity.status == 201) {
                    if (objcity.data.length != 0) {
                      setcustomercity(objcity.data[0].label);
                      setCityData(objcity.data);
                      setcityId(objcity.data[0].value);
                    }
                  }
                })
              );
            }
          })
        );
      })
    );
  }, []);

  const addAddress = () => setAddress(!address);
  return (
    <>
      <InnerBanner />
      <div>
        <div className="d-flex justify-content-between">
          <div className="pd-3">
            <Breadcrumb className="breadcrumb-content">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

              <Breadcrumb.Item>Address</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="Account pd-3">
            <h1 className="text-center-account">Address</h1>
          </div>
        </div>
        <div className="pd-3">
          <div>
            <Link to="/account">
              <div className="Address-return" style={{ cursor: "pointer" }}>
                <span>
                  <AiOutlineLeft />
                </span>
                Return to Account details
              </div>
            </Link>
            <div className="but-address">
              {/* butnaddAddressNew class add by laxmi */}
              <div className="butn butnaddAddressNew mt-4" onClick={addAddress}>
                Add a new address
              </div>
            </div>
            {address ? (
              <div className="Address-form">
                <div>
                  <div className="Address-form-edit">
                    <h2> Add a new address</h2>
                  </div>
                  <div>
                    <Form>
                      <Row>
                        <div class="col-md-12 center">
                          <div class="form-group mb-3">
                            <label for="validationCustom01">
                              Address Title<span class="mandatory">*</span>
                            </label>
                            <br />
                            <label class="radio-inline">
                              <input
                                type="radio"
                                name="optradio"
                                checked={Title === "Office" ? true : false}
                                onChange={() => {
                                  setTitle("Office");
                                }}
                              />{" "}
                              Office
                            </label>
                            <label
                              class="radio-inline"
                              style={{ marginLeft: "10px" }}
                            >
                              <input
                                type="radio"
                                name="optradio"
                                checked={Title === "Home" ? true : false}
                                onChange={() => {
                                  setTitle("Home");
                                }}
                              />{" "}
                              Home
                            </label>
                          </div>
                        </div>
                        <Col>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Name"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="name@example.com"
                              className="input"
                              value={Name}
                              onChange={(text) => {
                                setName(text.target.value);
                              }}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Mobile Number"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="name@example.com"
                              className="input"
                              value={mobileNumber}
                              onChange={(mobile) => {
                                if (mobile.target.value.length <= 10)
                                  setmobileNumber(
                                    mobile.target.value.replace(/\D/g, "")
                                  );
                              }}
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Flat/House/Building No."
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="name@example.com"
                          className="input"
                          value={flatNumber}
                          onChange={(text) => {
                            setflatNumber(text.target.value);
                          }}
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Area/Street/Sector/Village"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="name@example.com"
                          className="input"
                          value={area}
                          onChange={(text) => {
                            setArea(text.target.value);
                          }}
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Lankmark (optional)"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="name@example.com"
                          className="input"
                          value={landmark}
                          onChange={(landmark) => {
                            setLandmark(landmark.target.value);
                          }}
                        />
                      </FloatingLabel>
                      <Row>
                      <Col xs={12} s={12} md={12} lg={4}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="GST Number (optional)"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="name@example.com"
                              className="input"
                              value={gst}
                              onChange={(gst) => {
                                setgst(gst.target.value);
                              }}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col xs={12} s={12} md={12} lg={4}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Pincode"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Pincode"
                              className="input"
                              value={pincode}
                              onChange={(text) => {
                                if (text.target.value.length <= 6) {
                                  setpincode(
                                    text.target.value.replace(/\D/g, "")
                                  );
                                }
                              }}
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>

                      <Row>
                        {/* col size add by laxmi------------------------------------- */}
                        {/* <Col> */}
                        <Col xs={12} s={12} md={12} lg={4}>
                          <Form.Label htmlFor="basic-url">
                            Country
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            className="mb-3 pd-country"
                            value={countryId}
                            onChange={(country) => {
                              setcountryId(country.target.value);
                              setCountry(
                                countryData[parseInt(country.target.value) + 1]
                                  .label
                              );

                              PostApiCall.postRequest(
                                {
                                  countryid: country.target.value,
                                  Country:
                                    countryData[
                                      parseInt(country.target.value) + 1
                                    ].label,
                                },
                                "GetState"
                              ).then((results) =>
                                results.json().then((obj) => {
                                  if (
                                    results.status == 200 ||
                                    results.status == 201
                                  ) {
                                    setStateId(obj.data[0].value);
                                    setcustomerstate(obj.data[0].label);

                                    PostApiCall.postRequest(
                                      {
                                        stateid: obj.data[0].value,
                                      },
                                      "GetCity"
                                    ).then((resultscity) =>
                                      resultscity.json().then((objcity) => {
                                        if (
                                          resultscity.status == 200 ||
                                          resultscity.status == 201
                                        ) {
                                          setCityData(objcity.data);
                                          setStateData(obj.data);

                                          if (objcity.data.length > 0) {
                                            setcityId(objcity.data[0].value);
                                            setcustomercity(
                                              objcity.data[0].label
                                            );
                                          }
                                        }
                                      })
                                    );
                                  }
                                })
                              );
                            }}
                          >
                            {countryData.map((schedule) => (
                              <option
                                key={schedule.label}
                                value={schedule.value}
                              >
                                {schedule.label}
                              </option>
                            ))}
                          </Form.Select>
                        </ Col>
                         {/* col size add by laxmi------------------------------------- */}
                        {/* <Col> */}
                        <Col xs={12} s={12} md={12} lg={4}>
                          <Form.Label htmlFor="basic-url">
                            State
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="mb-3 pd-country"
                            value={stateId}
                            onChange={(state) => {
                              setStateId(state.target.value);

                              for (
                                var i = 0;
                                i < Object.keys(stateData).length;
                                i++
                              ) {
                                if (stateData[i].value == state.target.value) {
                                  setcustomerstate(stateData[i].label);
                                }
                              }

                              PostApiCall.postRequest(
                                {
                                  stateid: state.target.value,
                                },
                                "GetCity"
                              ).then((results) =>
                                results.json().then((obj) => {
                                  if (
                                    results.status == 200 ||
                                    results.status == 201
                                  ) {
                                    if (obj.data.length > 0) {
                                      setCityData(obj.data);
                                      setcityId(obj.data[0].value);
                                      setcustomercity(obj.data[0].label);
                                    }
                                  }
                                })
                              );
                            }}
                          >
                            {stateData.map((schedule) => (
                              <option
                                key={schedule.label}
                                value={schedule.value}
                              >
                                {schedule.label}
                              </option>
                            ))}
                          </Form.Select>
                        </ Col>
                        {/* col size add by laxmi------------------------------------- */}
                        {/* <Col> */}
                        <Col xs={12} s={12} md={12} lg={4}>
                          <Form.Label htmlFor="basic-url">
                            City
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            className="mb-3 pd-country"
                            value={cityId}
                            onChange={(city) => {
                              setcityId(city.target.value);

                              for (
                                var i = 0;
                                i < Object.keys(cityData).length;
                                i++
                              ) {
                                if (cityData[i].value == city.target.value) {
                                  setcustomercity(cityData[i].label);
                                }
                              }
                            }}
                          >
                            {cityData.map((schedule) => (
                              <option
                                key={schedule.label}
                                value={schedule.value}
                              >
                                {schedule.label}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                      <div className="but-address-flex">
                        {/* <div className="butn pd-aou-but"> Update Address</div> */}
                        <div>
                          <button
                            className="butn"
                            onClick={(e) => {
                              e.preventDefault();
                              if (Name != null) {
                                if (mobileNumber != null) {
                                  if (
                                    numRegex.test(mobileNumber) &&
                                    mobileNumber.length == 10
                                  ) {
                                    if (flatNumber != null) {
                                      if (area != null) {
                                        if (pincode != null) {
                                          if (
                                            numRegex.test(pincode) &&
                                            pincode.length == 6
                                          ) {
                                            if (country != null) {
                                              if (customerstate != null) {
                                                if (customercity != null) {
                                                  setLoading(true);

                                                  const login =
                                                    localStorage.getItem(
                                                      "CustomerData"
                                                    );
                                                  const loginDetails =
                                                    JSON.parse(login);

                                                  PostApiCall.postRequest(
                                                    {
                                                      CUSTOMERID:
                                                        loginDetails[0]
                                                          .fld_customerid,
                                                      addressid: addressId,
                                                      title: Title,
                                                      name: Name,
                                                      mobileNo: mobileNumber,
                                                      area: area,
                                                      flatno: flatNumber,
                                                      landmark: landmark,
                                                      gstNumber: gst,
                                                      country: countryId,
                                                      state: stateId,
                                                      city: cityId,
                                                      pincode: pincode,
                                                      action: "INSERT",
                                                      longitude: longitude,
                                                      latitude: latitude,
                                                    },
                                                    "Add_CustomerAddress"
                                                  ).then((result) =>
                                                    result
                                                      .json()
                                                      .then((obj) => {
                                                        if (
                                                          result.status ==
                                                            200 ||
                                                          result.status == 201
                                                        ) {
                                                          setupdateCustomerAddressData(
                                                            !updateCustomerAddressData
                                                          );
                                                          setLoading(false);
                                                          setAddress(!address);
                                                          Notiflix.Notify.Success(
                                                            "Address Successfully Added"
                                                          );
                                                        } else {
                                                          setLoading(false);
                                                          Notiflix.Notify.Failure(
                                                            obj.data
                                                          );
                                                        }
                                                      })
                                                  );
                                                } else {
                                                  Notiflix.Notify.Failure(
                                                    "Please select your city from dropdown"
                                                  );
                                                }
                                              } else {
                                                Notiflix.Notify.Failure(
                                                  "Please select your state name from dropdown"
                                                );
                                              }
                                            } else {
                                              Notiflix.Notify.Failure(
                                                "Please select your country name from dropdown"
                                              );
                                            }
                                          } else {
                                            Notiflix.Notify.Failure(
                                              "Please enter valid pincode"
                                            );
                                          }
                                        } else {
                                          Notiflix.Notify.Failure(
                                            "Please enter your pincode"
                                          );
                                        }
                                      } else {
                                        Notiflix.Notify.Failure(
                                          "Please enter your Area Name/Street Name/Sector Name/Village Name"
                                        );
                                      }
                                    } else {
                                      Notiflix.Notify.Failure(
                                        "Please enter your Flat/House/Building Number"
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.Failure(
                                      "Please enter valid mobile number"
                                    );
                                  }
                                } else {
                                  Notiflix.Notify.Failure(
                                    "Please enter your mobile number"
                                  );
                                }
                              } else {
                                Notiflix.Notify.Failure(
                                  "Please enter your name"
                                );
                              }
                            }}
                          >
                            {loading && loading == true ? (
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            ) : (
                              "Add Address"
                            )}
                          </button>{" "}
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            ) : null}


            {/* chnage by laxmi add row and col---------------------------------------------------- */}
            <div className="row px-2">
              {AddressData.map((data, index) => {
                return (
                  <>
                    {/* <div className="row" key={index}> */}
                      <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5 px-2" key={index}>
                        <div className="addresshowborder">
                        <h2 className="Address-form-h2">
                          {data.fld_address_type}
                        </h2>
                        <p className="text-capitalize mb-2">
                          {data.fld_contact_name}
                          <br />
                          <p>
                            Mobile No. : 
                            {data.fld_contact_number}
                          </p>
                          <p>
                            {data.fld_address_1}, {data.fld_address_2}
                          </p>
                          <p>
                            {data.CityName}- {data.fld_pincode}
                          </p>
                          <p>
                            {data.StateName}, {data.CountryName}
                          </p>
                          <p
                            style={{
                              display:
                                data.fld_landmark == null ? "none" : "block",
                            }}
                          >
                            Landmark: {data.fld_landmark}
                          </p>
                          <p
                            style={{
                              display:
                                data.fld_gstin == null ? "none" : "block",
                            }}
                          >
                            GSTIN: {data.fld_gstin}
                          </p>
                        </p>
                        <div className="address-edit-delete d-flex justify-content-between">
                          <button
                            className="butn"
                            onClick={() => {
                              localStorage.setItem(
                                "AddressData",
                                JSON.stringify(data)
                              );
                              history.push("/edit-address");
                            }}
                          >
                            <FaRegEdit /> Edit
                          </button>

                          <button
                            className="butn"
                            onClick={() => {
                              setloaderAddressId(data.fld_address_id);
                              setLoading(true);
                              const details = JSON.parse(
                                localStorage.getItem("CustomerData")
                              );
                              PostApiCall.postRequest(
                                {
                                  CUSTOMERID: details[0].fld_customerid,
                                  addressid: data.fld_address_id,
                                  title: data.fld_address_type,
                                  name: data.fld_contact_name,
                                  mobileNo: data.fld_contact_number,
                                  area: data.fld_address_2,
                                  flatno: data.fld_address_1,
                                  landmark: data.fld_landmark,
                                  country: data.Country,
                                  state: data.State,
                                  city: data.City,
                                  pincode: data.fld_pincode,
                                  action: "DELETE",
                                },
                                "Add_CustomerAddress"
                              ).then((results) =>
                                results.json().then((obj) => {
                                  if (
                                    results.status == 200 ||
                                    results.status == 201
                                  ) {
                                    setupdateCustomerAddressData(
                                      !updateCustomerAddressData
                                    );
                                    setLoading(false);
                                    Notiflix.Notify.Success(
                                      "Address Successfully Delete."
                                    );
                                  }
                                })
                              );
                            }}
                          >
                            {loaderAddressId === data.fld_address_id &&
                            loading == true ? (
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <MdDelete /> Delete
                              </div>
                            )}
                          </button>
                          </div>
                        </div>
                      {/* </div> */}
                    </div>
                    {/* end chnage by laxmi add row and col---------------------------------------------------- */}

                    {/* <h2 className="Address-form-h2">{data.fld_address_type}</h2>
                    <p className="text-capitalize mb-2">
                      {data.fld_contact_name}
                      <br />
                      <p>
                        Mobile No. : 
                        {data.fld_contact_number}
                      </p>
                      <p>
                        {data.fld_address_1}, {data.fld_address_2}
                      </p>
                      <p>
                        {data.CityName}- {data.fld_pincode}
                      </p>
                      <p>
                        {data.StateName}, {data.CountryName}
                      </p>
                      <p
                        style={{
                          display: data.fld_landmark == null ? "none" : "block",
                        }}
                      >
                        Landmark: {data.fld_landmark}
                      </p>
                      <p
                        style={{
                          display: data.fld_gstin == null ? "none" : "block",
                        }}
                      >
                        GSTIN: {data.fld_gstin}
                      </p>
                    </p>
                    <div className="address-edit-delete d-flex justify-content-between">
                      <button
                        className="butn"
                        onClick={() => {
                          localStorage.setItem(
                            "AddressData",
                            JSON.stringify(data)
                          );
                          history.push("/edit-address");
                        }}
                      >
                        <FaRegEdit /> Edit
                      </button>

                      <button
                        className="butn"
                        onClick={() => {
                          setloaderAddressId(data.fld_address_id);
                          setLoading(true);
                          const details = JSON.parse(
                            localStorage.getItem("CustomerData")
                          );
                          PostApiCall.postRequest(
                            {
                              CUSTOMERID: details[0].fld_customerid,
                              addressid: data.fld_address_id,
                              title: data.fld_address_type,
                              name: data.fld_contact_name,
                              mobileNo: data.fld_contact_number,
                              area: data.fld_address_2,
                              flatno: data.fld_address_1,
                              landmark: data.fld_landmark,
                              country: data.Country,
                              state: data.State,
                              city: data.City,
                              pincode: data.fld_pincode,
                              action: "DELETE",
                            },
                            "Add_CustomerAddress"
                          ).then((results) =>
                            results.json().then((obj) => {
                              if (
                                results.status == 200 ||
                                results.status == 201
                              ) {
                                setupdateCustomerAddressData(
                                  !updateCustomerAddressData
                                );
                                setLoading(false);
                                Notiflix.Notify.Success(
                                  "Address Successfully Delete."
                                );
                              }
                            })
                          );
                        }}
                      >
                        {loaderAddressId === data.fld_address_id &&
                        loading == true ? (
                          <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <MdDelete /> Delete
                          </div>
                        )}
                      </button>
                    </div> */}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Address;
