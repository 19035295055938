import { Component } from "react";
import "./ListingPage.css";
import { RiStarFill } from "react-icons/ri";
import Notiflix from "notiflix";
import PostApiCall from "../../Helper/Api";
import moment from "moment"
import { store } from "../../context/store"
import Parser from "html-react-parser";
import NoImage from "../../Images/NO-IMAGE-AVAILABLE.webp";
import GetApiCall from "../../Helper/GetApi"
class Card extends Component {
  static contextType = store;
  constructor(props) {
    super(props);

    this.state = {
      price: "",
      value: "",
      productView: "row rless grid product-grid",
      addClass: false,
      VariantImage: null,
      VariantId: null,
      ProductId: null,
      VariantName: null,
      ItemName: null,
      VariantList: null,
      MRP: null,
      DiscountPrice: null,
      YouSave: null,
      SavePer: null,
      Stock: null,
      Weight: null,
      CategoryName: null,
      loading: false,
      StoreOpen: ""
    };
  }

  componentDidMount() {

    const store = this.context;
    // console.log(this.props.itemdata)
    GetApiCall.getRequest("getstorestatus").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          StoreOpen: obj.data[0].fld_store_status
        })

      })
    );

  }

  getCartdetails = () => {

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        console.log(res1.data)
        if (res1.data) {

          this.setState({ ProductCart: res1.data });
          this.context.setCart(res1.data.length);
        }
      });


  };
  render() {
    // console.log(this.state.ItemName)
    return (
      <>
        <div class="card-wrapper wbproduct-container" className={this.props.productViewClass == "row rless grid product-list" ? "card-wrapper wbproduct-container-list" : "card-wrapper wbproduct-container"}>
          <div class="wbimgblock">
            <div
              class="product__media-item"
              data-media-id="template--15673555648751__product-grid-7554787868911-29317311004911"
            >
              <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${this.props.itemdata.fld_variantname == null ? "" : this.props.itemdata.fld_variantname.replace(
                    /\s/g,
                    "-"
                  )}/${this.props.itemdata.fld_variantid}`}
              >
                <img
                  class="img-fluid mx-auto"
                  src={this.props.itemdata.VariantImage ? this.props.itemdata.VariantImage : NoImage}
                  sizes="(min-width: 1600px) 0px, (min-width: 992px) calc(0vw - 10rem), (min-width: 768px) calc((100vw - 11.5rem) / 2), calc(100vw - 4rem)"
                  loading="lazy"
                  width="1000"
                  height="1000"
                  alt=""
                />
              </a>
            </div>
            {/* <div class="card__badge">
              <span>Sale</span>
            </div>
            <span class="product_badge new">New</span> */}
          </div>
          <div class="wbproductdes">
            <div className="mb-2 listingpage-product-list" style={{ display: this.props.productViewClass == "row rless grid product-list" ? "none" : "block", }}>
              <ul>
                <li>
                  <RiStarFill />
                </li>
                <li>
                  <RiStarFill />
                </li>
                <li>
                  <RiStarFill />
                </li>
                <li>
                  <RiStarFill />
                </li>
                <li>
                  <RiStarFill />
                </li>
              </ul>
            </div>
            <h3 class="product-title">
              <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${this.props.itemdata.fld_variantname == null ? "" : this.props.itemdata.fld_variantname.replace(
                    /\s/g,
                    "-"
                  )}/${this.props.itemdata.fld_variantid}`}
              >
                {this.props.itemdata.fld_variantname}
              </a>
            </h3>
            <p
              class="wblistpdesc"
              style={{
                display:
                  this.props.productViewClass == "row rless grid product-list"
                    ? "block"
                    : "none",
              }}
            >
              {Parser(
                ("<p>" + this.props.itemdata.fld_short_description + "</p>")
                  .replace(/font-family/g, "")
                  .replace(/<br\/?>/gi, " ")
              )}
            </p>
            <div id="ProductInfo-template--15673555648751__product-grid-7554787868911">
              <div
                class="no-js-hidden wbhprice"
                id="price-template--15673555648751__product-grid-7554787868911"
                role="status"
              >
                <div class="price price--on-sale ">
                  <div>
                    <div class="price__sale">
                      <span class="visually-hidden visually-hidden--inline">
                        Sale price
                      </span>
                      <h6>
                        ₹ {this.props.itemdata.fld_website_discount_price}{" "}
                        <span style={{ display: this.state.DiscountPrice == this.state.MRP ? "none" : "inline" }}>
                          <s class="price-item price-item--regular">₹ {this.props.itemdata.fld_mrp}</s>
                        </span>
                      </h6>
                      <span class="visually-hidden visually-hidden--inline">
                        Regular price
                      </span>
                    </div>
                  </div>
                </div>
              </div>


              <div className="mb-2 listingpage-product-list" style={{ display: this.props.productViewClass == "row rless grid product-list" ? "block" : "none", }}>
                <ul>
                  <li>
                    <RiStarFill />
                  </li>
                  <li>
                    <RiStarFill />
                  </li>
                  <li>
                    <RiStarFill />
                  </li>
                  <li>
                    <RiStarFill />
                  </li>
                  <li>
                    <RiStarFill />
                  </li>
                </ul>
              </div>
              {this.props.itemdata.available_stock == "Out Of Stock" ?
                <div className={this.props.productViewClass == "row rless grid product-list" ? "button-soldout-list" : "button-soldout"}>
                  Sold Out
                </div> :

                <div className={this.props.productViewClass == "row rless grid product-list" ? "butn3" : "butn2"} style={{ display: this.state.StoreOpen == "Close" ? "none" : "block" }} onClick={() => {
                  this.setState({
                    loading: true
                  }, () => {
                    var login = localStorage.getItem("CustomerData");
                    var logindetails = JSON.parse(login);

                    var Order = localStorage.getItem("OrderData");
                    var Orderdetails = JSON.parse(Order);

                    // ===========with Login=========
                    PostApiCall.postRequest(
                      {
                        orderdate: moment().format("lll"),
                        itemdetails: `
                                       [
                                       { 
                                           "Item":${this.props.itemdata.fld_variantid},
                                           "Quantity":${1}
                                       }
                                       ]
                                       `,
                        status: "INCART",
                        customerid:
                          localStorage.getItem("CustomerData") == null
                            ? null
                            : logindetails[0].fld_customerid,
                        createdon: moment().format("lll"),
                        updatedon: moment().format("lll"),
                        orderid:
                          localStorage.getItem("OrderData") == null
                            ? 0
                            : Orderdetails[0].order_id,
                        updatedby:
                          localStorage.getItem("CustomerData") == null
                            ? null
                            : logindetails[0].fld_userid,
                      },
                      "AddToCartWebsite"
                    ).then((result) =>
                      result.json().then((obj) => {
                        if (result.status == 200 || result.status == 201) {

                          Notiflix.Notify.Success("Product added to Cart.");
                          this.setState({
                            loading: false,
                          })
                          // console.log(obj.data)
                          if (localStorage.getItem("OrderData") == null) {
                            //console.log('datastore')
                            localStorage.setItem(
                              "OrderData",
                              JSON.stringify(obj.data)
                            );
                            this.getCartdetails();
                          }
                          this.getCartdetails();
                        } else {

                          Notiflix.Notify.failure(obj.data);
                        }
                      })
                    );
                  })
                }}>
                  {
                    this.state.loading == true ?
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status" style={{ width: "1.4rem", height: "1.4rem" }}>
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div> : "Add To Cart"
                  }


                </div>
              }
              {/* )} */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Card;
