import "../Banner/Banner2.css";
import custsupt from "../../../Images/FooterImages/24-hours-support.webp";
import securePay from "../../../Images/FooterImages/secure-payment.webp";
import freeDelivery from "../../../Images/FooterImages/free-delivery.webp";
import easyReturn from "../../../Images/FooterImages/easy.webp";
import Slider from "react-slick";

function Banner2() {
  var features = {
    infinite: false,
    // dots: true,
    // autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        }
      }
    ],
  };
  return (
    <>
      {/* Desktop view */}
      <div className="footer-card-main DeskTabDisplayWriteup">
        <div class="d-flex justify-content-around">

          <div className="card footercart">
            <div className="card-body footerCard ">
              <img
                src={custsupt}
                alt="customer-support"
                className=" img-fluid img-responsive "
              ></img>
              <p className="CustomerPara">
                <b className="footercardtext"> Customer Support</b>
                <br />
                <span style={{ color: "gray", fontSize: "14px" }}>
                  Need assistance? Call us and our team will help you out.
                </span>
              </p>
            </div>
          </div>
          <div className="card footercart">
            <div className="card-body footerCard">
              <img
                src={securePay}
                alt="Secure Payment"
                className="img-fluid img-responsive"
              ></img>
              <p className="CustomerPara">
                <b className="footercardtext">Secure Payment</b>
                <br />
                <span style={{ color: "gray", fontSize: "14px" }}>
                  Making online payments on our site to buy is Safe and Secure.
                </span>
              </p>
            </div>
          </div>

          <div className="card footercart">
            <div className="card-body footerCard">
              <img
                src={freeDelivery}
                alt="Free Shipping"
                className="img-fluid img-responsive"
              ></img>
              <p className="CustomerPara">
                <b className="footercardtext">Free Shipping</b>
                <br />
                <span style={{ color: "gray", fontSize: "14px" }}>
                  Shipping cost is Free for selected value of orders made.
                </span>
              </p>
            </div>
          </div>

          <div className="card footercart">
            <div className="card-body footerCard">
              <img
                src={easyReturn}
                alt="easyReturn"
                className="img-fluid img-responsive"
              ></img>
              <p className="CustomerPara">
                <b className="footercardtext">Easy Return</b>
                <br />
                <span style={{ color: "gray", fontSize: "14px" }}>
                  Return of products if damaged or spoilt is very easy.
                </span>
              </p>
            </div>
          </div>

        </div>
      </div>

      {/* phn view / tab view*/}

      <div className="footer-card-main phnTabDisplayWriteup">
        <Slider {...features}>
          <div className="col-12 col-sm-6">
            <div className="writeupDivPhnView">
              <img
                src={custsupt}
                alt="customer-support"
                className=" img-fluid img-responsive imgWriteupHeight"
              ></img>
              <p className="CustomerPara">
                <b className="footercardtext"> Customer Support</b>
                <br />
                <span style={{ color: "gray", fontSize: "14px" }}>
                  Need assistance? Call us and our team will help you out.
                </span>
              </p>
            </div>
          </div>

          <div className="col-12 col-sm-6">
            <div className="writeupDivPhnView">
              <img
                src={securePay}
                alt="Secure Payment"
                className="img-fluid img-responsive imgWriteupHeight"
              ></img>
              <p className="CustomerPara">
                <b className="footercardtext">Secure Payment</b>
                <br />
                <span style={{ color: "gray", fontSize: "14px" }}>
                  Making online payments on our site to buy is Safe and Secure.
                </span>
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="writeupDivPhnView">
              <img
                src={freeDelivery}
                alt="Free Shipping"
                className="img-fluid img-responsive imgWriteupHeight"
              ></img>
              <p className="CustomerPara">
                <b className="footercardtext">Free Shipping</b>
                <br />
                <span style={{ color: "gray", fontSize: "14px" }}>
                  Shipping cost is Free for selected value of orders made.
                </span>
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="writeupDivPhnView">
              <img
                src={easyReturn}
                alt="easyReturn"
                className="img-fluid img-responsive imgWriteupHeight"
              ></img>
              <p className="CustomerPara">
                <b className="footercardtext">Easy Return</b>
                <br />
                <span style={{ color: "gray", fontSize: "14px" }}>
                  Return of products if damaged or spoilt is very easy.
                </span>
              </p>
            </div>
          </div>
        </Slider>
        <div className="row">

        </div>
      </div>
    </>
  );
}
export default Banner2;
